export type BlankEnum = '';
export type NullEnum = 'NULL';
// Type for Anesthesia Options
export type AnesthesiaEnum =  'GENERAL_ANESTHESIA' | 'REGIONAL_ANESTHESIA' | 'LOCAL_ANESTHESIA' | 'MAC_SEDATION' | 'SPINAL_ANESTHESIA' | 'EPIDURAL_ANESTHESIA';

// Dropdown Items for Anesthesia
export const anesthesiaItems: { label: string; value: AnesthesiaEnum | BlankEnum }[] = [
  { label: 'General Anesthesia', value: 'GENERAL_ANESTHESIA' },
  { label: 'Regional Anesthesia', value: 'REGIONAL_ANESTHESIA' },
  { label: 'Local Anesthesia', value: 'LOCAL_ANESTHESIA' },
  { label: 'Monitored Anesthesia Care (MAC)/Sedation', value: 'MAC_SEDATION' },
  { label: 'Spinal Anesthesia', value: 'SPINAL_ANESTHESIA' },
  { label: 'Epidural Anesthesia', value: 'EPIDURAL_ANESTHESIA' },
];

// Type for TypeOfNeedle
export type TypeOfNeedleEnum = 'COOK' | 'REPROLINE' | 'WALLACE' | 'SWEMED' | 'ASPIRATION' | 'DOUBLE_LUMEN' | 'SINGLE_LUMEN' | 'OTHER';

// Dropdown Items for Type of Needle
export const typeOfNeedleItems: { value: TypeOfNeedleEnum | BlankEnum; label: string }[] = [
  { value: 'COOK', label: 'Cook' },
  { value: 'REPROLINE', label: 'Reproline' },
  { value: 'WALLACE', label: 'Wallace' },
  { value: 'SWEMED', label: 'Swemed' },
  { value: 'ASPIRATION', label: 'Aspiration Needle' },
  { value: 'DOUBLE_LUMEN', label: 'Double Lumen Needle' },
  { value: 'SINGLE_LUMEN', label: 'Single Lumen Needle' },
  { value: 'OTHER', label: 'Other' },
];

// Type for SubtypeOfNeedle
export type SubtypeOfNeedleEnum = 'SINGLE_LUMEN' | 'DOUBLE_LUMEN';

// Dropdown Items for Subtype of Needle
export const subtypeOfNeedleItems: { value: SubtypeOfNeedleEnum | BlankEnum; label: string }[] = [
  { value: 'SINGLE_LUMEN', label: 'Single Lumen' },
  { value: 'DOUBLE_LUMEN', label: 'Double Lumen' },
];


// Type for DifficultyLevel
export type DifficultyLevelEnum = 
  | 'EASY'
  | 'MODERATE'
  | 'DIFFICULT'
  | 'EXTREMELY_DIFFICULT'
  | 'DIFFICULT_TO_ACCESS_OVARY'
  | 'GONE_THROUGH_MYOMETRIUM'
  | 'ENDOMETRIUM_DRAINED'
  | 'PUNCTURED'
  | 'PROFUSE_BLEEDING'
  | 'BLEEDING'
  | 'ARTERY_PUNCTURED';

// Dropdown Items for Level of Difficulty
export const difficultyLevelItems: { value: DifficultyLevelEnum | BlankEnum; label: string }[] = [
  { value: 'EASY', label: 'Easy' },
  { value: 'MODERATE', label: 'Moderate' },
  { value: 'DIFFICULT', label: 'Difficult' },
  { value: 'EXTREMELY_DIFFICULT', label: 'Extremely Difficult' },
  { value: 'DIFFICULT_TO_ACCESS_OVARY', label: 'Difficult to Access Ovary' },
  { value: 'GONE_THROUGH_MYOMETRIUM', label: 'Gone Through Myometrium' },
  { value: 'ENDOMETRIUM_DRAINED', label: 'Endometrium Drained' },
  { value: 'PUNCTURED', label: 'Punctured' },
  { value: 'PROFUSE_BLEEDING', label: 'Profuse Bleeding' },
  { value: 'BLEEDING', label: 'Bleeding' },
  { value: 'ARTERY_PUNCTURED', label: 'Artery Punctured' },
];



export const cycleItems: { label: string; value: string; status: string }[] = [
  { label: 'CY123/08122024', value: 'CYCLE_1', status: 'Active' },
  { label: 'CY124/07122024', value: 'CYCLE_2', status: 'Completed' },
  { label: 'CY313/06122024', value: 'CYCLE_3', status: 'Inactive' },
  // { label: 'CY511/05122024', value: 'CYCLE_4', status: 'Cancelled' },
];
