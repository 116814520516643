/* eslint-disable no-console */
import { Modal, Box, Typography, Grid, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import styles from './Modals.module.css';
import { createTest, TestRequest } from './helper.stim';
import { useAlert } from '../../components/alertProvider';
import { formatDateToLocalYYYYMMDD } from '../../services/time';

interface HormoneValueModalProps {
    hormonesModalOpen: boolean,
    handleHormonesModalClose: () => void,
    hormoneId?: number,
    patientId: number,
    date?: Date,
    cycle: number,
    id?: number,
    value?: string
}

const HormoneValueModal: React.FC<HormoneValueModalProps> = ({hormonesModalOpen, handleHormonesModalClose, patientId, date, hormoneId, value, id}) => {
  const {addAlert} = useAlert();
  const updatedValue = value? value === '+'? '': value: '';
  const [hormonesFormData, setHormonesFormData] = useState({
    hormoneValue: updatedValue || '',
  });
 
  const handleHormonesChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    
    const { name, value } = event.target;
    setHormonesFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleHormonesSubmit = () => {
    if (hormoneId && date) {
      
      const body: TestRequest = {
        patient: patientId,
        laboratory_test: hormoneId,
        results: parseInt(hormonesFormData.hormoneValue),
        date: formatDateToLocalYYYYMMDD(date),
      };
      createTest(body, id).catch(() => addAlert('Failed to create Hormone test', 'error'));
    }
    handleHormonesModalClose();
  };
      
  return (
    <Modal open={hormonesModalOpen} onClose={handleHormonesModalClose}>
      <Box className={`${styles.modal} ${styles.modalSmall}`}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
            {id ? 'Add Result': 'Schedule Investigation'}
          </Typography>
        </Box>
        <Grid container spacing={2} className={styles.modalBody}>
          
          {id ? <Grid item xs={12} md={6}><TextField
            fullWidth
            label={<>
                  Hormone Value <span style={{ color: 'red' }}> * </span>
            </>}
            name="hormoneValue"
            placeholder="Hormone Value"
            value={hormonesFormData.hormoneValue}
            onChange={handleHormonesChange}
            margin="normal"
            InputLabelProps={{ shrink: true }}

          /></Grid>: <Typography padding={'1rem'}>Are you sure you want to schedule this investigation?</Typography>}
          
        </Grid>
        <Box className={styles.primary}>
          <Button fullWidth variant="outlined" onClick={handleHormonesModalClose} className={styles.modalCancelBtn}>
              Cancel
          </Button>
          <Button fullWidth variant="contained" onClick={handleHormonesSubmit} className={styles.modalSaveBtn}>
            {id ? 'Update': 'Schedule'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default HormoneValueModal;