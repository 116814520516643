/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import styles from './AllDay.module.css';
import { DayData, getSemenProcessingPreProcesss } from './AllDayHelper';

interface cycleProp {
  selectedCycleId: number;
}

const AllDay: React.FC<cycleProp> = ({ selectedCycleId }) => {
  const [dataAllDay, setDataAllDay] = useState<any[]>([]);
  const [pgtData, setPgtData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = () => {
      getSemenProcessingPreProcesss(selectedCycleId)
        .then((response) => {
          const sortedResponse = response.sort((a: any, b: any) => a.embryo_number - b.embryo_number);
          setDataAllDay(sortedResponse);
          const pgtInfo = sortedResponse.filter((item: any) => item.has_pgt);
          setPgtData(pgtInfo);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        });
    };

    fetchData();
  }, [selectedCycleId]);

  const daysWithData = [0, 1, 2, 3, 4, 5, 6, 7].filter((dayIndex) => 
    dataAllDay.some((row) => row.day === dayIndex && row.embryo_number !== null)
  );

  return (
    <div className={styles.tablesWrapper}>
      {/* Main Table: Embryo Data */}
      <TableContainer component={Paper} className={styles.tableContainer}>
        <div className={styles.tableScrollWrapper}>
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" rowSpan={2} className={`${styles.tableCell} ${styles.header}`}>
                  Emb#
                </TableCell>
                {daysWithData.map((dayIndex) => (
                  <TableCell
                    key={dayIndex}
                    align="center"
                    colSpan={dayIndex === 0 ? 1 : 2}
                    className={`${styles.tableCell} ${styles.header}`}
                  >
                    {`Day ${dayIndex}`}
                  </TableCell>
                ))}
                {/* PGT Data Column */}
                <TableCell align="center" rowSpan={2} className={`${styles.tableCell} ${styles.header}`}>
                  PGT
                </TableCell>
                <TableCell align="center" rowSpan={2} className={`${styles.tableCell} ${styles.header}`}>
                  Result
                </TableCell>
                <TableCell align="center" rowSpan={2} className={`${styles.tableCell} ${styles.header}`}>
                  AH
                </TableCell>
                <TableCell align="center" rowSpan={2} className={`${styles.tableCell} ${styles.header}`}>
                  Disposition
                </TableCell>
                <TableCell align="center" rowSpan={2} className={`${styles.tableCell} ${styles.header}`}>
                  Image
                </TableCell>
              </TableRow>
              <TableRow>
                {daysWithData.map((dayIndex) => {
                  if (dayIndex === 0) {
                    return (
                      <TableCell key={`maturity_${dayIndex}`} align="center" className={`${styles.tableCell} ${styles.header}`}>
                        Maturity
                      </TableCell>
                    );
                  }
                  if (dayIndex === 1) {
                    return (
                      <>
                        <TableCell key={`pn_${dayIndex}`} align="center" className={`${styles.tableCell} ${styles.header}`}>
                          PN
                        </TableCell>
                        <TableCell key={`pb_${dayIndex}`} align="center" className={`${styles.tableCell} ${styles.header}`}>
                          PB
                        </TableCell>
                      </>
                    );
                  }
                  if (dayIndex === 2 || dayIndex === 3) {
                    return (
                      <>
                        <TableCell key={`cell_${dayIndex}`} align="center" className={`${styles.tableCell} ${styles.header}`}>
                          # Cells
                        </TableCell>
                        <TableCell key={`grade_${dayIndex}`} align="center" className={`${styles.tableCell} ${styles.header}`}>
                          Grade
                        </TableCell>
                      </>
                    );
                  }
                  if (dayIndex >= 4 && dayIndex <= 7) {
                    return (
                      <>
                        <TableCell key={`stage_${dayIndex}`} align="center" className={`${styles.tableCell} ${styles.header}`}>
                          Stage
                        </TableCell>
                        <TableCell key={`grade_${dayIndex}`} align="center" className={`${styles.tableCell} ${styles.header}`}>
                          Grade
                        </TableCell>
                      </>
                    );
                  }
                  return null;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {dataAllDay
                .filter((row) => row.embryo_number !== null)
                .reduce((acc: any, curr: any) => {
                  const index = acc.findIndex((item: any) => item.embryo_number === curr.embryo_number);
                  if (index === -1) {
                    acc.push({ embryo_number: curr.embryo_number, days: { [curr.day]: curr } });
                  } else {
                    acc[index].days[curr.day] = curr;
                  }
                  return acc;
                }, [])
                .map((row: any, index: number) => {
                  const pgtInfo = pgtData.find((pgt: any) => pgt.embryo_number === row.embryo_number) || {};
                  return (
                    <TableRow key={index} className={styles.tableRow}>
                      <TableCell align="center" className={styles.tableCell}>
                        {row.embryo_number}
                      </TableCell>
                      {daysWithData.map((dayIndex) => {
                        const dayData = row.days[dayIndex] || {};
                        if (dayIndex === 0) {
                          return (
                            <TableCell key={`maturity_${dayIndex}`} align="center" className={styles.tableCell}>
                              {dayData.oocyte_grade || '-'}
                            </TableCell>
                          );
                        }
                        if (dayIndex === 1) {
                          return (
                            <>
                              <TableCell key={`pn_${dayIndex}`} align="center" className={styles.tableCell}>
                                {dayData.pn || '-'}
                              </TableCell>
                              <TableCell key={`pb_${dayIndex}`} align="center" className={styles.tableCell}>
                                {dayData.pb || '-'}
                              </TableCell>
                            </>
                          );
                        }
                        if (dayIndex === 2 || dayIndex === 3) {
                          return (
                            <>
                              <TableCell key={`cell_${dayIndex}`} align="center" className={styles.tableCell}>
                                {dayData.number_of_cells || '-'}
                              </TableCell>
                              <TableCell key={`grade_${dayIndex}`} align="center" className={styles.tableCell}>
                                {dayData.grade || '-'}
                              </TableCell>
                            </>
                          );
                        }
                        if (dayIndex >= 4 && dayIndex <= 7) {
                          return (
                            <>
                              <TableCell key={`stage_${dayIndex}`} align="center" className={styles.tableCell}>
                                {dayData.stage || '-'}
                              </TableCell>
                              <TableCell key={`grade_${dayIndex}`} align="center" className={styles.tableCell}>
                                {dayData.grade || '-'}
                              </TableCell>
                            </>
                          );
                        }
                        return null;
                      })}
                      <TableCell align="center" className={styles.tableCell}>
                        {pgtInfo.has_pgt ? 'YES' : 'No'}
                      </TableCell>
                      <TableCell align="center" className={styles.tableCell}>
                        {pgtInfo.pgt_results || '-'}
                      </TableCell>
                      <TableCell align="center" className={styles.tableCell}>
                        {pgtInfo.assisted_hatching ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell align="center" className={styles.tableCell}>
                        {
                          Object.values(row.days as { [key: number]: DayData }) 
                            .find((dayData: DayData) => dayData?.disposition)?.disposition || '-' 
                        }
                      </TableCell>


                      <TableCell align="center" className={styles.tableCell}>
                        {pgtInfo.files ? <img src={pgtInfo.files} alt={'Embryo'} className={styles.imageCell} /> : '-'}
                      </TableCell>
                    </TableRow>
                  
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </div>
  );
};

export default AllDay;
