/* eslint-disable @typescript-eslint/no-explicit-any */
import { getRequest } from '../../services/httpService';

export interface ThawedData {
  id: string | null;
  oocyteId: number | null;
  cycleNo: number | null;
  cryoId: number | null;
  cryoDate: string | null;
  material: string | null;
  planInfo: string | null;
  remarks: string | null;
  oocyte_number: number | null;
  grade: string | null;
  location: string | null;
  disposition: string | null;
  files: string | null;
  cryo_preserved_date: string | null;
  cryo_preserved_by: number | null;
  cryo_purpose: string | null;
  cryo_media: string | null;
  is_screened: boolean;
  disposition_plan: string | null;
  disposition_plan_type: string | null;
  disposition_date: string | null;
  disposition_time: string | null;
  disposition_recipient: number | null;
  cryo_preserved_by_name: string | null;
  disposition_witness_name: string | null;
  disposition_embryologist_name: string | null;
}


    

  
export const mapThawedData = (fetchedData: any[]): ThawedData[] => {
  return fetchedData.map(item => ({
    id: item.id || null,
    oocyteId: item.oocyte_number || null,
    cycleNo: item.cycle || null,
    cryoId: item.id || null,
    cryoDate: item.cryo_preserved_date || null,
    material: item.cryo_media || null,
    planInfo: item.disposition_plan || null,
    remarks: item.remarks || null,
    oocyte_number: item.oocyte_number || null,
    grade: item.grade || null,
    location: item.location || null,
    disposition: item.disposition || null,
    files: item.files || null,
    cryo_preserved_date: item.cryo_preserved_date || null,
    cryo_preserved_by: item.cryo_preserved_by || null,
    cryo_purpose: item.cryo_purpose || null,
    cryo_media: item.cryo_media || null,
    is_screened: item.is_screened || false,
    disposition_plan: item.disposition_plan || null,
    disposition_plan_type: item.disposition_plan_type || null,
    disposition_date: item.disposition_date || null,
    disposition_time: item.disposition_time || null,
    disposition_recipient: item.disposition_recipient || null,
    cryo_preserved_by_name: item.cryo_preserved_by_name || null,
    disposition_witness_name: item.disposition_witness_name || null,
    disposition_embryologist_name: item.disposition_embryologist_name || null,
  }));
};


export const updatedMaterialLabels = (item: any) => [
  { label: 'Grade', value: item.grade || '-' },
  { label: 'Protocol', value: item.oocyteId || '-' },
  { label: 'Screening Status', value: item.is_screened ? 'YES' : 'NO' },
];


export const updatedBillingLabels = (item: any) => [
  { label: 'Plan', value: item.disposition_plan || '-' },
  { label: 'Date', value: item.disposition_date || '-' },
  { label: 'Time', value: item.disposition_time || '-' },
  { label: 'Embryologist', value: item.disposition_embryologist_name || '-' },
  { label: 'Witness', value: item.disposition_witness_name || '-' },
  { label: 'Survival Status', value: item.is_screened ? 'YES' : 'NO' },
  { label: 'Thaw ID', value: '-' },
  { label: 'Thaw Protocol', value: '-' },
  { label: 'Remark', value: item.remarks || '-' },
];

export function getThawedUrl(id: number, label: string): string {
  const sanitizedLabel = label.toLowerCase(); 
  return `/cryo-storage/${sanitizedLabel}/patient/${id}/?status=THAW`;
}

export function getThawed(id: number, label: string): Promise<ThawedData> {
  return getRequest(getThawedUrl(id, label));
}