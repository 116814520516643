/* eslint-disable @typescript-eslint/no-explicit-any */
import { getRequest } from '../../../services/httpService';


export interface DayData {
  disposition?: string;
  oocyte_grade?: string;
  pn?: string;
  pb?: string;
  number_of_cells?: number;
  grade?: string;
  stage?: string;
}

export function getputSemenProcessingPreProcessUrl(cycle_id: number): string {
  return `embryo/summary/${cycle_id}`; 
}
  

export function getSemenProcessingPreProcesss(cycle_id: number): Promise<any> {
  return getRequest(getputSemenProcessingPreProcessUrl(cycle_id)); 
}