/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextField, ThemeProvider,  MenuItem,  Button, Box, FormControlLabel, Checkbox, Link, Autocomplete, FormControl, FormHelperText, Select, InputLabel, Typography } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useAlert } from '../../alertProvider';
import { useLoader } from '../../loaderProvider/loader';
import { ConfirmationModal } from '../../confirmationModal';
import FormObserver from '../../FormikFormObserver';
import { getMaxDateForMonthInput, RequiredLabel } from '../../../utils/commonUtils';
import { usePatientContext } from '../../SubPatinetProvider';
import styles from '../oocyteRetrieval/oocyteRetrival.module.css';
import theme from '../../../utils/theme';

import {  getUsersByClinicId, uploadFileURL } from '../../../services/apiService';
import { useClinic } from '../../ClinicProvider';
import { addLaparoscopy, adhesionItems, anesthesiaItems, associatedProcedureItems, ebHpeItems, endometriosisGradeItems, getLaparoscopy, initialLaparoscopy, LaparoscopyDetailsEnum, LaparoscopyType, LaparoscopyValidationSchema, liverUndersurfaceItems, mobilityItems, positionItems, shapeItems, siteItems, updateLaparoscopy, uterusItems, uterusSizeItems } from './laparoscopy.helper';
import ImageUploadComponent from './imageUploadLaparoscopy';
import { Height } from '@mui/icons-material';
import { FileTypes } from '../../../types';

interface LaparoscopyFormProp {
  record: number; // Ensure this matches your data structure
  onFormChange?: (hasChanged: boolean) => void;
  onBack: () => void;
  isEditMode: boolean; 
}

const Laparoscopy: React.FC<LaparoscopyFormProp> = ({ record, onFormChange , onBack, isEditMode = false}) => {
  const handleFormChange = onFormChange || (() => {});
  const [ hasReset, setHasReset ] = useState(false); 
  const { patientId } = usePatientContext();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [ initialValues, setInitialValues ] = useState<LaparoscopyType>(initialLaparoscopy);
  const [ openConfirmDialog, setOpenConfirmDialog ] = useState(false);
  const maxMonthDate = useMemo(() => getMaxDateForMonthInput(), []);
  const [ openResetConfirmDialog, setOpenResetConfirmDialog] = useState(false);    
  const [ doctorList, setDoctorList ] = useState<any[]>([]);
  const { ClinicId } = useClinic();
  const [file, setFile] = useState<File | null>(null); 
  const [imagePathUrl, setImagePathUrl] = useState<string | null>(null);


  // Log the record prop to check data
  useEffect(() => {
    console.log('Laparoscopy record prop:', isEditMode);
    console.log('Laparoscopy record prop:', record);
  }, [isEditMode, record]);


  useEffect(() => {
    showLoader();
    getLaparoscopy(record)
      .then((data) => {
        setInitialValues({ ...initialLaparoscopy, ...data });

        if (data.image_path_url) {
          setImagePathUrl(data.image_path_url); // Set the Base64 image data
        }

      })
       
      .catch(() => console.error('Error fetching Laparoscopy', 'error'))
      .finally(() => hideLoader());
  }, [addAlert, showLoader, hideLoader, record]);

  
  const sanitizeLaparoscopy = (values: LaparoscopyType): LaparoscopyType => {
    const sanitizedValues = { ...values, patient_procedure: record };
    return sanitizedValues;
  };
  
  const handleSubmit = async (
    values: LaparoscopyType,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<LaparoscopyType>
  ) => {
    showLoader();

    // Use the utility function to sanitize values
    // const sanitizedValues = sanitizeLaparoscopy(values);
    // addLaparoscopy(sanitizedValues)
    // Use the utility function to sanitize values
    const sanitizedValues = sanitizeLaparoscopy(values);
    let laparoscopyApiCall; // Renamed for clarity
    if (isEditMode) {
      // Ensure that sanitizedValues.id is a valid number
      const id = sanitizedValues?.id;
    
      if (id !== undefined && id !== null) { // Check if ID is not undefined or null
        laparoscopyApiCall = updateLaparoscopy(id, sanitizedValues);
      } else {
        console.error('ID is required for updating laparoscopy');
        hideLoader();
        setSubmitting(false);
        return; // Exit early if ID is not valid
      }
    } else {
      // Otherwise, call the add API
      laparoscopyApiCall = addLaparoscopy(sanitizedValues);
    }

    laparoscopyApiCall
      .then((updatedData) => {

        if (file) {
          const fileName = file.name ; 
          if (updatedData.id !== undefined && updatedData.id !== null) {
            uploadFileURL({ file_name: fileName, source: FileTypes.LAPAROSCOPY, ref_id: updatedData.id }, file as File);
          }
        }
        
        addAlert('Laparoscopy saved successfully', 'success');
        setInitialValues(updatedData);
        resetForm({ values: updatedData });
        onBack();

      })  
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.type === 'validation_error') {
          const apiErrors = error.response.data.errors;
          const formikErrors: FormikErrors<LaparoscopyType> = {};

          apiErrors.forEach((error: { attr: keyof LaparoscopyType; detail: string }) => {
            formikErrors[error.attr] = error.detail;
          });

          setErrors(formikErrors);
        } else {
           
          console.error('An error occurred while saving Laparoscopy.');
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  const handleReset = (
    resetForm: FormikHelpers<LaparoscopyType>['resetForm'],
    setFieldValue: FormikHelpers<LaparoscopyType>['setFieldValue']
  ) => {
    setOpenResetConfirmDialog(false); 
    resetForm(); 
    setHasReset(true); 
    
    Object.keys(initialLaparoscopy).forEach((field) => {
      const initialValue = initialLaparoscopy[field as keyof LaparoscopyType];
      if (typeof initialValue === 'number' || initialValue === null) {
        setFieldValue(field, '');
      } else {
        setFieldValue(field, initialValue);
      }
    });
  };
   
  useEffect(() => {
    showLoader();
    const query = `clinic=${ClinicId}&role=DOCTOR`;
    getUsersByClinicId(query)
      .then((res: any) => {
        setDoctorList(res?.objects ?? []);
      })
      .catch((error) => {
        console.error('Error fetching clinicians:', error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]);


  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={LaparoscopyValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >      
      {({         
        values,
        setFieldValue,
        submitForm,
        validateForm,
        errors,
        setFieldError, 
        dirty,  handleBlur, handleChange, handleSubmit, isSubmitting, touched, resetForm, setFieldTouched }) => (
        <>        
          <FormObserver dirty={dirty} onFormChange={handleFormChange} />          
          <ThemeProvider theme={theme}>
            <form onSubmit={handleSubmit}>
              <Grid2 container spacing={2} justifyContent="start" sx={{ marginTop: 2 , marginRight:2}}>
                <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }}>
                  <TextField
                    fullWidth
                    size="small" 
                    label={RequiredLabel('Date', true)}
                    name="date"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    value={values.date}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('date', e.target.value || null)}
                    error={touched.date && Boolean(errors.date)}
                    // helperText={touched.date && errors.date}
                    inputProps={{ max: maxMonthDate  }}
                    aria-label="Date Input Field"
                  />
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('Indication', true)}
                    name="indication"
                    value={values.indication || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.indication && Boolean(errors.indication)}
                    // helperText={touched.indication && errors.indication}
                  />
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('Day of Cycle', true)}
                    name="cycle_day"
                    value={values.cycle_day || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.cycle_day && Boolean(errors.cycle_day)}
                    // helperText={touched.cycle_day && errors.cycle_day}
                  />
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Duration of Procedure (Hours)"
                    name="procedure_duration"
                    value={values.procedure_duration || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.procedure_duration && Boolean(errors.procedure_duration)}
                    helperText={touched.procedure_duration && errors.procedure_duration}
                  />
                </Grid2>
              </Grid2>

              <Typography 
                sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '19.5px',  textAlign: 'left',  marginTop: 4, marginBottom: 2 }}
              >
              Anesthesia Details
              </Typography>            


              <Grid2 container spacing={2} justifyContent="start" sx={{ marginY: 2, marginRight:2 }}>
                {/* Anesthesia Type Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Anesthesia Type"
                    name="anesthesia_type"
                    value={values.anesthesia_type || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.anesthesia_type && Boolean(errors.anesthesia_type)}
                    helperText={touched.anesthesia_type && errors.anesthesia_type}
                  >
                    {/* Dynamically generating options from the anesthesiaItems array */}
                    {anesthesiaItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Anesthesia Details Remark Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Anesthesia Details Remark"
                    name="anesthesia_details_remark"
                    value={values.anesthesia_details_remark || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.anesthesia_details_remark && Boolean(errors.anesthesia_details_remark)}
                    helperText={touched.anesthesia_details_remark && errors.anesthesia_details_remark}
                    multiline
                    minRows={1}
                    maxRows={1}
                  />
                </Grid2>

                {/* Preanesthetic Check Up Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Preanesthetic Check Up"
                    name="preanesthetic_checkup"
                    value={values.preanesthetic_checkup || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.preanesthetic_checkup && Boolean(errors.preanesthetic_checkup)}
                    helperText={touched.preanesthetic_checkup && errors.preanesthetic_checkup}
                    multiline
                    minRows={1}
                    maxRows={1}
                  />
                </Grid2>
              </Grid2>


              {/* Patency of Fallopian Tube Section */}
              <Typography 
                sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '19.5px', textAlign: 'left', marginTop: 4, marginBottom: 2 }}
              >
                Patency of Fallopian Tube
              </Typography>

              <Grid2 container spacing={2} justifyContent="start" sx={{ marginY: 2, marginRight:2 }}>
                {/* Right Tube Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Right Tube"
                    name="right_tube_status"
                    value={values.right_tube_status || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.right_tube_status && Boolean(errors.right_tube_status)}
                    // helperText={touched.right_tube_status && errors.right_tube_status}
                  />
                </Grid2>

                {/* Left Tube Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Left Tube"
                    name="left_tube_status"
                    value={values.left_tube_status || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.left_tube_status && Boolean(errors.left_tube_status)}
                    // helperText={touched.left_tube_status && errors.left_tube_status}
                  />
                </Grid2>
              </Grid2>

              {/* Right Ovary Section */}
              <Typography 
                sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '19.5px', textAlign: 'left',  marginTop: 4, marginBottom: 2 }}
              >
  Right Ovary
              </Typography>

              <Grid2 container spacing={2} justifyContent="start" sx={{ marginY: 2, marginRight:2 }}>
                {/* Right Ovary Status Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Status"
                    name="right_ovary_status"
                    value={values.right_ovary_status || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.right_ovary_status && Boolean(errors.right_ovary_status)}
                    // helperText={touched.right_ovary_status && errors.right_ovary_status}
                  />
                </Grid2>

                {/* Right Ovary Other Details Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Details"
                    name="right_ovary_details"
                    value={values.right_ovary_details || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.right_ovary_details && Boolean(errors.right_ovary_details)}
                    // helperText={touched.right_ovary_details && errors.right_ovary_details}
                    multiline
                    minRows={1}
                    maxRows={1}
                  />
                </Grid2>
              </Grid2>

              {/* Left Ovary Section */}
              <Typography 
                sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '19.5px', textAlign: 'left',  marginTop: 4, marginBottom: 2 }}
              >
              Left Ovary
              </Typography>

              <Grid2 container spacing={2} justifyContent="start" sx={{ marginY: 2, marginRight:2 }}>
                {/* Left Ovary Status Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Status"
                    name="left_ovary_status"
                    value={values.left_ovary_status || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.left_ovary_status && Boolean(errors.left_ovary_status)}
                    // helperText={touched.left_ovary_status && errors.left_ovary_status}
                  />
                </Grid2>

                {/* Left Ovary Other Details Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Other Details"
                    name="left_ovary_details"
                    value={values.left_ovary_details || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.left_ovary_details && Boolean(errors.left_ovary_details)}
                    // helperText={touched.left_ovary_details && errors.left_ovary_details}
                    multiline
                    minRows={1}
                    maxRows={1}
                  />
                </Grid2>
              </Grid2>

              <Typography 
                sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '19.5px', textAlign: 'left',  marginTop: 4, marginBottom: 2 }}
              >
              Other details
              </Typography>

              <Grid2 container spacing={2} justifyContent="start" sx={{ marginY: 2, marginRight:2 }}>
                {/* Site Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Site"
                    name="adhesion_site"
                    value={values.adhesion_site || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.adhesion_site && Boolean(errors.adhesion_site)}
                    // helperText={touched.adhesion_site && errors.adhesion_site}
                  >
                    {siteItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Adhesion Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Adhesion"
                    name="adhesion_grade"
                    value={values.adhesion_grade || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.adhesion_grade && Boolean(errors.adhesion_grade)}
                    // helperText={touched.adhesion_grade && errors.adhesion_grade}
                  >
                    {adhesionItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>
                
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Remark"
                    name="remark"
                    value={values.remark || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.remark && Boolean(errors.remark)}
                    helperText={touched.remark && errors.remark}
                    multiline
                    minRows={1}
                    maxRows={1}
                  />
                </Grid2>
              </Grid2>

              <Grid2 container spacing={2} justifyContent="start" sx={{ marginY: 2, marginRight:2 }}>                
                {/* Field for Endometriosis Grade */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Endometriosis Grade"
                    name="endometriosis_grade"
                    value={values.endometriosis_grade || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.endometriosis_grade && Boolean(errors.endometriosis_grade)}
                    // helperText={touched.endometriosis_grade && errors.endometriosis_grade}
                  >
                    {endometriosisGradeItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Uterus Type"
                    name="uterus_status"
                    value={values.uterus_status || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.uterus_status && Boolean(errors.uterus_status)}
                    // helperText={touched.uterus_status && errors.uterus_status}
                  >
                    {uterusItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Uterus Size Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Size"
                    name="uterus_size"
                    value={values.uterus_size || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.uterus_size && Boolean(errors.uterus_size)}
                    // helperText={touched.uterus_size && errors.uterus_size}
                  >
                    {uterusSizeItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Uterus Shape Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Shape"
                    name="uterus_shape"
                    value={values.uterus_shape || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.uterus_shape && Boolean(errors.uterus_shape)}
                    // helperText={touched.uterus_shape && errors.uterus_shape}
                  >
                    {shapeItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Additional fields for Position */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Position"
                    name="uterus_position"
                    value={values.uterus_position || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.uterus_position && Boolean(errors.uterus_position)}
                    // helperText={touched.uterus_position && errors.uterus_position}
                  >
                    {positionItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Additional fields for Mobility */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Mobility"
                    name="uterus_mobility"
                    value={values.uterus_mobility || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.uterus_mobility && Boolean(errors.uterus_mobility)}
                    // helperText={touched.uterus_mobility && errors.uterus_mobility}
                  >
                    {mobilityItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Additional fields for Associated Procedure */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Associated Procedure"
                    name="associated_procedures"
                    value={values.associated_procedures || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.associated_procedures && Boolean(errors.associated_procedures)}
                    // helperText={touched.associated_procedures && errors.associated_procedures}
                  >
                    {associatedProcedureItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>


                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Remark"
                    name="uterus_remark"
                    value={values.uterus_remark || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.uterus_remark && Boolean(errors.uterus_remark)}
                    // helperText={touched.uterus_remark && errors.uterus_remark}
                    multiline
                    minRows={1}
                    maxRows={1}
                  />
                </Grid2>

                {/* Field for Pouch of Douglas */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Pouch of Douglas"
                    name="pouch_of_douglas_remark"
                    value={values.pouch_of_douglas_remark || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.pouch_of_douglas_remark && Boolean(errors.pouch_of_douglas_remark)}
                    // helperText={touched.pouch_of_douglas_remark && errors.pouch_of_douglas_remark}
                  />
                </Grid2>

                {/* Field for Liver Undersurface */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Liver Undersurface"
                    name="undersurface_of_liver"
                    value={values.undersurface_of_liver || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.undersurface_of_liver && Boolean(errors.undersurface_of_liver)}
                    // helperText={touched.undersurface_of_liver && errors.undersurface_of_liver}
                  >
                    {liverUndersurfaceItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Field for EB HPE */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="EB HPE"
                    name="eb_hpe"
                    value={values.eb_hpe || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.eb_hpe && Boolean(errors.eb_hpe)}
                    // helperText={touched.eb_hpe && errors.eb_hpe}
                  >
                    {ebHpeItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Remark"
                    name="eb_hpe_remark"
                    value={values.eb_hpe_remark || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.eb_hpe_remark && Boolean(errors.eb_hpe_remark)}
                    // helperText={touched.eb_hpe_remark && errors.eb_hpe_remark}
                    multiline
                    minRows={1}
                    maxRows={1}
                  />
                </Grid2>

                {/* Field for Done By */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="done_by"
                    options={doctorList}
                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                    value={doctorList.find((doctor) => doctor.id === values.done_by) || null}
                    onChange={(_, value) => {
                      setFieldValue('done_by', value?.id || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Done By"
                        error={touched.done_by && Boolean(errors.done_by)}
                        // helperText={touched.done_by && errors.done_by}
                      />
                    )}
                  />
                </Grid2>

                {/* Field for Associated Doctor */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="associated_doctor"
                    options={doctorList} // Updated to use doctorList
                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                    value={doctorList.find((doctor) => doctor.id === values.associated_doctor) || null}
                    onChange={(_, value) => {
                      setFieldValue('associated_doctor', value?.id || '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={RequiredLabel('Associated Doctor', true)}
                        error={touched.associated_doctor && Boolean(errors.associated_doctor)}
                        // helperText={touched.associated_doctor && errors.associated_doctor}
                      />
                    )}
                  />
                </Grid2>

                <Grid2 size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                  {/* <ImageUploadComponent /> */}
                  <ImageUploadComponent onFileUpload={setFile}       imagePathUrl={imagePathUrl} />
                </Grid2>

              </Grid2>

              <Box sx={{flexGrow: 1, marginRight: 2}}>              
                <Box className={styles.customButtonDiv}>
                  
                  <button className={styles.resetButton} onClick={onBack} >Back to List</button>                  
                  <button
                    type="button"
                    className={styles.resetButton}
                    onClick={() => setOpenResetConfirmDialog(true)} 
                  > 
                  Reset
                  </button>
                  <button
                    type="button"
                    className={`${styles.customButton} ${isSubmitting || !dirty ? styles.disabled : ''}`}
                    disabled={isSubmitting || !dirty} 
                    onClick={() => {
                      validateForm().then((errors) => {
                        if (Object.keys(errors).length === 0) {
                          // submitForm();
                          setOpenConfirmDialog(true);
                        } else {
                          const errorKeys = Object.keys(errors);                          
                          errorKeys.forEach((key) =>{
                            setFieldTouched(key, true, true);
                          });
                        }
                      });
                    }}                  
                  >                    
                    <span style={{ fontWeight: 'bold' }}>
                      {isSubmitting ? 'Saving...' : 'Save'}
                    </span>
                  </button>                  
                </Box>

                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    submitForm();
                    setOpenConfirmDialog(false);
                  }}
                  type="save"
                  title="Laparoscopy"
                  contentMessage="Are you sure you want to save Laparoscopy?"
                />
                <ConfirmationModal
                  open={openResetConfirmDialog}
                  handleClose={() => setOpenResetConfirmDialog(false)} 
                  onConfirm={() => handleReset(resetForm, setFieldValue)} 
                  title="Laparoscopy"
                  contentMessage="Are you sure you want to reset Laparoscopy?"
                  type={'delete'}
                />
              </Box>
            </form>
          </ThemeProvider>
        </>
      )}
    </Formik>
  );
};

export default Laparoscopy;