import React from 'react';
import { ReactComponent as PrescribedUnavailableImage } from '../../assets/SVG/PrescribedUnavailable.svg';
import Grid2 from '@mui/material/Grid2';

const PrescribedUnavailable: React.FC = () => {
  return (
    <Grid2 
      container 
      justifyContent="center" 
      alignItems="center" 
    //   sx={{ minHeight: '100vh' }} // Adjust height as needed
    >
      <Grid2>
        <PrescribedUnavailableImage style={{ width: '600px', height: '400px', marginTop: '64px', }} />
        <p style={{ textAlign: 'center', fontWeight: 600 }}>
           No surgery has been prescribed yet
        </p>
      </Grid2>
    </Grid2>
  );
};

export default PrescribedUnavailable;
