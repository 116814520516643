/* eslint-disable no-console */
import React, { useCallback, useState } from 'react';
import CyclePicker from '../../../components/Embryology/cyclePicker';
import { CycleData } from '../../../components/Surgery/oocyteRetrieval/OocyteRetrival.helper';
import EmbryoCultureView from './embryoCultureView';
import EmbryoCycleUnavailable from './embryoCycleUnavailable';
import HeaderProfile from '../../../components/header_Profile/header_Profile.index';
import { PatientData } from '../../../types';
import { Box } from '@mui/material';

const EmbryoCulture: React.FC = () => {
  const [cyclesAvailable, setCyclesAvailable] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<PatientData | null>(null);
  
  const [selectedCycle, setSelectedCycle] = useState<CycleData | null>(null);

  // This function will be triggered when a cycle is selected
  const handleCycleChange = useCallback(
    (selectedCycle: CycleData | null) => {
      if (selectedCycle) {
        setSelectedCycle(selectedCycle);
        console.log('Selected Cycle:', selectedCycle);
      } else {
        console.log('No cycle selected');
      }
    },
    [] 
  );  

  
  const handleDataFromHeader = (data: PatientData) => {
    setSelectedPatient(data);
  };

  return (
    <div>

        
      <div>
        <HeaderProfile onData={handleDataFromHeader} />
      </div>
      <Box mt={6} px={2}>

        <CyclePicker
          onCycleChange={handleCycleChange}
          onCycleAvailabilityChange={setCyclesAvailable} 
        />
        {cyclesAvailable && selectedPatient ? (
          selectedCycle ? (
            <EmbryoCultureView selectedCycle={selectedCycle} />
          ) : (
            <p>Select a cycle to view its details.</p>
          )
        ) : (
          <EmbryoCycleUnavailable />
        )}

      </Box>
    </div>
  );
};

export default EmbryoCulture;
