/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid2, IconButton, ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import styles from '../Occyte-Grading/occytegrading.module.css';
import editIcon from '../../../../assets/SVG/edit icon.svg';
import { theme } from '../../../components/Medical_History_Forms/themeprovider';
import { convertUnderscoreToTitle } from '../../../utils/commonUtils';
import DetailsTable from './detailsTable';

interface oocyteViewProp {
  data: any;
  tableData:any[];
  tablecolumns:any
  isFinalized:boolean
  day:number
}

const EmbryoCultureViewMode: React.FC<oocyteViewProp> = ({ data, tableData,isFinalized, day, tablecolumns }) => {

  useEffect(()=>{
    // eslint-disable-next-line no-console
    console.log('data in view mode',data, isFinalized);
        
  },[data, isFinalized]);
  return(
    <ThemeProvider theme={theme} >
      <div style={{padding:'20px'}}>

        <Grid2 container spacing={2} marginTop={2}>
          <Grid2 size={{sm:6}}>
            <h4 style={{
              fontFamily: 'Montserrat',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '29px',
              textAlign: 'left',
            }}>
          Oocyte Details
            </h4>
          </Grid2>

          {/* <Grid2 size={{sm:6}} display={'flex'} justifyContent={'flex-end'}>
            <IconButton

              color="error"
              title="Delete Row"
            >
              <img src={editIcon} alt="DeleteIcon" />
            </IconButton>
          </Grid2> */}
        </Grid2>


        <Grid2 container spacing={2} marginTop={2}>
          <Grid2 size={{xs:12, sm:6, md:4, lg:3, xl:2}}>
            <p className={`${styles.labelHeading}`}>{day === 1 ? 'Fert Check Date': 'Date'}</p>
            <p className={`${styles.values}`}>{data?.oocyte_count || '-'}</p>
          </Grid2>
          <Grid2 size={{xs:12, sm:6, md:4, lg:3, xl:2}}>
            <p className={`${styles.labelHeading}`}>{day === 0 ? 'Insemination Time' : day === 1 ? 'Fert Check Time' : 'Time'}</p>
            <p className={`${styles.values}`}>{data?.time
              ? new Date(`1970-01-01T${data.time}`).toLocaleString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
              })
              : '-'}</p>
          </Grid2>
          <Grid2 size={{xs:12, sm:6, md:4, lg:3, xl:2}}>
            <p className={`${styles.labelHeading}`}>Embryologist</p>
            <p className={`${styles.values}`}>{data?.embryologist_name || '-'}</p>
          </Grid2>
          <Grid2 size={{xs:12, sm:6, md:4, lg:3, xl:2}}>
            <p className={`${styles.labelHeading}`}>Witness</p>
            <p className={`${styles.values}`}>{data?.witness_name || '-'}</p>
          </Grid2>
          <Grid2 size={{xs:12, sm:6, md:4, lg:3, xl:2}}>
            <p className={`${styles.labelHeading}`}>Incubator</p>
            <p className={`${styles.values}`}>{convertUnderscoreToTitle(data?.incubator) || '-'}</p>
          </Grid2>
          {day === 0 && (
            <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
              <p className={`${styles.labelHeading}`}>Fertilization</p>
              <p className={`${styles.values}`}>{convertUnderscoreToTitle(data?.fertilization) || '-'}</p>
            </Grid2>
          )}
          {day >= 3 && (
            <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}>
              <p className={`${styles.labelHeading}`}>Assisted Hatching</p>
              <p className={`${styles.values}`}>{data?.is_assisted_hatching ? 'Yes' : 'No'}</p>
            </Grid2>
          )}
          
          <Grid2 size={{xs:12, sm:6, md:4, lg:3, xl:2}}>
            <p className={`${styles.labelHeading}`}>Remark</p>
            <p className={`${styles.values}`}>{data?.remarks || '-'}</p>
          </Grid2>

          <Grid2 size={{xs:12, sm:6, md:4, lg:3, xl:2}}>
            <p className={`${styles.labelHeading}`}>Culture Dish Configuration</p>
            <p className={`${styles.values}`}>{convertUnderscoreToTitle(data?.culture_dish_config) || '-'}</p>
          </Grid2>

        </Grid2>

        <div >
          <p style={{fontFamily:'Montserrat', fontSize:'20px', fontWeight:700, paddingTop:'1rem',paddingBottom:'1rem'}}>Details</p>
          <DetailsTable columns={tablecolumns} rows={tableData} isDeletable={isFinalized} />
        </div>

      </div>
    </ThemeProvider>
    
  );
};

export default EmbryoCultureViewMode;