import React, {useEffect, useRef} from 'react';

interface FormObserverProps {
  dirty: boolean;
  onFormChange: (hasChanged: boolean) => void;
}

const FormObserver: React.FC<FormObserverProps> = ({ dirty, onFormChange }) => {
  const prevDirtyRef = useRef<boolean>(dirty);

  useEffect(() => {
    if (prevDirtyRef.current !== dirty) {
      onFormChange(dirty);
      prevDirtyRef.current = dirty;
    }
  }, [dirty, onFormChange]);

  return null;
};

export default FormObserver;
