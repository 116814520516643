/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField, ThemeProvider,  MenuItem,  Button, Box, FormControlLabel, Checkbox, Link, Autocomplete } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import {addEmbryoTransfer, catheterItems, CycleEmbryoDetails, cycleItems, EmbryoDetailsType, EmbryoTransferType, EmbryoTransferValidationSchema,  endometriumPatternItems,  getEmbryoTransfer,  getEmbryoTransferDetails,  initialEmbryoTransfer,  levelOfDifficultyItems,  transferMethodItems,  typeOfAnesthesiaItems,  ultrasoundMethodItems,  updateEmbryoTransfer} from './embryoTransfer.helpers';
import { theme } from '../../Medical_History_Forms/themeprovider';
import { useAlert } from '../../alertProvider';
import { useLoader } from '../../loaderProvider/loader';
import {ConfirmationModal} from '../../confirmationModal';
import FormObserver from '../../FormikFormObserver';
import { capitalizeTitle, getMaxDate, getMaxDateForMonthInput, RequiredLabel } from '../../../utils/commonUtils';
import { usePatientContext } from '../../SubPatinetProvider';
import styles from '../oocyteRetrieval/oocyteRetrival.module.css';
import { useClinic } from '../../ClinicProvider';
import { getUsersByClinicId } from '../../../services/apiService';
import EmbryoDetailsTable from './embryoDetailsTable';
import { useNavigate } from 'react-router-dom';
import { Clinician, CycleData, CycleItem, getARTCycleList, Role, ROLES } from '../oocyteRetrieval/OocyteRetrival.helper';
import StatusChip from '../../customTableComponent/statusChip';
import EmbryoTransferDetails from './embryoTransferDetails';

interface EmbryoTransferFormProp {
  onFormChange?: (hasChanged: boolean) => void;
}

const EmbryoTransfer: React.FC<EmbryoTransferFormProp> = ({ onFormChange}) => {
  const handleFormChange = onFormChange || (() => {});
  const [hasReset, setHasReset] = useState(false); 
  const { patientId } = usePatientContext();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [initialValues, setInitialValues] = useState<EmbryoTransferType>(initialEmbryoTransfer);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openResetConfirmDialog, setOpenResetConfirmDialog] = useState(false); 
  const { ClinicId } = useClinic();
  const navigate = useNavigate();
  const [cycleStatus, setCycleStatus] = useState<string | null>(null);

  const [oocyteRetrievalDataAvailable, setOocyteRetrievalDataAvailable] = useState<boolean>(false); // New state variable
  const [isEditing, setIsEditing] = useState(false);
  const [cycleList, setCycleList] = useState<CycleItem[]>([]);
  const [selectedCycle, setSelectedCycle] = useState<number | null>(null);
  const [embryoDetails, setEmbryoDetails] = useState<CycleEmbryoDetails[]>([]);


  const sanitizeEmbryoTransfer = (values: EmbryoTransferType): EmbryoTransferType => {
    const sanitizedValues = { ...values };
    if (sanitizedValues.start_time !== null && sanitizedValues.start_time.trim().length === 5) {
      sanitizedValues.start_time = `${sanitizedValues.start_time}:00`;
    }
    if (sanitizedValues.end_time !== null && sanitizedValues.end_time.trim().length === 5) {
      sanitizedValues.end_time = `${sanitizedValues.end_time}:00`;
    }

    return sanitizedValues;
  };
  
  const fetchOocyteRetrieval = useCallback(
    (cycleId: number ) => {
      showLoader();   
      if (cycleId) {
        getEmbryoTransfer(cycleId) 
          .then((data) => {
            setInitialValues({ ...initialEmbryoTransfer, ...data });
            console.log('Updated initialValues:', { ...initialEmbryoTransfer, ...data });
            setOocyteRetrievalDataAvailable(true); 
          })
          .catch(() => {
            setOocyteRetrievalDataAvailable(false); 
            console.error('Error fetching Oocyte Retrieval');
          })
          .finally(() => {
            hideLoader();
            setInitialValues((prevValues) => ({
              ...prevValues,
              cycle: cycleId
            }));
          });

        getEmbryoTransferDetails(cycleId)
          .then((data) => {                
            setEmbryoDetails(data || []); 
          })
          .catch((error) => {
            console.error('Error fetching embryo details:', error);
            setEmbryoDetails([]); 
          });
      }
    },
    [showLoader, hideLoader] 
  );  

  const handleSubmit = async (
    values: EmbryoTransferType,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<EmbryoTransferType>
  ) => {
    showLoader();

    // Use the utility function to sanitize values
    const sanitizedValues = sanitizeEmbryoTransfer(values);

    const cycleId = sanitizedValues.cycle as number; 
    
    const operation = isEditing
      ? updateEmbryoTransfer(sanitizedValues?.id as number, sanitizedValues)
      : addEmbryoTransfer(sanitizedValues);
    operation
      .then((updatedData) => {
        addAlert('  Embryo Transfer saved successfully', 'success');
        setInitialValues(updatedData);
        resetForm({ values: updatedData });
        fetchOocyteRetrieval(cycleId);
        setIsEditing(false);

      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.type === 'validation_error') {
          const apiErrors = error.response.data.errors;
          const formikErrors: FormikErrors<EmbryoTransferType> = {};

          apiErrors.forEach((error: { attr: keyof EmbryoTransferType; detail: string }) => {
            formikErrors[error.attr] = error.detail;
          });

          setErrors(formikErrors);
        } else {
           
          console.error('An error occurred while saving EmbryoTransfer.');
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  const handleReset = (
    resetForm: FormikHelpers<EmbryoTransferType>['resetForm'],
    setFieldValue: FormikHelpers<EmbryoTransferType>['setFieldValue']
  ) => {
    setOpenResetConfirmDialog(false); 
    resetForm(); 
    setHasReset(true); 
    
    Object.keys(initialEmbryoTransfer).forEach((field) => {
      const initialValue = initialEmbryoTransfer[field as keyof EmbryoTransferType];
      if (typeof initialValue === 'number' || initialValue === null) {
        setFieldValue(field, '');
      } else {
        setFieldValue(field, initialValue);
      }
    });
  };
  
  useEffect(() => {
    showLoader();
    getARTCycleList(patientId)
      .then((data) => {        
        const formattedData = data.map((cycle: CycleData) => ({
          id: cycle.id,
          value: cycle.cycle_number,
          label: cycle.cycle_number,
          status: cycle.status,
          procedure:cycle?.procedure?.procedures
        }));
        setCycleList(formattedData);

        // Set default cycle if the list is not empty
        if (formattedData.length > 0) {
          const procedures = data[0].procedure?.procedures;
          // If procedures include "FET", set the last cycle data
          if (procedures === 'FET') {
            const lastCycle = data[1] ;
            setSelectedCycle(lastCycle.id); 
            setCycleStatus(lastCycle.status); 
            fetchOocyteRetrieval(lastCycle.id); 
          } else {
          // If not "FET", use the first cycle by default
            setSelectedCycle(formattedData[0].id);
            setCycleStatus(formattedData[0].status);
            fetchOocyteRetrieval(formattedData[0].id);
          }
          
          setInitialValues((prevValues) => ({
            ...prevValues,
            cycle_start_date: data[0]?.cycle_start_date
          }));
        }
      })
      .catch(() => {
        console.error('Error fetching cycle list');
      })
      .finally(() => {
        hideLoader();
        setIsEditing(false);
      });
  }, [patientId, showLoader, hideLoader, fetchOocyteRetrieval]);


  const handleCycleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = Number(event.target.value);    
    const selectedCycleItem = cycleList.find((item) => item.id === selectedValue);    
    // Only proceed if procedure is defined and does not include 'FET'
    if (
      (selectedCycleItem?.procedure && !selectedCycleItem.procedure.includes('FET')) ||
      !selectedCycleItem?.procedure
    ) {
      setSelectedCycle(selectedValue);
      setCycleStatus(selectedCycleItem?.status || null);    
      fetchOocyteRetrieval(selectedValue);
    }
  };
  
  const handleCombinedChange = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formikHandleChange: (event: React.ChangeEvent<any>) => void
  ) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formikHandleChange(event);
    handleCycleChange(event as React.ChangeEvent<HTMLInputElement>);
    setIsEditing(false);
  };

  // Initialize state for doctor lists
  const [doctorList, setDoctorList] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    ANESTHETIST: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });
  const fetchCliniciansByRole = useCallback((role: Role) => {
    showLoader();
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {
        setDoctorList(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]); 

  // Fetch clinicians for each role on component mount or when ClinicId changes
  useEffect(() => {
    ROLES.forEach(fetchCliniciansByRole);
  }, [fetchCliniciansByRole]); // Use the memoize

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const navigateToCycleOverview = () => {
    // Redirect to the desired route
    navigate(`/clinical/patients/cycle-overview/${patientId}`);
  };


  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={EmbryoTransferValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >      
      {({         
        values,
        setFieldValue,
        submitForm,
        validateForm,
        errors,
        setFieldError, 
        dirty,  handleBlur, handleChange, handleSubmit, isSubmitting, touched, resetForm, setFieldTouched }) => (
        <>        
          <FormObserver dirty={dirty} onFormChange={handleFormChange} />          
          <ThemeProvider theme={theme}>
            <form onSubmit={handleSubmit}>
              <Box sx={{flexGrow: 1, marginRight:2}}>
                <Grid2 container spacing={2}  sx={{ marginTop: 1 }}>
                  {/* Cycle No. */}
                  <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                    <TextField
                      fullWidth
                      size="small"
                      label={RequiredLabel('Cycle No', true)}
                      name="cycle"
                      select
                      value={values.cycle || ''}
                      onBlur={handleBlur}
                      onChange={handleCombinedChange(handleChange)}
                      error={touched.cycle && Boolean(errors.cycle)}
                      // helperText={touched.cycle && errors.cycle}
                    >
                      {cycleList.map((item) => ( 
                        <MenuItem key={item.value} value={item.id}>
                          {item.label} &nbsp;|&nbsp; &nbsp; 
                          <StatusChip 
                            value={item.status === 'TERMINATED' ? 'Inactive' : capitalizeTitle(item.status)} 
                          />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm:4, md: 4, lg:3 }} 
                    sx={{ textAlign: 'left' }}>
                    <Link
                      href="#"
                      onClick={navigateToCycleOverview}
                      sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '23.2px', textAlign: 'left', marginLeft:2}}
                    >
                      Cycle Overview
                    </Link>
                  </Grid2>


                  {/* Terminate Cycle (Button) */}                  
                  {cycleStatus !== 'TERMINATED' ? (
                    <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                      sx={{ textAlign: 'right', }}
                    >
                      <Button variant='outlined'  color='error' onClick={navigateToCycleOverview}>
                        Terminate Cycle
                      </Button>
                    </Grid2>
                  ) : (
                    <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                      sx={{ textAlign: 'right'}}
                    >
                      <button className={styles.terminateButton}  onClick={navigateToCycleOverview}>
                        Create New Cycle
                      </button>
                    </Grid2>
                  )}

                </Grid2>

                {cycleStatus === 'Inactive' || cycleStatus === 'TERMINATED' || (oocyteRetrievalDataAvailable && !isEditing) ? (
                  <EmbryoTransferDetails cycleId={selectedCycle} />
                ) :  (
                  <>
                    <Grid2 container spacing={2}  sx={{ marginTop: 2 }}>
                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label={RequiredLabel('Transfer Method', true)} 
                          name="transfer_method"
                          select
                          value={values.transfer_method || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.transfer_method && Boolean(errors.transfer_method)}
                          // helperText={touched.transfer_method && errors.transfer_method}
                        >
                          {transferMethodItems.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}  sx={{ marginTop: 3 }}>
                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Oocyte Fertilized"
                          name="oocyte_fertilized_method"
                          type="number"
                          value={values.oocyte_fertilized_method || null}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.oocyte_fertilized_method&& Boolean(errors.oocyte_fertilized_method)}
                          // helperText={touched.oocyte_fertilized_method && errors.oocyte_fertilized_method}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Embryo Cryopreserved"
                          name="embryo_cryo_preservation_method"
                          type="number"
                          value={values.embryo_cryo_preservation_method || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.embryo_cryo_preservation_method && Boolean(errors.embryo_cryo_preservation_method)}
                          // helperText={touched.embryo_cryo_preservation_method && errors.embryo_cryo_preservation_method}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Fresh Transfer"
                          name="fresh_embryo_transfer_method"
                          type="number"
                          value={values.fresh_embryo_transfer_method || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.fresh_embryo_transfer_method && Boolean(errors.fresh_embryo_transfer_method)}
                          // helperText={touched.fresh_embryo_transfer_method && errors.fresh_embryo_transfer_method}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Frozen Transfer"
                          name="frozen_embryo_transfer_method"
                          type="number"
                          value={values.frozen_embryo_transfer_method || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.frozen_embryo_transfer_method && Boolean(errors.frozen_embryo_transfer_method)}
                          // helperText={touched.frozen_embryo_transfer_method && errors.frozen_embryo_transfer_method}
                        />
                      </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}  sx={{ marginTop: 3 }}>
                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Date"
                          name="date"
                          type="date"
                          value={values.date || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.date && Boolean(errors.date)}
                          // helperText={touched.date && errors.date}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Start Time"
                          name="start_time"
                          type="time"
                          value={values.start_time || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.start_time && Boolean(errors.start_time)}
                          // helperText={touched.start_time && errors.start_time}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="End Time"
                          name="end_time"
                          type="time"
                          value={values.end_time || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.end_time && Boolean(errors.end_time)}
                          helperText={
                            touched.end_time &&
                        (errors.end_time === 'End Time should be after Start Time'
                          ? errors.end_time
                          : '')
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="ET Dish ID"
                          type='number'
                          name="et_dish_id"
                          value={values.et_dish_id || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.et_dish_id && Boolean(errors.et_dish_id)}
                          helperText={touched.et_dish_id && errors.et_dish_id}
                        />
                      </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}  sx={{ marginTop: 3 }}>
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          id="embryologist"                      
                          options={doctorList['EMBRYOLOGIST'] || []} 
                          getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                          value={doctorList['EMBRYOLOGIST'].find((doctor) => doctor.id === values.embryologist) || null}
                          onChange={(_, value) => {
                            setFieldValue('embryologist', value?.id || '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={RequiredLabel('Embryologist', true)}
                              error={touched.embryologist && Boolean(errors.embryologist)}
                              // helperText={touched.embryologist && errors.embryologist}
                            />
                          )}
                        />
                      </Grid2>
                  

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          id="witness_embryologist"
                          options={doctorList['EMBRYOLOGIST'] || []}
                          getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                          value={doctorList['EMBRYOLOGIST'].find((doctor) => doctor.id === values.witness_embryologist) || null}
                          onChange={(_, value) => {
                            setFieldValue('witness_embryologist', value?.id || '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={RequiredLabel('Witness Embryologist', true)}
                              error={touched.witness_embryologist && Boolean(errors.witness_embryologist)}
                              helperText={
                                touched.witness_embryologist &&
                            (errors.witness_embryologist === 'The witness embryologist cannot be the same as the embryologist.'
                              ? errors.witness_embryologist
                              : '')
                              }
                            />
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          id="nurse"
                          options={doctorList['NURSE'] || []}
                          getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                          value={doctorList['NURSE'].find((doctor) => doctor.id === values.nurse) || null}
                          onChange={(_, value) => {
                            setFieldValue('nurse', value?.id || '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Nurse'
                              error={touched.nurse && Boolean(errors.nurse)}
                              // helperText={touched.nurse && errors.nurse}
                            />
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.performed_under_anesthesia || false}
                              onChange={handleChange}
                              name="performed_under_anesthesia"
                              color="primary"
                            />
                          }
                          label="Performed Under Anesthesia"
                        />
                      </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}  sx={{ marginTop: 3 }}>
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          id="anesthesia"
                          options={typeOfAnesthesiaItems} // Use the typeOfAnesthesiaItems array
                          getOptionLabel={(option) => option.label} // Display the label for the item
                          value={typeOfAnesthesiaItems.find((item) => item.value === values.anesthesia) || null} // Match the selected value
                          onChange={(_, value) => {
                            setFieldValue('anesthesia', value?.value || ''); // Set the value from the selected item
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Anesthesia"
                              error={touched.anesthesia && Boolean(errors.anesthesia)}
                              // helperText={touched.anesthesia && errors.anesthesia}
                            />
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          id="anesthetist"
                          options={doctorList['ANESTHETIST'] || []}
                          getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                          value={doctorList['ANESTHETIST'].find((doctor) => doctor.id === values.anesthetist) || null}
                          onChange={(_, value) => {
                            setFieldValue('anesthetist', value?.id || '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label='Anesthetist'
                              error={touched.anesthetist && Boolean(errors.anesthetist)}
                              // helperText={touched.anesthetist && errors.anesthetist}
                            />
                          )}
                        />
                      </Grid2>

                      {/* Endometrium Thickness */}
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Endometrium Thickness"
                          name="endometrium_thickness"
                          value={values.endometrium_thickness || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.endometrium_thickness && Boolean(errors.endometrium_thickness)}
                          helperText={touched.endometrium_thickness && errors.endometrium_thickness}
                        />
                      </Grid2>

                      {/* Endometrium Pattern Dropdown */}
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          select
                          fullWidth
                          size="small"
                          label="Endometrium Pattern"
                          name="endometrium_pattern"
                          value={values.endometrium_pattern || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.endometrium_pattern && Boolean(errors.endometrium_pattern)}
                          // helperText={touched.endometrium_pattern && errors.endometrium_pattern}
                        >
                          {endometriumPatternItems.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid2>
                    </Grid2>
                    <Grid2 container spacing={2}  sx={{ marginTop: 3 }}>
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* Ultrasound Checkbox */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.is_ultrasound || false}
                              onChange={handleChange}
                              name="is_ultrasound"
                              color="primary"
                            />
                          }
                          label="Ultrasound"
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* Ultrasound Method Dropdown */}
                        <TextField
                          select
                          fullWidth
                          size="small"
                          label="Ultrasound Method"
                          name="ultrasound_method"
                          value={values.ultrasound_method || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.ultrasound_method && Boolean(errors.ultrasound_method)}
                          // helperText={touched.ultrasound_method && errors.ultrasound_method}
                        >
                          {ultrasoundMethodItems.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* Transfer Depth */}
                        <TextField
                          fullWidth
                          size="small"
                          label="Transfer Depth"
                          name="transfer_depth"
                          value={values.transfer_depth || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.transfer_depth && Boolean(errors.transfer_depth)}
                          // helperText={touched.transfer_depth && errors.transfer_depth}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* Level of Difficulty Dropdown */}
                        <TextField
                          select
                          fullWidth
                          size="small"
                          label="Level of Difficulty"
                          name="difficulty_level"
                          value={values.difficulty_level || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.difficulty_level && Boolean(errors.difficulty_level)}
                          // helperText={touched.difficulty_level && errors.difficulty_level}
                        >
                          {levelOfDifficultyItems.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}  sx={{ marginTop: 3 }}>
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          select
                          fullWidth
                          size="small"
                          label="Catheter"
                          name="catheter"
                          value={values.catheter || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.catheter && Boolean(errors.catheter)}
                          // helperText={touched.catheter && errors.catheter}
                        >
                          {catheterItems.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* Catheter Tube  */}
                        <TextField
                          fullWidth
                          size="small"
                          label="Catheter Tube"
                          name="catheter_tube"
                          type='number'
                          value={values.catheter_tube || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.catheter_tube && Boolean(errors.catheter_tube)}
                          // helperText={touched.catheter_tube && errors.catheter_tube}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* catheter_remarks */}
                        <TextField
                          fullWidth
                          size="small"
                          label="Catheter Remarks"
                          name="catheter_remarks"
                          value={values.catheter_remarks || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.catheter_remarks && Boolean(errors.catheter_remarks)}
                          helperText={touched.catheter_remarks && errors.catheter_remarks}
                          multiline
                          minRows={1}  
                          maxRows={3}  
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* No of Attempts Dropdown */}
                        <TextField
                          select
                          fullWidth
                          size="small"
                          label="No of Attempts"
                          name="no_of_attempts"
                          value={values.no_of_attempts || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.no_of_attempts && Boolean(errors.no_of_attempts)}
                          // helperText={touched.no_of_attempts && errors.no_of_attempts}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                        </TextField>
                      </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}  sx={{ marginTop: 3 }}>
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* distance_from_fundus  */}
                        <TextField
                          fullWidth
                          size="small"
                          label="Distance From Fundus"
                          name="distance_from_fundus"
                          type='number'
                          value={values.distance_from_fundus || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.distance_from_fundus && Boolean(errors.distance_from_fundus)}
                          // helperText={touched.distance_from_fundus && errors.distance_from_fundus}
                        />
                      </Grid2>
                  
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* distance_from_fundus  */}
                        <TextField
                          fullWidth
                          size="small"
                          label="Embryo Retained"
                          name="embryo_retained"
                          type='number'
                          value={values.embryo_retained || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.embryo_retained && Boolean(errors.embryo_retained)}
                          // helperText={touched.embryo_retained && errors.embryo_retained}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* Use of Tenaculum Checkbox */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.is_tenaculum_used || false}
                              onChange={handleChange}
                              name="is_tenaculum_used"
                              color="primary"
                            />
                          }
                          label="Use of Tenaculum"
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* Use of Stylet Checkbox */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.is_stylet_used || false}
                              onChange={handleChange}
                              name="is_stylet_used"
                              color="primary"
                            />
                          }
                          label="Use of Stylet"
                        />
                      </Grid2>
                  
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* Use of Embryo Glue Checkbox */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.is_embryo_glue_used || false}
                              onChange={handleChange}
                              name="is_embryo_glue_used"
                              color="primary"
                            />
                          }
                          label="Use of Embryo Glue"
                        />
                      </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}  sx={{ marginY: 3, marginRight:2 }}>                  
                      <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                        <EmbryoDetailsTable embryoDetails={embryoDetails} />
                      </Grid2>                      
                    </Grid2>
                  
                    <Grid2 container spacing={2}  sx={{ marginTop: 4 }}>
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* complications */}
                        <TextField
                          fullWidth
                          size="small"
                          label="Complications"
                          name="complications"
                          value={values.complications || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.complications && Boolean(errors.complications)}
                          helperText={touched.complications && errors.complications}
                          multiline
                          minRows={1}  
                          maxRows={3}  
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* notes */}
                        <TextField
                          fullWidth
                          size="small"
                          label="Notes"
                          name="notes"
                          value={values.notes || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.notes && Boolean(errors.notes)}
                          helperText={touched.notes && errors.notes}
                          multiline
                          minRows={1}  
                          maxRows={3}  
                        />
                      </Grid2>
            
                      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Pregnancy Date"
                          name="pregnancy_date"
                          type="date"
                          value={values.pregnancy_date || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.pregnancy_date && Boolean(errors.pregnancy_date)}
                          helperText={touched.pregnancy_date && errors.pregnancy_date}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid2>             
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.is_cycle_closed || false}
                              onChange={handleChange}
                              name="is_cycle_closed"
                              color="primary"
                            />
                          }
                          label="Cycle Close"
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                        {/* cycle_close_reason */}
                        <TextField
                          fullWidth
                          size="small"
                          label="Cycle Close Reason"
                          name="cycle_close_reason"
                          value={values.cycle_close_reason || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched.cycle_close_reason && Boolean(errors.cycle_close_reason)}
                          helperText={touched.cycle_close_reason && errors.cycle_close_reason}
                          multiline
                          minRows={1}  
                          maxRows={3}  
                        />
                      </Grid2>
                    </Grid2>
                  </>
                )}

                
                {(cycleStatus !== 'TERMINATED' && (!oocyteRetrievalDataAvailable || isEditing)) && (
                  <Box className={styles.customButtonDiv}>                  
                    <button
                      type="button"
                      className={styles.resetButton}
                      onClick={() => setOpenResetConfirmDialog(true)} 
                    >
                  Reset
                    </button>
                    <button
                      type="button"                      
                      className={`${styles.customButton} ${isSubmitting || !dirty ? styles.disabled : ''}`}
                      disabled={isSubmitting || !dirty} 
                      onClick={() => {
                        console.log('Starting form validation...');                        
                        validateForm().then((formErrors) => {
                          console.log('Form validation completed. Form errors:', formErrors);
                          if (Object.keys(formErrors || {}).length === 0 && !isSubmitting) {
                            console.log('No errors found and form is not submitting. Opening confirm dialog...');
                            setOpenConfirmDialog(true);
                          } else {
                            console.log('Form has errors or is submitting. Marking fields as touched...');
                            
                            Object.keys(values).forEach((field) => {
                              console.log(`Marking field ${field} as touched.`);
                              setFieldTouched(`${field}`, true, false);
                            }); 
                          }
                        }).catch((err) => {
                          console.error('Error during form validation:', err);
                        });
                      }}                  
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </span>
                    </button>                  
                  </Box>
                )}

                {(cycleStatus !== 'TERMINATED' &&  (oocyteRetrievalDataAvailable && !isEditing)) && (
                  <Box className={styles.customButtonDiv}>
                    <button
                      type="button"
                      className={styles.customButton}
                      onClick={handleEditClick}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        Edit
                      </span>
                    </button>
                  </Box>
                )}

                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    setOpenConfirmDialog(false);
                    submitForm(); 
                  }}
                  type="save"
                  title="Oocyte Retrieval"
                  contentMessage="Are you sure you want to save oocyte retrieval?"
                />
                <ConfirmationModal
                  open={openResetConfirmDialog}
                  handleClose={() => setOpenResetConfirmDialog(false)} 
                  onConfirm={() => handleReset(resetForm, setFieldValue)} 
                  title="EmbryoTransfer"
                  contentMessage="Are you sure you want to reset oocyte retrieval ?"
                  type={'delete'}
                />
              </Box>
              
            </form>
          </ThemeProvider>
        </>
      )}
    </Formik>
  );
};

export default EmbryoTransfer;