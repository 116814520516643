/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, Tabs, Tab, ThemeProvider } from '@mui/material';
import { CycleData } from '../../../components/Surgery/oocyteRetrieval/OocyteRetrival.helper';
import { fetchMockCycleDetails } from './embryoCulture.helpers';

import styles from './embryoCulture.module.css';
import { theme } from '../../../theme';

import CycleDayView from './cycleDayView';

interface EmbryoCultureViewProps {
  selectedCycle: CycleData;  // This contains only cycle_id
}

const EmbryoCultureView: React.FC<EmbryoCultureViewProps> = ({ selectedCycle }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cycleDetails, setCycleDetails] = useState<any>(null);  // Store detailed cycle data
  const [loading, setLoading] = useState<boolean>(false);  // Loading state

  // Define tab labels
  const tabs = ['All Days', 'Day 0', 'Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];

  // Fetch cycle details when selectedCycle changes
  const fetchDetails = useCallback(() => {
    if (selectedCycle?.id) {
      setLoading(true);
      fetchMockCycleDetails(selectedCycle?.id)  // Use mock data
        .then(response => {
          setCycleDetails(response);  // Store cycle details from the mock response
        })
        .catch(error => {
          console.error('Error fetching cycle details:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedCycle]);

  // Trigger fetch when selectedCycle is available
  useEffect(() => {
    fetchDetails();
  }, [selectedCycle, fetchDetails]);

  // Handle tab change
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  // Extract data for the selected tab
  const getDayData = (dayIndex: number) => {
    if (dayIndex === 0) {
      // Handle "All Days" tab
      return cycleDetails?.allData || [];  // Mock data for all days
    }
    // Handle specific day data
    const dayKey = `day${dayIndex}`; // Assuming the days are stored as day0, day1, etc., in `cycleDetails`
    return cycleDetails?.[dayKey] || null;
  };


  // Button styles using useMemo
  const buttonStyles = useMemo(
    () => ({
      base: {
        backgroundColor: 'rgba(250, 250, 250, 1)',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: 'rgba(193, 193, 193, 1)',
        textTransform: 'capitalize',
        '&:hover': {
          color: '#E17E61 !important', // Ensure your color takes precedence
          fontWeight: 500,
          backgroundColor: '#FFFFFF',
          boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.2)', // Highlight with box shadow
          borderRadius: '10px',
          marginRight:'2px'
        },
      },
      selected: {
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 2px 8px 2px rgba(225, 126, 97, 0.6)', // Highlight with box shadow
        fontWeight: 700,
        borderRadius: '10px',
        color: '#E17E61', // Set selected text color
      },
    }),
    []
  );

  // Render content for the selected tab
  const renderTabContent = () => {
    if (loading) {
      return (
        <Box textAlign="center" mt={2}>
          <Typography>Loading...</Typography>
        </Box>
      );
    }

    if (!cycleDetails) {
      return (
        <Box textAlign="center" mt={2}>
          <Typography>No cycle data available.</Typography>
        </Box>
      );
    }

  
    return <CycleDayView dayIndex={selectedTab} selectedCycle= {selectedCycle}/>;
  };

  return (

    <ThemeProvider theme={theme}>
      <Box>
        <Tabs
          sx={{
            width: '100%', // Full width
            '& .MuiTabs-flexContainer': {
              padding: '16px', // Add desired padding
            },
            '& .MuiTabs-indicator': {
              display: 'none', // Remove the bottom indicator line
            },
            borderBottom: 'none', // Remove bottom border on the Tabs component
            justifyContent: 'space-between', // Ensure tabs are evenly spaced
            padding: '10px',
            borderRadius: '10px',

            '& .MuiTab-root.Mui-selected': {
              color: '#E17E61 !important', // Ensure your color takes precedence
              fontWeight: 'bold',
              backgroundColor: '#FFFFFF',
              boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.2)', // Highlight with box shadow
              borderRadius: '10px',
              marginRight:'4px'
            },
          }}
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              sx={{
                ...(buttonStyles.base),
                ...(selectedTab === index && buttonStyles.selected), // Apply selected styles
                flexGrow: 1, // Ensure each tab takes equal space
                textAlign: 'center', // Center-align text within each tab
              }}
            />
          ))}
        </Tabs>


        <Box mt={2}>{renderTabContent()}</Box>
      </Box>

    </ThemeProvider>
  );
};

export default EmbryoCultureView;
