// CycleDayView.tsx
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import DayFirst from './dayFirst';
import DayZero from './dayZero';
import { CycleData } from '../../../components/Surgery/oocyteRetrieval/OocyteRetrival.helper';
import AllDay from './all-day';
import DayThird from './dayThird';
import DayTwo from './dayTwo';
import DayFour from './dayFour';

interface DayProps {
  dayIndex: number;
  selectedCycle: CycleData;  
}

const CycleDayView: React.FC<DayProps> = ({ dayIndex,selectedCycle }) => {
  // eslint-disable-next-line no-console
  useEffect(()=>{console.log(selectedCycle);
  },[selectedCycle]);
  const renderDayComponent = () => {
    switch (dayIndex) {
    case 1:
      return <DayZero  selectedCycleId ={selectedCycle.id}/>;
    case 2:
      return <DayFirst selectedCycleId ={selectedCycle.id} />;
    case 3:
      return <DayTwo  selectedCycleId ={selectedCycle.id}/>;
    case 4:
      return <DayThird selectedCycleId ={selectedCycle.id} />;
    case 5 :
      return <DayFour selectedCycleId ={selectedCycle.id} selectedDay={4} />;
    case 6 :
      return <DayFour selectedCycleId ={selectedCycle.id} selectedDay={5} />;
    case 7 :
      return <DayFour selectedCycleId ={selectedCycle.id} selectedDay={6} />;
    case 8 :
      return <DayFour selectedCycleId ={selectedCycle.id} selectedDay={7} />;
    default:
      return <AllDay selectedCycleId ={selectedCycle.id}/>;
    }
  };

  return (
    <Box>
      {renderDayComponent()}
    </Box>
  );
};

export default CycleDayView;
