/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Box, Button, Grid, IconButton, MenuItem, TextField, ThemeProvider } from '@mui/material';
import { ColumnConfig, DiagnosisMaster, GroupConfig, TableRowData } from '../../types'; 
import { DiagnosisMenuItems, diagnosistype, DiagnosisTypeEnum, getDiagnosis, getDoctors, initialDiagnosis, PatientDiagnosis, PatientDiagnosisValidationSchema, postDiagnosis, User} from './diagnosisHelper';
import { ConfirmationModal } from '../confirmationModal';
import { useAlert } from '../alertProvider';
import { useLoader } from '../loaderProvider/loader';
import { patientDetailsObservable } from '../../services/sharedService';
import { Field, FieldArray, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { theme } from '../Medical_History_Forms/themeprovider';
import DeleteIcon from '@mui/icons-material/Delete';
import HPIBack from '../../assets/SVG/HPIBack.svg';
import { usePatientContext } from '../SubPatinetProvider';
import { RequiredLabel } from '../../utils/commonUtils';


interface AddDiagnosisProps {
  onBackClick: () => void;
  data: any; // This is the prop you're passing (the selected data)
}

const AddDiagnosis: React.FC<AddDiagnosisProps> = ({ onBackClick, data }) => {
  const [inputValue, setInputValue] = useState(''); // State for input value
  const [diagnosis, setDiagnosis] = useState<DiagnosisMaster[]>([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);
  const [doctors, setDoctors] = useState<User[]>([]);
  const [initialValues, setInitialValues] = useState<{ diagnosis: diagnosistype[] }>({ diagnosis: [initialDiagnosis],  });
  const { patientId } = usePatientContext();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); 
  const contentMessage = 'Are you sure you want to save  Diagnosis?';
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [patientDetails, setpatientDetails] = useState<any >(null);

  
  

  useEffect(() => {
    const subscription = patientDetailsObservable.subscribe((value) => {
      setpatientDetails(value);
    });

    return () => subscription.unsubscribe();
  }, []);
  useEffect(() => {
    getDoctors().then((res) => {
      setDoctors(res.objects);
    });
  }, []);

  const handleSelectionChange = (
    value: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<{ diagnosis: diagnosistype[]; }>>,
    values: { diagnosis: any[] }
  ) => {
    if (value) {
      const filteredDiagnosis = values.diagnosis.filter(
        (diagnosis) => diagnosis.diagnosis
      );
  
      const isDuplicate = filteredDiagnosis.some(
        (diagnosis) => diagnosis.diagnosis === value.id
      );
  
      if (isDuplicate) {
        addAlert('This diagnosis has already been added.', 'info');
        return; 
      }

 
      setFieldValue('diagnosis', [
        ...filteredDiagnosis,
        {
          patient: patientId,
          diagnosis: value.id,
          diagnosis_code: value.code,
          diagnosis_name: value.name,
          diagnosis_type: '',
          doctor: null,
          notes: null,
        },
      ]);
    }
  };
  

  const onBack = () => {
    onBackClick();
  };

  const handleSearch = (searchValue: string) => {
    if (searchValue.length>0) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        const query = `?gender=${patientDetails.gender}&search=${searchValue}`;
        getDiagnosis(query).then((res) => {
          setDiagnosis(res.objects);
        });
      }, 300); // Adjust delay (e.g., 300ms) as needed
    }
  };

  

  const handleSubmit = (values: any, { setSubmitting, resetForm }: any) => {
    showLoader();
    const convertedData:PatientDiagnosis[] = values.diagnosis.map((test:any) => {
      
      return {
        ...(test.id && { id: test.id }),
        patient: test.patient,       
        diagnosis: test.diagnosis,            
        diagnosis_type: test.diagnosis_type as DiagnosisTypeEnum, 
        doctor:  test.doctor,
        notes: test.notes || ''
      };
    });
    
    Promise.all(convertedData.map(data => postDiagnosis(data)))
      .then(() => {
        addAlert('Diagnosis added successfully.', 'success');
        onBackClick();
        
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        addAlert('An error occurred while saving the Diagnosis. Please try again.', 'error');
      })
      .finally(()=>{
        hideLoader();
      });
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  useEffect(() => {
    if (data) {
      const doctor = doctors.find(doc => doc.id === data.doctor);
      
      setInitialValues({
        diagnosis: [{
          id: data.id,
          patient: patientId,
          diagnosis: data.diagnosis,
          diagnosis_code: data.diagnosis_code,
          diagnosis_name: data.diagnosis_name,
          diagnosis_type: data.diagnosis_type || '',
          doctor: doctor ? doctor.id : null, 
          notes: data.notes || '',
        }],
      });
    }
  }, [data, patientId, doctors]);

  const [missingFields, setMissingFields] = useState<{ [index: number]: string[] }>({});

  const handleOpenModal = (values: any) => {
    let allFieldsFilled = true;
    const updatedMissingFields: { [index: number]: string[] } = {};
    if (Array.isArray(values.diagnosis)) {
      values.diagnosis.forEach((row: any, index: number) => {
        const missingFields: string[] = [];
    
        if (!row.diagnosis_code) missingFields.push('diagnosis_code');
        if (!row.diagnosis_name) missingFields.push('diagnosis_name');
        if (!row.diagnosis_type) missingFields.push('diagnosis_type');
        if (!row.doctor) missingFields.push('doctor');
    
        if (missingFields.length > 0) {
          updatedMissingFields[index] = missingFields;
          allFieldsFilled = false;
        }
      });
    } else {
      console.error('values.diagnosis is undefined or not an array');
      allFieldsFilled = false;
    }
    setMissingFields(updatedMissingFields);
  
    if (allFieldsFilled) {
      setOpenConfirmDialog(true);
    }
  };
  
  
  
  

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true} 
      validationSchema={PatientDiagnosisValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        dirty,
        touched,
        errors,
        setFieldTouched,
        submitForm,
        validateForm,
      }) => {
        return (
          <>
            <ThemeProvider theme={{theme}}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={5} display="flex" justifyContent="start">
                    <IconButton onClick={onBack}>
                      <img src={HPIBack} alt="Back" style={{ width: '2.5rem', height: '2.5rem' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
              <Form  onSubmit={(e) => {
                e.preventDefault();
              }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={7}> {/* Occupies 6 out of 12 columns */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start', // Aligns to the start
                          marginBottom: 5,
                        }}
                      >
                        <Autocomplete
                          disablePortal
                          fullWidth
                          options={inputValue.trim() ? diagnosis : []}
                          value={selectedDiagnosis} // Bind the selected value
                          inputValue={inputValue} // Bind the input value
                          onChange={(e, value) => {
                            handleSelectionChange(value, setFieldValue, values); // Handle the selection
                            setInputValue(''); // Clear the search box
                          }}
                          onInputChange={(e, value) => {
                            setInputValue(value); // Update input value state
                            handleSearch(value);
                          }}
                          getOptionLabel={(option) => `${option.code || ''} - ${option.name || ''}`} // Display both code and name in the input
                          renderOption={(props, option) => (
                            <li {...props}>
                              <span>{option.code}</span>&nbsp;-&nbsp;{option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search by code or diagnosis"
                              placeholder="Search by code or diagnosis"
                            />
                          )}
                          noOptionsText=""
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {/* Dynamic Fields for Diagnoses */}
                  <FieldArray
                    name="diagnosis"
                    render={({ remove }) => (
                      <>
                        {values.diagnosis.map((row, index) => (
                          <Grid container spacing={2} marginBottom={3} key={index} alignItems="center">
                            <Grid item xs={2}>
                              {values.diagnosis[index]?.diagnosis_code ? (
                                <span>{values.diagnosis[index].diagnosis_code}</span>
                              ) : (
                                <TextField
                                  value=""
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  disabled
                                  InputLabelProps={{shrink: true,}}
                                  label={RequiredLabel('Diagnosis Code', true)}
                                  error={!!missingFields[index]?.includes('diagnosis_code') && !row.diagnosis_code} 
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '&.Mui-error': {
                                        '& fieldset': {
                                          borderColor: 'red', 
                                        },
                                      },
                                    },
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={2}>
                              {values.diagnosis[index]?.diagnosis_name ? (
                                <span>{values.diagnosis[index].diagnosis_name}</span>
                              ) : (
                                <TextField
                                  value=""
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  InputLabelProps={{shrink: true,}}
                                  disabled
                                  label={RequiredLabel('Diagnosis Name', true)}
                                  error={!!missingFields[index]?.includes('diagnosis_name') && !row.diagnosis_name}
                                  sx={{
                                    '& .MuiOutlinedInput-root': {
                                      '&.Mui-error': {
                                        '& fieldset': {
                                          borderColor: 'red', 
                                        },
                                      },
                                    },
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={2}>
                              <Field
                                name={`diagnosis[${index}].diagnosis_type`}
                                render={({ field }: FieldProps) => (
                                  <TextField
                                    {...field}
                                    label={RequiredLabel('Diagnosis Type', true)}
                                    select
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    value={row.diagnosis_type !== null ? row.diagnosis_type : ''}
                                    onChange={(event) => {
                                      setFieldValue(`diagnosis[${index}].diagnosis_type`, event.target.value);
                                    }}
                                    fullWidth
                                    error={!!missingFields[index]?.includes('diagnosis_type')&& !row.diagnosis_type} 
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '&.Mui-error': {
                                          '& fieldset': {
                                            borderColor: 'red', 
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    {DiagnosisMenuItems.map((item) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>

                            <Grid item xs={2}>
                              <Field
                                name={`diagnosis[${index}].doctor`}
                                render={({ field }: FieldProps) => (
                                  <TextField
                                    {...field}
                                    label={RequiredLabel('Doctor', true)}
                                    select
                                    InputLabelProps={{shrink: true,}}
                                    variant="outlined"
                                    size="small"
                                    value={row.doctor !== null ? row.doctor : ''}
                                    onChange={(event) => {
                                      setFieldValue(`diagnosis[${index}].doctor`, event.target.value);
                                    }}
                                    fullWidth
                                    
                                    error={
                                      Boolean(
                                        touched.diagnosis?.[index]?.doctor &&
                                        typeof errors.diagnosis?.[index] === 'object' &&
                                        errors.diagnosis?.[index]?.doctor
                                      ) || 
                                      (!!missingFields[index]?.includes('doctor') && !row.doctor)
                                    }
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '&.Mui-error': {
                                          '& fieldset': {
                                            borderColor: 'red',
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    {doctors.map((item) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        Dr. {item.first_name} {item.last_name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>
                            {/* Notes */}
                            <Grid item xs={3}>
                              <Field
                                name={`diagnosis[${index}].notes`}
                                as={TextField}
                                label="Notes"
                                variant="outlined"
                                fullWidth
                                multiline
                                value={row.notes !== null ? row.notes : ''}
                                InputLabelProps={{shrink: true,}}
                                error={Boolean(
                                  touched.diagnosis?.[index]?.notes &&
                                typeof errors.diagnosis?.[index] === 'object' &&
                                errors.diagnosis?.[index]?.notes
                                )} />
                            </Grid>
                            {/* Remove Button */}
                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                              <IconButton onClick={() => remove(index)}
                                color="error"
                                sx={{ textTransform: 'none' }}>
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )} />
                </Box>
                {/* Submit Button */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                  <button
                    onClick={() => handleOpenModal(values)}
                    style={{
                      padding: '8px 10px',
                      border: 'none',
                      borderRadius: '7px',
                      fontSize: '16px',
                      fontWeight: '700',
                      backgroundColor:'#F4F4F4',
                      color:'#232323',
                      width: '100px'
                    }}
                  >
                    save
                  </button>
                </Box>
              </Form>
              <ConfirmationModal
                open={openConfirmDialog}
                handleClose={handleCloseConfirmDialog}
                onConfirm={() => {
                  setOpenConfirmDialog(false);
                  handleSubmit();
                }}
                type="save"
                title="Save Diagnosis"
                contentMessage={contentMessage}
              />
            </ThemeProvider></>
        );
      }}
    </Formik>
  );

};

export default AddDiagnosis;
