import React, { useCallback, useEffect, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import EditableTable from '../../customTableComponent/index';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { proceduresList, columns, groupConfig, proceduresListType } from './proceduresHelper';
import ProcedureDialog from './ProcedureDialog'; 
import CycleList from '../CycleList/cycleList';
import { getProcedures } from '../../../services/apiService';
import { convertDateToDDMMYYYY, convertUnderscoreToTitle } from '../../../utils/commonUtils';
import { useLocation } from 'react-router-dom';
interface PatientListProps {
  patientId: number | null;
}

const ProcedureList: React.FC<PatientListProps> = ({ patientId }) => {
  const [tableData, setTableData] = useState<proceduresListType[]>(proceduresList); 
  const [selectedProcedure, setSelectedProcedure] = useState<proceduresListType | null>(null); 
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // For Delete confirmation
  const [procedureToDelete, setProcedureToDelete] = useState<proceduresListType | null>(null); // For storing the procedure to delete
  const [isCycleListVisible, setIsCycleListVisible] = useState(false); 
  const [searchTerm, setSearchTerm] = useState('');
  

  // Callback to handle fetched and transformed data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFetchedData = (data: any[]) => {
    setTableData(data); // Update the state with the transformed data
  };

  // Function to fetch procedures with a callback
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchProcedures = useCallback((callback: (data: any[]) => void) => {
    setTableData([]); // Clear the existing data before fetching new data
    if (patientId) {
      const query = `?patient=${patientId}&order_by=created_on`;
      getProcedures(query)
        .then((data) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const transformedData = data.objects.map((item: any) => ({
            id: item.id,
            advised_date: convertDateToDDMMYYYY(item.created_on) ?? '-',
            procedures_type: item.services[0]?.service_type === 'CYCLE_RELATED'
              ? 'Cycle Related Procedure'
              : 'Non Cycle Related Procedure',
            service_code: item.services[0]?.code ?? '-',
            service_name: item.services[0]?.name ?? '-',
            procedure_notes: item.procedure_notes ?? '-',
            services: item.services ?? '-',
            indication: item.indication ? convertUnderscoreToTitle(item.indication) : '-',
            doctor_name: item.doctor_name,
            donor_info: item.donor_info,
          }));

          callback(transformedData); // Call the callback with the transformed data
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        });
    }
  }, [patientId]); // Dependency on patientId


  // Example useEffect to fetch procedures when patientId or view changes
  useEffect(() => {
    fetchProcedures(handleFetchedData); // Call fetchProcedures with the callback
  }, [patientId, fetchProcedures]);

  
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [view, setView] = useState(new URLSearchParams(location.search).get('view'));
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setView(queryParams.get('view')); // Update state when URL changes

    if (queryParams.get('view') === 'procedure_list') {
      setIsCycleListVisible(false);
      fetchProcedures(handleFetchedData); // Call fetchProcedures with the callback


    }
  }, [location, fetchProcedures]);

  useEffect(() => {
    fetchProcedures(handleFetchedData); // Call fetchProcedures with the callback


  }, [patientId, fetchProcedures]);
  const handleTableChange = (id: number, field: string, value: string | number) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      )
    );
  };

  const handleRowClick = (id: number) => {
    const rowData = tableData.find((procedure) => procedure.id === id);
    
    if (rowData) {
      setSelectedProcedure(rowData); // Set the selected procedure for editing
      setIsDialogOpen(true); // Open the edit dialog
    }
  };

  const handleDeleteClick = (id: number) => {
    const procedureToDelete = tableData.find((procedure) => procedure.id === id);
    if (procedureToDelete) {
      setProcedureToDelete(procedureToDelete); // Set the procedure to be deleted
      setIsDeleteDialogOpen(true); // Open delete confirmation dialog
    }
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setSelectedProcedure(null);
    fetchProcedures(handleFetchedData); // Call fetchProcedures with the callback

  };

  const handleAddButtonClick = () => {
    setIsCycleListVisible(true); 
    // Update the 'view' query parameter to 'select_procedure'
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('view')) {
      queryParams.delete('view'); // Remove the 'view' parameter
      
      const newQueryString = queryParams.toString();
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}${newQueryString ? `?${newQueryString}` : ''}`
      );
    }
    // queryParams.delete('view'); // Remove the 'view' parameter
    // queryParams.set('view', 'select_procedure'); // Update the 'view' parameter
    // window.history.replaceState(null, '', `${location.pathname}?${queryParams.toString()}`);
  };

  const handleSaveProcedure = (updatedProcedure: proceduresListType) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === updatedProcedure.id ? updatedProcedure : row
      )
    );
  };

  const handleCloseCycleList = () => {
    setIsCycleListVisible(false);
  };

  const handleDeleteProcedure = () => {
    if (procedureToDelete) {
      setTableData((prevData) =>
        prevData.filter((row) => row.id !== procedureToDelete.id) 
      );
      setIsDeleteDialogOpen(false);
      setProcedureToDelete(null);
    }
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
    setProcedureToDelete(null);
  };

  return (
    <div>
      {isCycleListVisible ? (
      // Render CycleList only
        <CycleList onClose={handleCloseCycleList} />
      ) : (
      // Render the rest of the components
        <Grid2 container spacing={2} my={1} mx={3} alignItems="center">
          <Grid2 container spacing={2} my={1} mx={1} size={12}>
            <Grid2 size={6}>
              <strong>List of Prescribed Services</strong>
            </Grid2>

            <Grid2 size={4} container justifyContent="flex-end">
              <TextField
                variant="outlined"
                placeholder="Search Services"
                size="small"
                sx={{ width: '80%' }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </Grid2>

            <Grid2 size={2} container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: 'none', fontWeight: 'bold', width: '60%' }}
                startIcon={<AddIcon />}
                onClick={handleAddButtonClick} 
              >
            Add
              </Button>
            </Grid2>
          </Grid2>

          <Grid2 size={12}>
            {tableData.length === 0 ? (
              <div style={{textAlign:'center', fontSize:'18px'}}>No procedures found, click add to create Procedure</div>
            ) : (
              <EditableTable
                columns={columns}
                data={tableData.filter(item =>
                  item.services[0].name.toLowerCase().includes(searchTerm.toLowerCase())
                )}
                // data={tableData}
                actionType="delete"
                groupConfig={groupConfig}
                onChange={handleTableChange}
                onRowClick={handleRowClick}
                onAction={handleDeleteClick}
              />
            )}
          </Grid2>

          {/* Procedure Dialog */}
          <ProcedureDialog
            open={isDialogOpen}
            handleClose={handleClose}
            procedureData={selectedProcedure!}
            onSave={handleSaveProcedure}
          />

          {/* Delete Confirmation Dialog */}
          <Dialog open={isDeleteDialogOpen} onClose={() => handleDeleteDialogClose}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>Are you sure you want to delete this procedure?</DialogContent>
            <DialogActions>
              <Button onClick={() => handleDeleteDialogClose} color="primary">
            Cancel
              </Button>
              <Button onClick={handleDeleteProcedure} color="primary">
            Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Grid2>
      )}
    </div>
  );
};

export default ProcedureList;
