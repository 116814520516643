import React, { useState, useEffect } from 'react';
import { IconButton, Button, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

interface ImageUploadComponentProps {
  onFileUpload: (file: File | null) => void; // Callback to pass the uploaded file to the parent
  imagePathUrl?: string | null; // Optional initial image URL (Base64 string)
}

const ImageUploadComponent: React.FC<ImageUploadComponentProps> = ({ onFileUpload, imagePathUrl }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);

  // Set the initial image preview if provided by the parent
  useEffect(() => {
    if (imagePathUrl) {
      setUploadedImage(imagePathUrl);
    }
  }, [imagePathUrl]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Generate a temporary URL for the uploaded file
      setUploadedImage(imageUrl);
      onFileUpload(file); // Pass the file to the parent
    } else {
      onFileUpload(null); // No file selected
    }
  };

  const handlePreviewClick = () => {
    if (uploadedImage) {
      window.open(uploadedImage, '_blank'); // Open the image in a new tab/window
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {/* Image Upload Button and Preview on Same Line */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {/* Image Upload Button */}
        <label htmlFor="image-upload">
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
          <Button
            variant="outlined"
            component="span"
            style={{
              width: '10rem',
              height: '36px',
              padding: '12px 40px',
              gap: '8px',
              borderRadius: '10px',
              background: 'rgba(243, 243, 243, 1)',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FileUploadOutlinedIcon style={{ width: '20px', marginRight: '8px', color: 'black' }} />
            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '22px',
                textAlign: 'left',
                color: 'rgba(80, 80, 80, 1)',
                textTransform: 'capitalize',
              }}
            >
              Upload
            </Typography>
          </Button>
        </label>

        {/* Show Preview Icon After Upload */}
        {uploadedImage && (
          <IconButton onClick={handlePreviewClick}>
            <Visibility />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ImageUploadComponent;
