/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import OocyteSelector from './occyteSelector';
import DetailsTable from './detailsTable';
import { action945Items, actionsItems, cleavageItems, cultureDishConfigItems, EmbryoDetail, FreezeDialogValues, gradeItems, incubatorItems, initialValuesCryo, pgtFacilityItems, pgtOrderBiopsyCellsItems, pgtorderDetailsItems, pgtResultEmbryoSexItems, pgtResultMoistureItems, pgtResultsItems, pgtTypeItems, stageItems, symmetryItems } from './embryoCulture.helpers';
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, TextField, ThemeProvider } from '@mui/material';
import styles from './embryoCulture.module.css';
import { theme } from '../../../components/Medical_History_Forms/themeprovider';
import { Form } from 'react-router-dom';
import { Formik, FormikErrors, FormikHelpers, getIn } from 'formik';
import { convertUnderscoreToTitle, RequiredLabel } from '../../../utils/commonUtils';
import { Clinician, Role, ROLES } from '../Occyte-Grading/oocyteGrading.helper';
import { useClinic } from '../../../components/ClinicProvider';
import { useAlert } from '../../../components/alertProvider';
import { useLoader } from '../../../components/loaderProvider/loader';
import { getUsersByClinicId } from '../../../services/apiService';
import ImageUploadComponent from '../../../components/Surgery/laparoscopy/imageUploadLaparoscopy';
import { ConfirmationModal } from '../../../components/confirmationModal';
import { columns, CombinedValidationSchema, EmbryoCultureType, EmbryoUpdateDetailsType, getEmbryoCultureDetails,  initialEmbryoCultureValues, initialEmbryoUpdateValues, updateEmbryoDetails, updateEmbryoSummaryDetails } from './dayFour.helpers';
import { AxiosError } from 'axios';
import GradingUnavailable from '../Occyte-Grading/oocyteGradingUnavailable';
import EmbryoCultureViewMode from './ViewMode';
import FreezeActionDialog from './freezeActionDialog';
import OverlayIconPanel from './overlay';
import EmbryoFileUpload from './EmbryoFileUpload';

interface cycleProp {
  selectedCycleId: number;
  selectedDay: number;
}

const DayFour:React.FC<cycleProp> = ({ selectedCycleId, selectedDay }) => {  
  const { ClinicId } = useClinic();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [file, setFile] = useState<File | null>(null); 
  const [imagePathUrl, setImagePathUrl] = useState<string | null>(null);
  const [selectedEmbryos, setSelectedEmbryos] = useState<number[]>([]);
  const [selectedSource, setselectedSource] = useState<string>('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [embryoCulture, setEmbryoCulture] = useState<EmbryoCultureType>(initialEmbryoCultureValues);
  const [embryoDetails, setEmbryoDetails] = useState<EmbryoUpdateDetailsType>(initialEmbryoUpdateValues);

  const [actionStatus, setActionStatus] = useState<'DRAFT' | 'FINALIZED'>('DRAFT');
  // Initialize state for clinician lists
  const [clinicianLists, setClinicianLists] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    ANESTHETIST: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });
  
  const [allEmbryos, setAllEmbryos] = useState<EmbryoDetail[]>([]);
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableData, setTableData] = useState<any[]>([]);
  const [isFinalized, setIsFinalized] = useState(false);
  const [hitSubmitbtn, setHitSubmitbtn] = useState(false);
  const [embryosFound, setEmbryosFound] = useState<boolean>(true); 

  const [isTableDeletable, setIsTableDeletable] = useState(true);

  const [freezeValues, setFreezeValues] = useState<FreezeDialogValues>(initialValuesCryo);
  const [isFreezeDialogOpen, setFreezeDialogOpen] = useState(false);
  
  const fetchEmbryoDetails = useCallback(
    (selectedCycleId: number) => {
      showLoader();
      if (selectedCycleId) {
        getEmbryoCultureDetails(selectedCycleId, selectedDay)  
          .then((data) => {
            
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const finalizedEmbryo = data.embryo.find((embryo: any) => embryo.status === 'FINALIZED');
    
            if (data.embryo && data.embryo.length === 0) {

              setEmbryosFound(false); 
            } else {
              setEmbryosFound(true); 
            }

            // If a finalized embryo is found, set state to true
            if (finalizedEmbryo) {
              setIsFinalized(true);
            }

            if (data.details && Array.isArray(data.details)) {
              const mappedRows = data.embryo
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .filter((item: any) => item.action !== null).sort((a: any, b: any) => a.embryo_number - b.embryo_number).map((item: any) => {
                  const freezeContent = item.action === 'FREEZE'
                    ? {
                      title: 'Action Information',
                      details: [
                        { label: 'Cryo Preserved date', value: item.cryo_preserved_date || '-' },
                        { label: 'Cryo Preserved By', value: item.cryo_preserved_by_name || '-' },
                        { label: 'Cryo Purpose', value: convertUnderscoreToTitle(item.cryo_purpose) || '-' },
                        { label: 'Media', value: convertUnderscoreToTitle(item.cryo_media) || '-' },
                        { label: 'Screening Status', value: item.is_screened  ?'Yes':'No'},
                      ],
                    }
                    : null;

                  const pgtOrderContent = item.pgt_order && 
                    Object.values(item.pgt_order).some(value => value !== null && value !== undefined)
                    ? {
                      title: 'PGT Order Details',
                      details: [
                        { label: 'Date', value: item.pgt_order.pgt_order_date || '-' },
                        {
                          label: 'Time',
                          value: item.pgt_order.pgt_order_time
                            ? new Date(`1970-01-01T${item.pgt_order.pgt_order_time}`).toLocaleString('en-US', {
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true,
                            })
                            : '-',
                        },
                        { label: '# Cell Biopsy', value: convertUnderscoreToTitle(item.pgt_order.pgt_order_biopsy_cells) || '-' },
                        { label: 'Facility', value: convertUnderscoreToTitle(item.pgt_order.pgt_facility) || '-' },
                        { label: 'PGT Type', value: convertUnderscoreToTitle(item.pgt_order.pgt_type) || '-' },
                      ],
                    }
                    : null;
                  const pgtResultContent = item.pgt_results &&
                                      Object.entries(item.pgt_results).some(([key, value]) => 
                                        value !== null && 
                                        value !== undefined && 
                                        (key !== 'pgt_result_notes' || value !== '')
                                      )
                    ?  {
                      title: 'PGT Result',
                      details: [
                        { label: 'Embryo Sex', value: convertUnderscoreToTitle(item.pgt_results.pgt_result_embryo_sex) || '-' },
                        { label: 'PGT Result', value: convertUnderscoreToTitle(item.pgt_results.pgt_results) || '-' },
                        { label: 'Mitosure', value: convertUnderscoreToTitle(item.pgt_results.pgt_result_moisture) || '-' },
                        { label: 'Notes', value: convertUnderscoreToTitle(item.pgt_results.pgt_result_notes) || '-' },
                      ],
                    }
                    : null;
                                    
                  return{
                    id: item.id,
                    embryoNo: item.embryo_number,
                    image:item.files_url,
                    stage: convertUnderscoreToTitle(item?.stage) || '-',
                    grade: item?.grade || '-',
                    pgt_order: pgtOrderContent ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <OverlayIconPanel content={pgtOrderContent} />
                        <span style={{ marginLeft: '5px' }}>Yes</span>
                      </div>
                    ) : (
                      'No'
                    ),
                    pgt_results: pgtResultContent ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <OverlayIconPanel content={pgtResultContent} />
                        <span style={{ marginLeft: '5px' }}>Yes</span>
                      </div>
                    ) : (
                      'No'
                    ),
                    action: item.action === 'FREEZE' && freezeContent ?( 
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <OverlayIconPanel content={freezeContent} />
                        <span style={{ marginLeft: '5px' }}>{convertUnderscoreToTitle(item.action)}</span>
                      </div>): item.action,
                    freezeLocation: '-',
                  };
                });
      
              setTableData(mappedRows);
            }


            
            const finalizedItem = data.embryo?.find((item: EmbryoUpdateDetailsType) => item.status === 'FINALIZED');            
            setIsTableDeletable(!finalizedItem);

            const embryoDetails = data.embryo
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((detail: any) => ({
                embryo_number: detail.embryo_number,
                isFilled: detail.action !== null,
              }))
              .sort((a: { embryo_number: number; }, b: { embryo_number: number; }) => a.embryo_number - b.embryo_number);
            setAllEmbryos(embryoDetails);
            const dayOneEntry = data?.details.find((item: { day: number; }) => item.day === selectedDay);            
            setEmbryoCulture((prevEmbryoCulture) => ({
              ...prevEmbryoCulture,  
              ...dayOneEntry,        
            }));

          })
          .catch((error) => {
            console.error('Error fetching Embryo Details:', error);
          })
          .finally(() => {
            hideLoader();
          });
      }
    },
    [showLoader, hideLoader , selectedDay]
  );


  useEffect(() => {
    fetchEmbryoDetails(selectedCycleId);
    
    if (selectedDay) {
      setEmbryoCulture(prevState => ({
        ...prevState,
        day: selectedDay,
      }));
    }
    
    if (selectedDay) {
      setEmbryoDetails(prevState => ({
        ...prevState,
        day: selectedDay,
      }));
    }

    // setEmbryoCulture(initialEmbryoCultureValues);

  }, [fetchEmbryoDetails,selectedCycleId, selectedDay]);  
  
  
  // Wrap fetchCliniciansByRole in useCallback
  const fetchCliniciansByRole = useCallback((role: Role) => {
    showLoader();
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {
        setClinicianLists(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {         
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]); // Add dependencies here
  
  // Fetch clinicians for each role on component mount or when ClinicId changes
  useEffect(() => {
    ROLES.forEach(fetchCliniciansByRole);
  }, [fetchCliniciansByRole]); 
  

  const handleSelectionChange = (newSelectedEmbryos: number[], newDropdownValue: string) => {
    setSelectedEmbryos(newSelectedEmbryos);
    setselectedSource(newDropdownValue);
  };

  

  useEffect(()=>{
     
    console.log(selectedEmbryos);
     
    console.log(selectedSource);
  },[selectedEmbryos, selectedSource]);

  // Utility function to sanitize EmbryoCulture values
  const sanitizeEmbryoCulture = (values: EmbryoCultureType): EmbryoCultureType => {
    const sanitizedValues = { ...values };

    if (sanitizedValues.time && sanitizedValues.time.trim().length === 5) {
      sanitizedValues.time = `${sanitizedValues.time}:00`;
    }

    sanitizedValues.day = selectedDay; 
    sanitizedValues.cycle = selectedCycleId; 

    return sanitizedValues;
  };

  const sanitizeEmbryoDetails = (values: EmbryoUpdateDetailsType): EmbryoUpdateDetailsType => {
    const sanitizedValues = { ...values };
    sanitizedValues.cycle = selectedCycleId; 
    sanitizedValues.status = actionStatus; 
    
    sanitizedValues.cryo_preserved_date = freezeValues.cryo_preserved_date || sanitizedValues.cryo_preserved_date;
    sanitizedValues.cryo_preserved_by = freezeValues.cryo_preserved_by || sanitizedValues.cryo_preserved_by;
    sanitizedValues.cryo_purpose = freezeValues.cryo_purpose || sanitizedValues.cryo_purpose;
    sanitizedValues.cryo_media = freezeValues.cryo_media || sanitizedValues.cryo_media;
    sanitizedValues.is_screened = freezeValues.is_screened || sanitizedValues.is_screened;

    return sanitizedValues;
  };

  const handleSubmit = (
    values: { embryoCulture: EmbryoCultureType; embryoDetails: EmbryoUpdateDetailsType },
    { setSubmitting, setErrors, resetForm }: FormikHelpers<{ embryoCulture: EmbryoCultureType; embryoDetails: EmbryoUpdateDetailsType }>
  ) => {
    showLoader();
  
    const sanitizedEmbryoCulture = sanitizeEmbryoCulture(values.embryoCulture);
    const sanitizedEmbryoDetails = sanitizeEmbryoDetails(values.embryoDetails); 
  
    Promise.all([
      updateEmbryoDetails(values.embryoCulture.id ?? 0, sanitizedEmbryoCulture),
      updateEmbryoSummaryDetails(selectedCycleId, sanitizedEmbryoDetails),
    ])
      .then(([updatedEmbryoCulture, updatedEmbryoDetails]) => {
        console.log('EmbryoCulture updated successfully:', updatedEmbryoCulture);
        console.log('EmbryoSummary updated successfully:', updatedEmbryoDetails);
  
        addAlert('Embryo details saved successfully', 'success');
  
        // Reset form with sanitized values
        resetForm({
          values: {
            embryoCulture: sanitizedEmbryoCulture,
            embryoDetails: sanitizedEmbryoDetails,
          },
        });
  
        fetchEmbryoDetails(selectedCycleId ?? 0); 
      })
      .catch((error: unknown) => {
        // Handle errors
        if (error instanceof AxiosError) {
          if (error.response?.data?.type === 'validation_error') {
            const apiErrors = error.response.data.errors;
  
            const formikErrors: FormikErrors<{
              embryoCulture: EmbryoCultureType;
              embryoDetails: EmbryoUpdateDetailsType;
            }> = {
              embryoCulture: {} as FormikErrors<EmbryoCultureType>,
              embryoDetails: {} as FormikErrors<EmbryoUpdateDetailsType>,
            };
  
            apiErrors.forEach((err: { attr: string; detail: string }) => {
              if (err.attr.startsWith('embryoCulture')) {
                const fieldKey = err.attr.replace('embryoCulture.', '');
                formikErrors.embryoCulture![fieldKey as keyof EmbryoCultureType] = err.detail;
              } else if (err.attr.startsWith('embryoDetails')) {
                const fieldKey = err.attr.replace('embryoDetails.', '');
                formikErrors.embryoDetails![fieldKey as keyof EmbryoUpdateDetailsType] = err.detail;
              }
            });
  
            setErrors(formikErrors);
          } else {
            console.error('Unexpected error occurred:', error);
          }
        } else {
          console.error('Error occurred while saving embryo details:', error);
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  
  const [isDialogOpen, setDialogOpen] = useState(false);
  
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  
  const [isPGTResultDialogOpen, isPGTResultDialogOpenDialogOpen] = useState(false);
  
  const handlePGTResultDialogClose = () => {
    isPGTResultDialogOpenDialogOpen(false);
  };
  

  const handleDelete = (id: number | string) => {
    console.log(`Deleting row with ID: ${id}`);  // console will remove after demo
    setTableData((prevRows) => prevRows.filter((row) => row.id !== id));
    fetchEmbryoDetails(selectedCycleId); 
  };

  if (!isTableDeletable) {
    return (
      <div >
        <EmbryoCultureViewMode data={embryoCulture} tableData={tableData} isFinalized ={isTableDeletable} tablecolumns= {columns} day={selectedDay}/>
      </div>
    );
  };

  
  const handleFreezeDialogSave = (formValues: FreezeDialogValues) => {
    console.log('Received Values from Freeze Dialog:', formValues);
    setFreezeValues(formValues); 
    
    setFreezeDialogOpen(false);
  };
    

  return (    
    <ThemeProvider theme={theme}>
      {!embryosFound ? (
      // If no embryos are found, show 
        <GradingUnavailable  message="No embryos available for the selected day." />
      ) : (
        <Formik
          initialValues={{ embryoCulture, embryoDetails }} 
          validationSchema={CombinedValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            setFieldValue,
            submitForm,
            validateForm,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldTouched
          }) => {
            return (
              <Form>
                <Grid2 container spacing={2} marginTop={2}>
                  <Grid2 size={{xs:12, sm:3, md:3}}>
                    <TextField
                      name="date"
                      type="date"
                      label={RequiredLabel('Date', true)}
                      disabled
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      defaultValue={new Date().toISOString().split('T')[0]}       
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <TextField
                      name="embryoCulture.time"
                      type="time"
                      label="Time"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={getIn(values, 'embryoCulture.time') || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        !!getIn(touched, 'embryoCulture.time') &&
                      !!getIn(errors, 'embryoCulture.time')
                      }
                    />
                  </Grid2>

               
                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="embryologist"
                      options={clinicianLists['EMBRYOLOGIST'] || []}
                      getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                      value={clinicianLists['EMBRYOLOGIST'].find(
                        (doctor) => doctor.id === values.embryoCulture?.embryologist
                      ) || null}
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.embryologist', value?.id || ''); // Update Formik's value for 'embryoCulture.embryologist'
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Embryologist', true)} // Your label function
                          error={
                            !!getIn(touched, 'embryoCulture.embryologist') &&
                          !!getIn(errors, 'embryoCulture.embryologist')
                          }
                        />
                      )}
                    />
                  </Grid2>
                    
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="witness"
                      options={clinicianLists['EMBRYOLOGIST'] || []}
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['EMBRYOLOGIST'].find(
                        (doctor) => doctor.id === values.embryoCulture?.witness
                      ) || null}
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.witness', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Witness', true)}
                          error={
                            !!getIn(touched, 'embryoCulture.witness') &&
                          !!getIn(errors, 'embryoCulture.witness')
                          }
                          helperText={
                            getIn(touched, 'embryoCulture.witness') && getIn(errors, 'embryoCulture.witness')
                              ? getIn(errors, 'embryoCulture.witness') === 'Witness Embryologist name is required'
                                ? '': getIn(errors, 'embryoCulture.witness') : ''
                          }
                        />
                      )}
                    />
                  </Grid2>
      
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={incubatorItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        incubatorItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.incubator')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.incubator', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Incubator', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.incubator') &&
                          !!getIn(errors, 'embryoCulture.incubator')
                          }
                        />
                      )}
                    />
                  </Grid2>
      
                  <Grid2 size={{ xs: 12, sm: 3, md: 3 }}>
                    <TextField
                      name="remarks"
                      label={RequiredLabel('Remark', false)}
                      fullWidth
                      multiline
                      rows={1}
                      placeholder="Enter remark"
                      value={values.embryoCulture?.remarks || ''}
                      onChange={(e) => {
                        setFieldValue('embryoCulture.remarks', e.target.value || '');
                      }}
                      onBlur={handleBlur}
                      error={
                        !!getIn(touched, 'embryoCulture.remarks') &&
                      !!getIn(errors, 'embryoCulture.remarks')
                      }
                    />
                  </Grid2>
                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={cultureDishConfigItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        cultureDishConfigItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.culture_dish_config')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.culture_dish_config', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Culture Dish Configuration', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.culture_dish_config') &&
                          !!getIn(errors, 'embryoCulture.culture_dish_config')
                          }
                        />
                      )}
                    />
                  </Grid2>              
                </Grid2>   
            
                <div>
                  <OocyteSelector
                    title="Embryo No."
                    totalOocytes={allEmbryos}
                    onSelectionChange={handleSelectionChange}
                    hitSubmitbtn={hitSubmitbtn}
                  /> 
                </div>

                <Grid2 container spacing={2} marginTop={2}>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={stageItems}
                      getOptionLabel={(option) => option.label}
                      value={ stageItems.find((option) => option.value === getIn(values, 'embryoDetails.stage')) || null }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.stage', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Stage', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.stage') &&
                          !!getIn(errors, 'embryoDetails.stage')
                          }
                        />
                      )}
                    />
                  </Grid2>

                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={gradeItems}
                      getOptionLabel={(option) => option.label}
                      value={ gradeItems.find((option) => option.value === getIn(values, 'embryoDetails.grade')) || null }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.grade', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Grade', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.grade') &&
                          !!getIn(errors, 'embryoDetails.grade')
                          }
                        />
                      )}
                    />
                  </Grid2>    
                

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={pgtorderDetailsItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        pgtorderDetailsItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.pgt_order_details')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.pgt_order_details', value?.value || '');
                        if (value?.value === 'YES') {
                          setDialogOpen(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('PGT Order Details', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.pgt_order_details') &&
                                !!getIn(errors, 'embryoDetails.pgt_order_details')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth 
                    sx={{
                      '& .MuiDialog-paper': {
                        borderRadius: '20px',
                      },
                 
                    }}>
                    <DialogTitle>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px', fontWeight:600, fontFamily:'Montserrat', fontSize:'20px' }}> PGT Order Details</span>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          {selectedEmbryos.map((num) => (
                            <div
                              key={num}
                              style={{
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: '#47B35F',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '12px',
                                fontWeight: 400,
                              }}
                            >
                              {num}
                            </div>
                          ))}
                        </div>
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <Grid2 container spacing={2} marginTop={2}>
                        <Grid2 size={{xs:12, sm:12, md:6}}>
                          <TextField
                            name="embryoDetails.pgt_order_date"
                            label={RequiredLabel('Date', true)}
                            fullWidth
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={values.embryoDetails.pgt_order_date }  // Use the value from Formik state or default to today's date
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!getIn(touched, 'pgt_order_date') && !!getIn(errors, 'pgt_order_date')}  // Check for errors in the Formik state
                            helperText={getIn(touched, 'pgt_order_date') && getIn(errors, 'pgt_order_date')}
                          />
                        </Grid2>

                        <Grid2 size={{xs:12, sm:12, md:6}}>
                          <TextField
                            name="embryoDetails.pgt_order_time"
                            type="time"
                            label={RequiredLabel('Time', true)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={getIn(values, 'embryoDetails.pgt_order_time') || ''} 
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!getIn(touched, 'embryoDetails.pgt_order_time') &&
                            !!getIn(errors, 'embryoDetails.pgt_order_time')
                            }
                          />
                        </Grid2>

                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtOrderBiopsyCellsItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtOrderBiopsyCellsItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_order_biopsy_cells')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_order_biopsy_cells', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('# Cell Biopsy', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_order_biopsy_cells') &&
                                !!getIn(errors, 'embryoDetails.pgt_order_biopsy_cells')
                                }
                              />
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtTypeItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtTypeItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_type')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_type', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('PGT Type', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_type') &&
                                !!getIn(errors, 'embryoDetails.pgt_type')
                                }
                              />
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtFacilityItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtFacilityItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_facility')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_facility', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('Facility', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_facility') &&
                                !!getIn(errors, 'embryoDetails.pgt_facility')
                                }
                              />
                            )}
                          />
                        </Grid2>
                      </Grid2>
                    </DialogContent>
                    <DialogActions>
                      <button onClick={handleDialogClose} className={styles.resetButton}>
                Close
                      </button>
                      <button onClick={handleDialogClose} className={`${styles.customButton}`}>
                Save
                      </button>
                    </DialogActions>
                  </Dialog>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={pgtorderDetailsItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        pgtorderDetailsItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.pgt_result')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.pgt_result', value?.value || '');
                        if (value?.value === 'YES') {
                          isPGTResultDialogOpenDialogOpen(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('PGT Result', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.pgt_result') &&
                                !!getIn(errors, 'embryoDetails.pgt_result')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Dialog open={isPGTResultDialogOpen} onClose={handlePGTResultDialogClose} maxWidth="md" fullWidth 
                    sx={{
                      '& .MuiDialog-paper': {
                        borderRadius: '20px',
                      },
                 
                    }}>
                    <DialogTitle>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px', fontWeight:600, fontFamily:'Montserrat', fontSize:'20px' }}> PGT Result</span>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          {selectedEmbryos.map((num) => (
                            <div
                              key={num}
                              style={{
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: '#47B35F',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '12px',
                                fontWeight: 400,
                              }}
                            >
                              {num}
                            </div>
                          ))}
                        </div>
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <Grid2 container spacing={2} marginTop={2}>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtResultEmbryoSexItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtResultEmbryoSexItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_result_embryo_sex')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_result_embryo_sex', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('Embryo Sex', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_result_embryo_sex') &&
                                !!getIn(errors, 'embryoDetails.pgt_result_embryo_sex')
                                }
                              />
                            )}
                          />
                        </Grid2>

                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtResultsItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtResultsItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_results')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_results', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('Result', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_results') &&
                                !!getIn(errors, 'embryoDetails.pgt_results')
                                }
                              />
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtResultMoistureItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtResultMoistureItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_result_moisture')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_result_moisture', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('Mitosure', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_result_moisture') &&
                                !!getIn(errors, 'embryoDetails.pgt_result_moisture')
                                }
                              />
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <TextField
                            name="embryoDetails.pgt_result_notes"
                            label={RequiredLabel('Notes', false)}
                            fullWidth
                            multiline
                            rows={1}
                            value={values.embryoDetails?.pgt_result_notes || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!getIn(touched, 'embryoDetails.pgt_result_notes') &&
                            !!getIn(errors, 'embryoDetails.pgt_result_notes')
                            }
                          />
                        </Grid2>
                      </Grid2>
                    </DialogContent>
                    <DialogActions>
                      <button onClick={handlePGTResultDialogClose} className={styles.resetButton}>
                Close
                      </button>
                      <button onClick={handlePGTResultDialogClose} className={`${styles.customButton}`}>
                Save
                      </button>
                    </DialogActions>
                  </Dialog>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={actionsItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        actionsItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.action')
                        ) || null
                      }               
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.action', value?.value || '');
                        if (value?.value === 'FREEZE') {
                          setFreezeDialogOpen(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Action', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.action') &&
                          !!getIn(errors, 'embryoDetails.action')
                          }
                        />
                      )} 
                    />
                  </Grid2>
                  
                  <Grid2 size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                    <EmbryoFileUpload selectedEmbryos={selectedEmbryos} onFileUpload={setFile} imagePathUrl={imagePathUrl} cycle_id={selectedCycleId || null} day={selectedDay}/>
                  </Grid2>
      
                  
                  {/* Freeze Action Dialog */}
                  <FreezeActionDialog
                    open={isFreezeDialogOpen}
                    onClose={() => {
                      setFieldValue('embryoDetails.action', ''); 
                      setFreezeDialogOpen(false);  
                    }}
                    values={freezeValues}
                    onSave={handleFreezeDialogSave}                    
                    selectedEmbryos={selectedEmbryos} 
                  />
                  
                </Grid2>
      
                <div >
                  <p style={{fontFamily:'Montserrat', fontSize:'20px', fontWeight:700, padding:'1rem'}}>Details</p>
                  <DetailsTable columns={columns} rows={tableData} isDeletable={isTableDeletable}  onDelete={handleDelete}/>
                </div>
                <div>
                  <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex', justifyContent:'flex-start', marginTop:'1rem'}}>
                      <button
                        type="button"
                        className={styles.resetButton}
                        // onClick={() => setOpenResetConfirmDialog(true)} 
                      >
                      Cancel
                      </button>
                    </Box>
                    <Box className={`${styles.customButtonDiv}`}  >
                      <button
                        type="button"
                        className={styles.resetButton}
                        style={{width:'10rem'}}
                        onClick={async () => {
                          console.log('Current Form Values:');
                          console.log('Selected Oocytes:', selectedEmbryos);
                          // Ensure setFieldValue completes before proceeding
                          await setFieldValue('embryoDetails.embryos', selectedEmbryos);
                          setActionStatus('FINALIZED');
                          setHitSubmitbtn(true);
                          console.log('Embryo Details:', embryoDetails);
                          validateForm().then((formErrors) => {
                          // Mark fields with validation errors as touched
                            Object.entries(formErrors).forEach(([namespace, fields]) => {
                              if (fields && typeof fields === 'object') {
                                Object.keys(fields).forEach((field) => {
                                  setFieldTouched(`${namespace}.${field}`, true, true);
                                });
                              }
                            });
                            console.log('Validation errors:', formErrors);
                            if (Object.keys(formErrors).length === 0) {
                              setOpenConfirmDialog(true);
                            } else {
                              console.log('Validation errors:2222', formErrors);
                            }
                          });
                        }}
                      >
                      Save & Finalize
                      </button>
                      <button
                        type="button"
                        onClick={async () => {
                          console.log('Current Form Values:');
                          console.log('Selected Oocytes:', selectedEmbryos);
                          // Ensure setFieldValue completes before proceeding
                          await setFieldValue('embryoDetails.embryos', selectedEmbryos);
                          setActionStatus('DRAFT');
                          setHitSubmitbtn(true);
                          console.log('Embryo Details:', embryoDetails);
                          validateForm().then((formErrors) => {
                          // Mark fields with validation errors as touched
                            Object.entries(formErrors).forEach(([namespace, fields]) => {
                              if (fields && typeof fields === 'object') {
                                Object.keys(fields).forEach((field) => {
                                  setFieldTouched(`${namespace}.${field}`, true, true);
                                });
                              }
                            });
                            console.log('Validation errors:', formErrors);
                            if (Object.keys(formErrors).length === 0) {
                              setOpenConfirmDialog(true);
                            } else {
                              console.log('Validation errors:2222', formErrors);
                            }
                          });
                        }}
                        className={`${styles.customButton}`}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Save
                        </span>
                      </button>
                    </Box>
                  </Box>
                </div>
                  
                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    setOpenConfirmDialog(false);
                    submitForm(); 
                  }} 
                  type="save"
                  title="Embryo Culture"
                  contentMessage={
                    actionStatus === 'FINALIZED'
                      ? `Are you sure you want to finalize day${selectedDay} details?`
                      : `Are you sure you want to save day${selectedDay}  details?`
                  }
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </ThemeProvider>
  );
};

export default DayFour;
