import * as yup from 'yup'; 
import { getRequest, postRequest, putRequest } from '../../../services/httpService';

export type BlankEnum = '';
export type NullEnum = 'NULL';

// Interface for Oocyte Retrieval
export interface OocyteRetrievalType {
  cycle: number | null;
  id: number | null;

  cycle_start_date: string | null; 
  opu_date: string | null;
  start_time: string ; 
  end_time: string; 

  clinician: number | null;
  embryologist: number | null;
  witness_embryologist: number | null;
  anesthetist: number | null;

  type_of_anesthesia: string;
  no_of_needles_used: number;
  nurse: number | null;
  tube_id: string;

  type_of_needle: string;
  sub_type_of_needle: string;
  difficulty_level: string; 
  procedure_findings: string;

  optimum_follicles_left: number | null;
  optimum_follicles_right: number | null;
  oocytes_retrieved_left: number | null;
  oocytes_retrieved_right: number | null;

  notes: string;
  clinicians_notes: string;
  pre_anesthesia_checkup: boolean | null;
}

// Initial state for Oocyte Retrieval with default values
export const initialOocyteRetrieval: OocyteRetrievalType = {
  cycle: null,
  id: null,

  cycle_start_date: null,
  opu_date: '',
  start_time: '',
  end_time: '',

  clinician: null,
  embryologist: null,
  witness_embryologist: null,
  anesthetist: null,

  type_of_anesthesia: '',
  no_of_needles_used: 0,
  nurse: null,
  tube_id: '',

  type_of_needle: '',
  sub_type_of_needle: '',
  difficulty_level: '',
  procedure_findings: '',

  optimum_follicles_left: null,
  optimum_follicles_right: null,
  oocytes_retrieved_left: null,
  oocytes_retrieved_right: null,

  notes: '',
  clinicians_notes: '',
  pre_anesthesia_checkup: false,
};

// Validation schema for Oocyte Retrieval form using Yup
export const OocyteRetrievalValidationSchema = yup.object({
  cycle: yup
    .number()
    .required('Cycle Number is required'),

  cycle_start_date: yup
    .string()
    .nullable()
    .required('Cycle Start Date is required'),

  opu_date: yup
    .string()
    .nullable()
    .required('OPU Date is required'),

  start_time: yup
    .string()
    .nullable()
    .required('Start Time is required'),


  end_time: yup
    .string()
    .required('End Time is required')
    .test(
      'is-after-start-time',
      'End Time should be after Start Time',
      function (value) {
        const { start_time } = this.parent;
        if (!start_time || !value) return true; 
        return value > start_time;
      }
    ),

  clinician: yup
    .number()
    .required('Clinician name is required'),

  embryologist: yup
    .number()
    .required('Embryologist name is required'),
  
  witness_embryologist: yup
    .number()
    .required('Witness Embryologist name is required')
    .test('not-same', 'The witness embryologist cannot be the same as the embryologist.', function (value) {
      const { embryologist } = this.parent; // Accessing the sibling field
      return value !== embryologist; // Validate that they are not the same
    }),

  anesthetist: yup
    .number()
    .required('Anesthetist name is required'),

  type_of_anesthesia: yup
    .string()
    .nullable()
    .required('Anesthetist name is required'),

  no_of_needles_used: yup
    .number()
    .nullable()
    .required('Number of needles is required'),

  nurse: yup
    .number()
    .nullable(),

  tube_id: yup
    .string()
    .nullable(),

  type_of_needle: yup
    .string()
    .nullable(),

  sub_type_of_needle: yup
    .string()
    .nullable(),

  difficulty_level: yup
    .string()
    .nullable(),

  procedure_findings: yup
    .string()
    .nullable()
    .max(2000, 'Procedure findings cannot exceed 2000 characters'),

  optimum_follicles_left: yup
    .number()
    .nullable(),

  optimum_follicles_right: yup
    .number()
    .nullable(),

  oocytes_retrieved_left: yup
    .number()
    .nullable(),

  oocytes_retrieved_right: yup
    .number()
    .nullable(),

  notes: yup
    .string()
    .nullable()  
    .max(2000, 'Notes cannot exceed 2000 characters'),

  clinicians_notes: yup
    .string()
    .nullable(),

  pre_anesthesia_checkup: yup
    .boolean()
    .nullable(),
});


// Function to generate the URL for adding oocyte retrieval
export function generateOocyteRetrievalURL(id: number): string {
  return `opu/cycle/${id}`;
}
 
// Function to add oocyte retrieval
export function addOocyteRetrieval(data: OocyteRetrievalType): Promise<OocyteRetrievalType> {
  const url = 'opu/';
  return postRequest(url, data, { responseType: 'json' });
}

  
// Function to get oocyte retrieval
export function getOocyteRetrieval(id: number): Promise<OocyteRetrievalType> {
  return getRequest(generateOocyteRetrievalURL(id), { responseType: 'json' });
}
  
// Function to update oocyte retrieval
export function updateOocyteRetrieval(id: number, data: OocyteRetrievalType): Promise<OocyteRetrievalType> {
  const url = `opu/${id}`;

  return putRequest(url, data, { responseType: 'json' });
}
  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getARTCycleList(id: number): Promise<any> {
  return getRequest(`cycle/patient/${id}`, { responseType: 'json' });
}

export interface Procedure {
  id: number;
  services: number[];
  procedures: string;
  procedures_type: string;
  procedure_notes: string | null; 
  created_on: string;
  donor_info: string;
  indication: string;
}

export interface CycleData {
  id: number;
  procedure: Procedure;
  cycle_start_date:string;
  medication_protocol: number;
  lmp_date: string; 
  status: string;
  cycle_number: string;
  oocyte_details?:number | null;
  semen_processing:number;
  semen_freezing?: number;
  iui?: number;
}

export interface CycleItem {
  id:number;
  value: string; 
  label: string; 
  status: string; 
  procedure?: string | undefined; 
}

// Define a constant for roles to avoid magic strings
export const ROLES = ['DOCTOR', 'ANESTHETIST', 'EMBRYOLOGIST', 'NURSE'] as const;

export interface Clinician {
  id: number; 
  first_name: string;
  last_name: string;
  // Add other relevant fields as per your API response
}

// Create a type that represents the role strings
export type Role = typeof ROLES[number]; // 'DOCTOR' | 'ANESTHETIST' | 'EMBRYOLOGIST' | 'NURSE'

interface OptimumFollicles {
  left_follicles: number;
  right_follicles: number;
}

export interface OocyteRetrievalData {
  id?: number;
  cycle?: number;
  opu_date?: string; // Format: YYYY-MM-DD
  start_time: string; // Format: HH:mm:ss
  end_time: string; // Format: HH:mm:ss
  clinician: number;
  embryologist: number;
  witness_embryologist: number;
  anesthetist: number;
  nurse: number;
  type_of_anesthesia: string; // Enum or specific type (e.g., "REGIONAL_ANESTHESIA")
  type_of_needle: string; // Enum or specific type (e.g., "COOK")
  sub_type_of_needle: string; // Enum or specific type (e.g., "SINGLE_LUMEN")
  difficulty_level: string; // Enum or specific type (e.g., "MODERATE")
  procedure_findings: string;
  notes: string;
  optimum_follicles: OptimumFollicles;
  oocytes_retrieved_left: number;
  oocytes_retrieved_right: number;
  tube_id: string;
  no_of_needles_used: number;
  pre_anesthesia_checkup: boolean;
  nurse_name: string;
  anesthetist_name: string;
  witness_embryologist_name: string;
  embryologist_name: string;
  clinician_name: string;
  cycle_start_date: string; // Format: YYYY-MM-DD
}


// Define default values for OocyteRetrievalData
export const defaultRetrievalData: OocyteRetrievalData = {
  // id: 0,
  // cycle: 0,
  opu_date: '',
  start_time: '',
  end_time: '',
  clinician: 0,
  embryologist: 0,
  witness_embryologist: 0,
  anesthetist: 0,
  nurse: 0,
  type_of_anesthesia: '',
  type_of_needle: '',
  sub_type_of_needle: '',
  difficulty_level: '',
  procedure_findings: '',
  notes: '',
  optimum_follicles: {
    left_follicles: 0,
    right_follicles: 0,
  },
  oocytes_retrieved_left: 0,
  oocytes_retrieved_right: 0,
  tube_id: '',
  no_of_needles_used: 0,
  pre_anesthesia_checkup: false,
  nurse_name: '',
  anesthetist_name: '',
  witness_embryologist_name: '',
  embryologist_name: '',
  clinician_name: '',
  cycle_start_date: '',
};

export interface CycleDetailsType {
  id: number;
  cycle_number: string;
  cycle_name: string;
  medication_protocol: number;
  cycle_provider: number;
  provider_name: string;
  cycle_coordinator: number;
  coordinator_name: string;
  cycle_creator: number;
  cycle_start_date: string;
  cycle_end_date: string;
  lmp_date: string;
  cycle_creator_name: string;
  cycle_warning: string;
  remarks: string | null;
  status: string;
  patient: number;
  patient_name: string;
  stimulation_chart: number | null; 
  optimum_follicles: OptimumFollicles | null;
}


export function getcycleListUrl(Id: string): string {
  return `/cycle/${Id}/`; 
}


export function getcycleList(Id: string): Promise<CycleDetailsType> {
  return getRequest(getcycleListUrl(Id), { responseType: 'json' });
}
