/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Modals.module.css';
import { Drug, getDrugs, getHormones, Hormone, OptionType } from './helper.stim';
import { PaginatedResponse } from '../../types';

interface CreateHormoneModalProps {
  hormoneModalOpen: boolean;
  handleHormoneModalClose: () => void;
  handleSubmitClose: (id: string, name: string) => void;
}

const CreateHormoneModal: React.FC<CreateHormoneModalProps> = ({
  hormoneModalOpen,
  handleHormoneModalClose,
  handleSubmitClose,
}) => {
  const [meds, setMeds] = useState<OptionType[]>([]);
  const [selectedHormone, setSelectedHormone] = useState<OptionType | null>(null);

  useEffect(() => {
    getHormones()
      .then((hormones: Hormone[]) => {
        const drugOptions: OptionType[] = hormones.map((hormone: Hormone) => ({
          label: hormone.name,
          value: hormone.id.toString(),
        }));
        setMeds(drugOptions);
      });
  }, []);

  // Formik validation schema
  const validationSchema = Yup.object({
    hormoneName: Yup.string().required('Hormone Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      hormoneName: '',
    },
    validationSchema,
    onSubmit: () => {
      if (selectedHormone) {
        handleSubmitClose(selectedHormone.value, selectedHormone.label);
        handleHormoneModalClose(); // Close the modal after submission
      }
    },
  });

  return (
    <Modal open={hormoneModalOpen} onClose={handleHormoneModalClose}>
      <Box className={styles.modal}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
            Add Hormone
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} className={styles.modalBody}>
          <Autocomplete
            options={meds}
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => {
              setSelectedHormone(value);
              formik.setFieldValue('hormoneName', value?.label || '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Hormone Name *"
                name="hormoneName"
                error={formik.touched.hormoneName && Boolean(formik.errors.hormoneName)}
                helperText={formik.touched.hormoneName && formik.errors.hormoneName}
                margin="normal"
              />
            )}
          />
          <Box className={styles.modalActions} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              onClick={handleHormoneModalClose}
              className={styles.modalCancelBtn}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              className={styles.modalSaveBtn}
              disabled={!selectedHormone} // Disable button if no drug is selected
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateHormoneModal;