import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, StepIconProps, StepConnector } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { grey } from '@mui/material/colors';
import styles from './CycleOverViewList.module.css';
import Stimulation from './Stimulation/Stimulation';
import OocyteRetrieval from './OocyteRetrieval/OocyteRetrieval';
import SemenProcessing from './SemenProcessing/SemenProcessing';
import Fertilization from './Fertilization/Fertilization';
import EmbryoCulture from './EmbryoCulture/EmbryoCulture';
import EmbryoTransferOutcome from './EmbryoTransferOutcome/EmbryoTransferOutcome';
import Grid from '@mui/material/Grid';
import { CycleDetailsType, getcycleList } from './CycleDetailsHelper';
import { Avatar, Tooltip } from '@mui/material'; 
import AvatarGroup from '@mui/material/AvatarGroup';
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  marginTop: theme.spacing(1),
  borderTop: '2px solid #e0e0e0',
  '& .MuiStepConnector-line': {
    transition: 'all 0.3s ease',
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderColor: '#0C7DED',
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderColor: '#4caf50',
  },
}));

function CustomStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  return (
    <div
      style={{
        backgroundColor: completed ? '#4caf50' : active ? '#4caf50' : grey[300],
        color: '#fff',
        borderRadius: '50%',
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.3s ease',
      }}
    >
      {completed ? <CheckCircleIcon /> : <span>{props.icon}</span>}
    </div>
  );
}

const AnimatedStepper = ({ activeStep, setActiveStepCallback }: { activeStep: number; setActiveStepCallback: (step: number) => void }) => {
  const steps = [
    'Stimulation ',
    'Oocyte Retrieval ',
    'Semen Processing ',
    'Fertilization ',
    'Embryo Culture ',
    'Embryo Transfer & Outcome ',
  ];

  const handleStepClick = (index: number) => {
    setActiveStepCallback(index);
  };

  return (
    <div style={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomConnector />}
        sx={{ padding: '0 2rem' }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={true}>
            <StepLabel StepIconComponent={CustomStepIcon} onClick={() => handleStepClick(index)}>
              <span
                className={`${styles.stepstext} ${activeStep === index ? styles.activeStepText : ''}`}
              >
                {label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};



const CycleDetails: React.FC<{ handleCycleListClick: () => void; cycle: string }> = ({ handleCycleListClick, cycle }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [cycleDetails, setCycleDetails] = useState<CycleDetailsType | null>(null);

  useEffect(() => {
    if (cycle) {
      getcycleList(cycle)
        .then((data) => {
          setCycleDetails(data); 
  
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching cycle details:', error);
        });
    }
  }, [cycle]);

  
  
  const setActiveStepCallback = (step: number) => {
    setActiveStep(step);
  };

  const getStepLabel = (step: number) => {
    const stepLabels = [
      'Stimulation',
      'Oocyte Retrieval',
      'Semen Processing',
      'Fertilization',
      'Embryo Culture',
      'Embryo Transfer & Outcome',
    ];

    return stepLabels[step] || 'Unknown Step';
  };

  return (
    <div style={{ width: '100%', padding: '2rem' }}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={2} md={2} className={styles.CycleDetails}>
          Cycle Details:
        </Grid>

        <Grid item xs={12} sm={10} md={10}>

          <Grid container spacing={3} alignItems="center">

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Number</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_number || '-'}</p>
              </div>
            </Grid>


            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Name</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_name || '-'}</p>
              </div>
            </Grid>


            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Indication</strong>
                <p className={styles.stepstext}> {cycleDetails?.procedure?.indication || '-'}</p>
              </div>
            </Grid>


            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Oocyte Source</strong>
                {cycleDetails?.procedure?.donor_info?.oocyte_source?.length ? (
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    <AvatarGroup max={4}>
                      {cycleDetails.procedure.donor_info.oocyte_source.map(
                        (source: { first_name: string; last_name: string; medical_record_no: string; photo_url: string }, index: number) => (
                          <Tooltip
                            key={index}
                            title={
                              `${source.first_name || ''} ${source.last_name || 'Self'} , MRN : ${source.medical_record_no || '-'}`
                            }
                            arrow
                          >
                            <Avatar alt={`${source.first_name} ${source.last_name}`} src={source.photo_url || undefined}>
                              {!source.photo_url && 
    `${source.first_name?.charAt(0) || ''}${source.last_name?.charAt(0) || ''}`}
                            </Avatar>
                          </Tooltip>
                        )
                      )}
                    </AvatarGroup>
                  </div>
                ) : (
                  <p className={styles.stepstext}>-</p>
                )}
              </div>
            </Grid>



            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Sperm Source</strong>
                {cycleDetails?.procedure?.donor_info?.sperm_source?.length ? (
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    <AvatarGroup max={4}>
                      {cycleDetails.procedure.donor_info.sperm_source.map(
                        (source: { first_name: string; last_name: string; medical_record_no: string; photo_url: string }, index: number) => (
                          <Tooltip
                            key={index}
                            title={
                              `${source.first_name || ''} ${source.last_name || 'Self'} , MRN : ${source.medical_record_no || '-'}`
                            }
                            arrow
                          >
                            <Avatar alt={`${source.first_name} ${source.last_name}`} src={source.photo_url || undefined}>
                              {!source.photo_url && 
    `${source.first_name?.charAt(0) || ''}${source.last_name?.charAt(0) || ''}`}
                            </Avatar>
                          </Tooltip>
                        )
                      )}
                    </AvatarGroup>
                  </div>
                ) : (
                  <p className={styles.stepstext}>-</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Recipient</strong>
                {cycleDetails?.procedure?.donor_info?.recipient ? (
                  <Tooltip
                    title={
                      `${cycleDetails.procedure.donor_info.recipient.first_name || ''} ${cycleDetails.procedure.donor_info.recipient.last_name || 'Self'} , MRN : ${cycleDetails.procedure.donor_info.recipient.medical_record_no || '-'}`
                    }
                    arrow
                  >
                    <Avatar
                      alt={`${cycleDetails.procedure.donor_info.recipient.first_name} ${cycleDetails.procedure.donor_info.recipient.last_name}`}
                      src={cycleDetails.procedure.donor_info.recipient.photo_url || undefined}
                    >
                      {!cycleDetails.procedure.donor_info.recipient.photo_url &&
            `${cycleDetails.procedure.donor_info.recipient.first_name?.charAt(0) || ''}${cycleDetails.procedure.donor_info.recipient.last_name?.charAt(0) || ''}`}
                    </Avatar>
                  </Tooltip>
                ) : (
                  <p className={styles.stepstext}>-</p>
                )}
              </div>
            </Grid>


            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Medication Protocol</strong>
                <p className={styles.stepstext}>{cycleDetails?.medication_protocol || '-'}</p>
              </div>
            </Grid>




            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Creator</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_creator_name || '-'}</p>
              </div>
            </Grid>


            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Co-ordinator</strong>
                <p className={styles.stepstext}>{cycleDetails?.coordinator_name || '-'}</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Provider</strong>
                <p className={styles.stepstext}>{cycleDetails?.provider_name || '-'}</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle Start Date</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_start_date || '-'}</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Cycle End Datee</strong>
                <p className={styles.stepstext}>{cycleDetails?.cycle_end_date || '-'}</p>
              </div>
            </Grid>

  

    

            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>LMP Date</strong>
                <p className={styles.stepstext}>{cycleDetails?.lmp_date || '-'}</p>
              </div>
            </Grid>


            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerCycleDetails}>Remark</strong>
                <p className={styles.stepstext}>{cycleDetails?.remarks || '-'}</p>
              </div>
            </Grid>


            <Grid item xs={12} sm={6} md={3}>
              <div className={styles.textField}>
                <strong className={styles.cycleList} onClick={handleCycleListClick}>
                    Cycle List
                </strong>
              </div>
            </Grid>
          </Grid>
   
        </Grid>
      </Grid>

      <div className={`${styles.boxstepper} mt-4`}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AnimatedStepper activeStep={activeStep} setActiveStepCallback={setActiveStepCallback} />
          </Grid>
        </Grid>
      </div>

      <div className="mt-4">
        <span className={styles.CycleDetailsStep}>
          {getStepLabel(activeStep)} <span className={styles.CycleDetailsStepView}>View</span>
        </span>
      </div>

      <div className="mt-4">
        {
          {
            'Stimulation': cycleDetails?.stimulation_chart ? <Stimulation stimulationChart={cycleDetails.stimulation_chart} /> : <Stimulation/>,
            'Oocyte Retrieval': <OocyteRetrieval cycle={cycle}/>,
            'Semen Processing': <SemenProcessing />,
            'Fertilization': <Fertilization />,
            'Embryo Culture': <EmbryoCulture />,
            'Embryo Transfer & Outcome': <EmbryoTransferOutcome />
          }[getStepLabel(activeStep)] || null
        }
      </div>
    </div>
  );
};

export default CycleDetails;
