/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import styles from './SemenSampleComponent.module.css';
import { ReactComponent as More } from '../../../assets/SVG/close-circle-greay.svg';
import { getProcessedSemenSample, ProcessedSemenSample } from './SemenSampleComponentHelper';
import { useLoader } from '../../../components/loaderProvider/loader';
import { ConfirmationModal } from '../../../components/confirmationModal';
import { usePatientContext } from '../../../components/SubPatinetProvider';

interface SemenSamplerProps {
  dialogTitle: string;
  selectedSpermId: number | null; 
  onClose: (data?: { spermId: number | null }) => void;
}

const SemenSampleComponent: React.FC<SemenSamplerProps> = ({ dialogTitle, onClose ,selectedSpermId}) => {
  const { showLoader, hideLoader } = useLoader();
  const [semenSamples, setSemenSamples] = useState<ProcessedSemenSample[]>([]);
  const [filteredSamples, setFilteredSamples] = useState<ProcessedSemenSample[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [cancelDialog, setCancelDialog] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSample, setSelectedSample] = useState<number | null>(selectedSpermId); 
  const { patientId } = usePatientContext();
  useEffect(() => {
    showLoader();
    getProcessedSemenSample(patientId, '?disposition_type=INSEMINATION')
      .then((data) => {
        setSemenSamples(data);
        setFilteredSamples(data);
        hideLoader();
      })
      .catch((error) => {
        console.error('Error fetching processed semen sample data:', error);
        hideLoader();
      });
  }, [hideLoader, showLoader,patientId]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query === '') {
      setFilteredSamples(semenSamples); 
    } else {
      const filteredData = semenSamples.filter((sample) => {
        const searchQueryLower = query.toLowerCase();
        return (
          (sample.sample_number &&
            sample.sample_number.toString().toLowerCase().includes(searchQueryLower)) ||
          (sample.cycle &&
            sample.cycle.toString().toLowerCase().includes(searchQueryLower)) ||
          (sample.cryo_number &&
            sample.cryo_number.toString().toLowerCase().includes(searchQueryLower))
        );
      });
      setFilteredSamples(filteredData);
    }
  };

  const handleRadioChange = (id: number) => {
    setSelectedSample(id);
  };

  const handleClearAll = () => {
    setCancelDialog(true);
  };

  const handleConfirmClearAll = () => {
    setSelectedSample(null); 
    onClose({ spermId: null }); 
    setCancelDialog(false); 
  };

  const handleCancelClearAll = () => {
    setCancelDialog(false);
    onClose({ spermId: null }); 
  };

  const handleSubmit = () => {
    onClose({ spermId: selectedSample });
  };

  return (
    <div className={`${styles.container} mt-4 p-0`}>
      <div className="px-4">
        <div className="d-flex justify-content-between">
          <span className={styles.titleText}>{dialogTitle}</span>
          <More
            className={styles.floatEnd}
            onClick={() => onClose({ spermId: selectedSample })}
          />
        </div>
        <div>
          <p className={styles.textmuted}>Select a Sperm Source</p>
        </div>
      </div>

      <hr />

      <div className="px-4">
        <div className="row gx-0">
          <div className="row gx-0">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              className={styles.inputField}
              placeholder="Search by Semen ID/Cycle No."
            />
          </div>
        </div>

        <div className="row gx-0 mt-4">
          <div className={styles.tableContainer}>
            <table className={styles.customTable}>
              <thead>
                <tr>
                  <th></th>
                  <th>Source</th>
                  <th>Semen ID</th>
                  <th>Cycle no.</th>
                  <th>Type of Sperm</th>
                  <th>Volume</th>
                  <th>Sperm Concentration</th>
                  <th>Total Sperm Count</th>
                </tr>
              </thead>
              <tbody>
                {filteredSamples.map((sample) => (
                  <tr key={sample.id}>
                    <td>
                      <input
                        type="radio"
                        name="semenSample"
                        value={sample.id}
                        checked={selectedSample === sample.id}
                        onChange={() => handleRadioChange(sample.id)}
                      />
                    </td>
                    <td> {sample.source_details 
                      ? sample.source_details === 'self' 
                        ? 'partner' 
                        : sample.source_details
                      : '-'}</td>
                    <td>{sample.sample_number || '-'}</td>
                    <td>{sample.cycle}</td>
                    <td>{sample.sperm_type || '-'}</td>
                    <td>{sample.post_volume_ml || '-'}</td>
                    <td>{sample.post_sperm_concentration ||'-'}</td>
                    <td>{sample.post_total_sperm_count ||'-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row gx-0 mt-4">
            <div className={`${styles.buttonDiv} col-md-12 p-0 mt-5`}>
              <button
                className={`${styles.cancel} ${styles.small}`}
                onClick={handleClearAll}
              >
                Clear All
              </button>
              <button
                className={`${styles.save}`}
                style={{
                  marginBottom: '20px',
                }}
                onClick={() => setOpenModal(true)}
              >
                Link
              </button>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        open={cancelDialog}
        handleClose={handleCancelClearAll}
        onConfirm={handleConfirmClearAll}
        title="Clear Selection"
        contentMessage="Are you sure you want to clear selected semen sample?"
        type="warning"
      />

      <ConfirmationModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        onConfirm={handleSubmit}
        title="Link"
        contentMessage="Are you sure you want to link semen sample?"
        type="warning"
      />
    </div>
  );
};

export default SemenSampleComponent;
