/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Modals.module.css';
import {
  createDrug,
  deleteMed,
  Drug,
  DrugPostRequest,
  DrugSchema,
  getDrug,
  getDrugDetails,
} from './helper.stim';
import {
  convertToLocalDateTime,
  convertToUTCFormat,
  convertUTCToLocalTime,
  formatDateToLocalYYYYMMDD,
} from '../../services/time';
import { useAlert } from '../../components/alertProvider';
import { AxiosError } from 'axios';
import { showConfirmationModal } from '../../components/ConfirmationModel';

interface AddDrugModalProps {
  drugModalOpen: boolean;
  handleDrugModalClose: () => void;
  stim: number;
  date: Date;
  id?: string;
  type?: string;
  medId?: number;
}

const AddDrugModal: React.FC<AddDrugModalProps> = ({
  drugModalOpen,
  handleDrugModalClose,
  stim,
  date,
  id,
  type,
  medId,
}) => {
  const [med, setMed] = useState<Drug | null>(null);
  const [selectedDrug, setSelectedDrug] = useState<DrugSchema | null>(null);
  const { addAlert } = useAlert();

  // Fetch drug details by medId
  useEffect(() => {
    if (medId) {
      getDrugDetails(medId)
        .then((drug: DrugSchema) => {
          console.log('Fetched selectedDrug:', drug);
          setSelectedDrug(drug);
        })
        .catch((error) => console.error('Error fetching drug details:', error));
    }
  }, [medId]);

  // Fetch additional drug info by id
  useEffect(() => {
    if (id) {
      getDrug(id)
        .then((drug: Drug) => {
          console.log('Fetched med:', drug);
          setMed(drug);
        })
        .catch((error) => console.error('Error fetching drug:', error));
    }
  }, [id]);

  // Validation schema
  const validationSchema = Yup.object({
    dosage: Yup.string().required('Dosage is required'),
    time: Yup.string().required('Time is required'),
    days: Yup.number()
      .required('Number of days is required')
      .positive('Days must be a positive number')
      .integer('Days must be an integer'),
  });

  // Compute the number of days dynamically
  const calculateDays = (): string => {
    if (selectedDrug?.start_date && selectedDrug?.end_date) {
      const start = convertToLocalDateTime(selectedDrug.start_date);
      const end = convertToLocalDateTime(selectedDrug.end_date);
      return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1).toString();
    }
    return '';
  };

  const handleDelete = () => {
    if (selectedDrug && selectedDrug.id) {
      showConfirmationModal({
        message: 'Are you sure you want to delete this medication?',
        onConfirm: () => {
          deleteMed(selectedDrug?.id).finally(() =>  handleDrugModalClose());
        },
        onCancel: () => {
          handleDrugModalClose();
        },
      });
    }
  };

  // Initialize Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dosage: selectedDrug?.quantity?.toString() || '',
      days: calculateDays(),
      time: selectedDrug?.start_time || '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (id) {
        const medType = type === 'trigger' ? 'TRIGGER' : 'STIMULATION';
        const body: DrugPostRequest = {
          drug: parseInt(id),
          drug_type: medType,
          start_date: selectedDrug?.start_date? formatDateToLocalYYYYMMDD(new Date(selectedDrug?.start_date)): formatDateToLocalYYYYMMDD(date),
          quantity: parseInt(values.dosage),
          start_time: convertToUTCFormat(values.time),
          number_of_days: parseInt(values.days),
          stim_chart: stim,
        };

        console.log('Submitting body:', body);

        createDrug(body, medId).catch((error: AxiosError) =>
          addAlert(error.cause?.message.toString() || 'Failed to create dose', 'error')
        ).finally(() => handleDrugModalClose());
      
      }
      // Close modal after submission
    },
  });

  // Update formik values whenever selectedDrug changes
  useEffect(() => {
    if (selectedDrug) {
      console.log('Updating formik values with selectedDrug:', selectedDrug);
      formik.setValues({
        dosage: selectedDrug.quantity?.toString() || '',
        days: calculateDays(),
        time: selectedDrug.start_time || '',
      });
    }
    /* eslint-disable-next-line */
  }, [selectedDrug]);

  return (
    <Modal open={drugModalOpen} onClose={handleDrugModalClose}>
      <Box className={styles.modal}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
            Add Stim Doses
          </Typography>
          {selectedDrug &&  <Button
            variant="outlined"
            color='error'
            onClick={handleDelete}
            className={styles.modalCancelBtn}
          >
              Delete
          </Button>}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            rowGap: '1rem',
            columnGap: '1.5rem',
            flexWrap: 'wrap',
            padding: '1rem',
          }}
        >
          <div className={styles.info}>
            <label>Start Date</label>
            <span>
              {selectedDrug?.start_date
                ? formatDateToLocalYYYYMMDD(new Date(selectedDrug.start_date))
                : formatDateToLocalYYYYMMDD(date)}
            </span>
          </div>
          {med?.name && (
            <div className={styles.info}>
              <label>Name</label>
              <span>{med.name}</span>
            </div>
          )}
          {med?.brand_name && (
            <div className={styles.info}>
              <label>Brand</label>
              <span>{med.brand_name}</span>
            </div>
          )}
          {med?.strength && (
            <div className={styles.info}>
              <label>Strength</label>
              <span>{med.strength}</span>
            </div>
          )}
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Dose *"
                name="dosage"
                type="text"
                value={formik.values.dosage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                error={formik.touched.dosage && Boolean(formik.errors.dosage)}
                helperText={formik.touched.dosage && formik.errors.dosage}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Drug Time *"
                name="time"
                type="time"
                value={selectedDrug?.start_time ? convertUTCToLocalTime(selectedDrug.start_time) : formik.values.time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                error={formik.touched.time && Boolean(formik.errors.time)}
                helperText={formik.touched.time && formik.errors.time}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="# Days *"
                name="days"
                type="number"
                value={formik.values.days}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                error={formik.touched.days && Boolean(formik.errors.days)}
                helperText={formik.touched.days && formik.errors.days}
              />
            </Grid>
          </Grid>
          <Box
            className={styles.primary}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              fullWidth
              variant="outlined"
              onClick={handleDrugModalClose}
              className={styles.modalCancelBtn}
            >
              Cancel
            </Button>
            <Button  fullWidth type="submit" variant="contained" className={styles.modalSaveBtn}>
              {medId ? 'Update': 'Submit'}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddDrugModal;