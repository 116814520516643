import React, { useEffect, useState } from 'react';
import { ThemeProvider, Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { defaultRetrievalData, getOocyteRetrieval } from './OocyteRetrival.helper';
import { theme } from '../../Medical_History_Forms/themeprovider';
import { useLoader } from '../../loaderProvider/loader';
import { usePatientContext } from '../../SubPatinetProvider';
import Grid from '@mui/material/Grid2';
import { infoTextStyle, sectionTitleStyle } from '../../../utils/commonUtils';
import { anesthesiaItems, difficultyLevelItems, subtypeOfNeedleItems, typeOfNeedleItems } from './oocyteRetrieval.enums';


interface OocyteRetrievalDetailsProps {
  cycleId: number | null; // Ensure this aligns with your data type
}


const OocyteRetrievalDetails: React.FC<OocyteRetrievalDetailsProps> = ({ cycleId }) => {
  const { showLoader, hideLoader } = useLoader();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { patientId } = usePatientContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [retrievalData, setRetrievalData] = useState<any>(null);

  useEffect(() => {
    if (!cycleId) return;

    showLoader();
    getOocyteRetrieval(cycleId)
      .then((data) => setRetrievalData(data))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching Oocyte Retrieval data');
        setRetrievalData(defaultRetrievalData); // Set to default on error
      })
      .finally(() => hideLoader());
  }, [cycleId, showLoader, hideLoader]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 3 }}>

        <Grid container spacing={4} mb={3}>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Cycle Start Date</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.cycle_start_date}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>OPU Date</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.opu_date}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Start Time</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.start_time}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>End Time</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.end_time}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Embryologist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.embryologist_name  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Witness Embryologist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.witness_embryologist_name  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Anesthetist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.anesthetist_name || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Type of Anesthesia</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                anesthesiaItems.find(item => item.value === retrievalData?.type_of_anesthesia)?.label || '-'
              }
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Number of Needles Used</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.no_of_needles_used || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Nurse</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.nurse_name || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Tube ID</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.tube_id || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Type of Needle</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                typeOfNeedleItems.find(item => item.value === retrievalData?.type_of_needle)?.label || '-'
              }
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Subtype of Needle</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                subtypeOfNeedleItems.find(item => item.value === retrievalData?.sub_type_of_needle)?.label || '-'
              }
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Level of Difficulty</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                difficultyLevelItems.find(item => item.value === retrievalData?.difficulty_level)?.label || '-'
              }
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Procedure Findings</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.procedure_findings  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            {/* Pre Anesthesia Checkup */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={retrievalData?.pre_anesthesia_checkup === true}
                  icon={<span style={{ color: 'red', fontSize: '1rem' }}>✖</span>} // Custom cross icon for unchecked state
                  checkedIcon={<span style={{ color: 'green', fontSize: '1rem' }}>✔</span>} // Check mark for checked state
                />
              }
              label="Pre-Anesthesia Check-up"
            />
          </Grid>
          <Grid size={4} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Notes</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.notes || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default OocyteRetrievalDetails;
