import React, { useState } from 'react';
import styles from './ReusableCustombtn.module.css';
import { ReactComponent as Filter } from '../../assets/SVG/filter.svg';
import { Dialog, TextField, Grid, createTheme, ThemeProvider } from '@mui/material';
import FilterPopup from '../RegistrationComponent/FilterComponent/index';
import { ButtonType, ReusableCustombtnProps } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../alertProvider';


const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small', 
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small', 
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#E17E61',  
          },
        },
      },
    },
  },
});

export default function ReusableCustombtn({
  type,
  buttonName,
  selectedButton,
  radioOptions,
  onRadioChange,
  selectedValue,
  formDate,
  toDate,
  onDateChange,
  inputLabel,
  inputPlaceholder,
  dateFromLabel,
  dateToLabel,
  onInputChange,
}: ReusableCustombtnProps) {
  const navigate = useNavigate();
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const today = new Date().toISOString().split('T')[0];
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const defaultStartDate = oneMonthAgo.toISOString().split('T')[0];
  const defaultEndDate = today;

  const [startDate, setStartDate] = useState(formDate || defaultStartDate);
  const [endDate, setEndDate] = useState(toDate || defaultEndDate);

  const {addAlert} = useAlert();

  const routeToNewRegistrationPage = () => {
    if (buttonName === 'New Registration') {
      const patientType = selectedValue === 'patient' ? 'PATIENT_COUPLE' : selectedValue || 'PATIENT_COUPLE';
      navigate('/admin/registration/patient-partner-registration', {
        state: { selected_patient_type: patientType },
      });
    }
    if (buttonName === 'Registration') {
      navigate('/admin/registration/patient-partner-registration');
    }
    if (buttonName === 'Billing Information') {
      navigate('/admin/registration/billing-information');
    }
  };

  const handleFilterPopupClose = () => {
    setIsFilterPopupOpen(false);
  };

  const handleFilterPopupOpen = () => {
    setIsFilterPopupOpen(true);
  };

  if (Array.isArray(type) && type.includes('button') && (!buttonName || buttonName.trim() === '')) {
    throw new Error(
      'buttonName must be provided and cannot be an empty string when type includes "button".'
    );
  }

  const types: ButtonType[] = Array.isArray(type) ? type : (type as string).trim().split(' ') as ButtonType[];

  const buttonClass = `btn btn-dark ${styles.addbtn} ${
    buttonName === selectedButton ? styles.selectedButton : ''
  }`;

  const renderElement = (type: ButtonType) => {
    switch (type) {
    case 'button':
      return (
        <button
          type="button"
          className={buttonClass}
          onClick={routeToNewRegistrationPage}
        >
          <span>{buttonName}</span>
        </button>
      );
    case 'toggle':
      return (
        <button
          type='button'
          className={`${styles.togglebutton} ${buttonName === selectedButton ? styles.togglebuttonactive : ''}`}
          onClick={routeToNewRegistrationPage}
        >
          <span className={styles.btnmain}>{buttonName}</span>
        </button>
      );
    case 'filter':
      return (
        <Filter
          className={styles.searchFilter}
          style={{ marginLeft: 10, height: '100%', cursor: 'pointer' }}
          onClick={handleFilterPopupOpen}
        />
      );
    case 'input':
      return (
        <Grid item xs={12}>
          <TextField
            sx={{ width: '100%' }}
            error={false} 
            id="searchInput"
            label={inputLabel || 'Search'}
            variant="outlined"
            placeholder={inputPlaceholder || 'Enter text here'}
            onChange={(e) => onInputChange && onInputChange(e.target.value)}
          />
        </Grid>
      );
    case 'radio':
      return (
        <div className={styles.radioGroup}>
          {radioOptions?.map(option => (
            <label key={option.value} className={styles.radioLabel}>
              <input
                type="radio"
                name="customRadioGroup"
                value={option.value}
                checked={option.value === selectedValue} 
                onChange={() => {
                  onRadioChange?.(option.value);
                }}
                className={styles.radioInput}
              />
              {option.name}
            </label>
          ))}
        </div>
      );
    case 'date':
      return (
        <div className={styles.dateGroup}>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ width: '100%' }}
              error={false} 
              id="start-date"
              label={dateFromLabel || 'Date From'}
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                onDateChange?.(e.target.value, endDate);
              }}
              inputProps={{
                max: today, 
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ width: '100%' }}
              error={false} 
              id="end-date"
              label={dateToLabel || 'Date To'}
              type="date"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => {
                if (e.target.value < startDate) {
                  addAlert('To Date should be greater than From Date', 'error');
                  return;
                } else {
                  setEndDate(e.target.value);
                  onDateChange?.(startDate, e.target.value);
                }
              }}
              inputProps={{
                max: today, 
              }}
            />
          </Grid>
        </div>
      );
    default:
      return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div className={styles.buttonsContainer}>
          {types.map((typeItem) => (
            <React.Fragment key={typeItem}>{renderElement(typeItem)}</React.Fragment>
          ))}
          {isFilterPopupOpen && (
            <Dialog
              open={isFilterPopupOpen}
              onClose={handleFilterPopupClose}
              classes={{ paper: styles.dialogContainer }}
            >
              <FilterPopup onClose={handleFilterPopupClose} />
            </Dialog>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}
