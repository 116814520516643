import React, { useState, useEffect, useCallback, useMemo } from 'react'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { keyframes, ThemeProvider } from '@mui/material/styles';
import { theme } from '../../theme';
import HeaderProfile from '../../components/header_Profile/header_Profile.index';
import OocyteRetrieval from '../../components/Surgery/oocyteRetrieval';
import EmbryoTransfer from '../../components/Surgery/embryoTransfer';
// import Laparoscopy from '../../components/Surgery/laparoscopy';
// import Hysteroscopy from '../../components/Surgery/hysteroscopy';
// import SurgicalSpermRetrieval from '../../components/Surgery/surgicalSpermRetrieval';
import { ReactComponent as UterusIcon } from '../../assets/SVG/uterus1.svg';
import { ReactComponent as EmbryoIcon } from '../../assets/SVG/embryo1.svg';
import { ReactComponent as LaparoscopyIcon } from '../../assets/SVG/laparoscopy1.svg';
import { ReactComponent as HysteroscopyIcon } from '../../assets/SVG/hysteroscopy1.svg';
import { ReactComponent as SpermRetrievalIcon } from '../../assets/SVG/sperm1.svg';
import { usePatientContext } from '../../components/patinetProvider';
import { getARTCycleList } from '../../components/Surgery/oocyteRetrieval/OocyteRetrival.helper';
import { useLoader } from '../../components/loaderProvider/loader';
import CyclesUnavailable from '../../components/Surgery/cyclesUnavailable';
import LaparoscopyContainer from '../../components/Surgery/laparoscopy/LaparoscopyContainer';
import HystereoscopyContainer from '../../components/Surgery/hysteroscopy/hysteroscopyContainer';
import SurgicalSpermRetrievalContainer from '../../components/Surgery/surgicalSpermRetrieval/surgicalSpermRetrievalContainer';



// Interface for patient data
interface PatientData {
  id: number;
  gender: 'MALE' | 'FEMALE';
  age: number;
  nameTitle: string;
  firstName: string;
  lastName: string;
  cycleNo: string;
  mrNo: string;
  updatedDatetime: Date;
  LastModified: string;
  visitID: string;
  genderId: string;
  visitedDoctorID: string;
  therapyId: string;
};

const SurgeryPage: React.FC = () => {
  const [selectedPatient, setSelectedPatient] = useState<PatientData | null>(null);
  const [selectedProcedure, setSelectedProcedure] = useState<string>('OocyteRetrieval');
  
  const { patientId } = usePatientContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [cycleExists, setCycleExists] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  
  const handleDatafromHeader = useCallback((data: PatientData) => {
    setSelectedPatient(data);
  }, []);

  // Navigate centrally based on a procedure type
  const redirectToProcedure = useCallback((procedureType: string) => {
    if (!selectedPatient) return;
    navigate(`/clinical/patients/surgery/${patientId}?surgeryType=${procedureType}`);
  }, [navigate, patientId, selectedPatient]);

  const determineProcedureOnQuery = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const surgeryType = searchParams.get('surgeryType');
    
    if (!selectedPatient) return;

    if (!surgeryType) {
      if (selectedPatient.gender === 'MALE') {
        setSelectedProcedure('SpermRetrieval');
        redirectToProcedure('SpermRetrieval');
      } else if (selectedPatient.gender === 'FEMALE') {
        setSelectedProcedure('OocyteRetrieval');
        redirectToProcedure('OocyteRetrieval');
      }
      return;
    }

    if (selectedPatient) {
      if (selectedPatient.gender === 'MALE') {
        setSelectedProcedure('SpermRetrieval');
        redirectToProcedure('SpermRetrieval');
      } else if (selectedPatient.gender === 'FEMALE') {
        if (surgeryType === 'SpermRetrieval') {
          setSelectedProcedure('OocyteRetrieval');
          redirectToProcedure('OocyteRetrieval');
        } else {
          setSelectedProcedure(surgeryType);
          redirectToProcedure(surgeryType);
        }
      }
    }
  }, [location.search, selectedPatient, redirectToProcedure]);

  useEffect(() => {
    determineProcedureOnQuery();
  }, [location.search, selectedPatient, determineProcedureOnQuery]);
  const handleProcedureSelect = useCallback((procedure: string) => {
    setSelectedProcedure(procedure);
    redirectToProcedure(procedure);
  }, [redirectToProcedure]);

  useEffect(() => {
    showLoader();
    getARTCycleList(patientId)
      .then((data) => {        
        // Set cycleExists based on the cycle list
        const hasCycles = data.length > 0;        
        setCycleExists(hasCycles);
      })
      .catch(() => {
        setCycleExists(false); // Set cycleExists to false on error
      })
      .finally(() => {
        hideLoader();
      });
  }, [patientId, showLoader, hideLoader]);
  
  const renderProcedureForm = useMemo(() => {
    if (!selectedPatient) return <p>No patient selected</p>;
  
    if (!cycleExists && ['OocyteRetrieval', 'EmbryoTransfer'].includes(selectedProcedure)) {
      return <CyclesUnavailable />;
    }
  
    switch (selectedProcedure) {
    case 'OocyteRetrieval':
      return <OocyteRetrieval />;
    case 'EmbryoTransfer':
      return <EmbryoTransfer />;
    case 'Laparoscopy':
      return <LaparoscopyContainer />;
    case 'Hysteroscopy':
      return <HystereoscopyContainer />;
    case 'SpermRetrieval':
      return <SurgicalSpermRetrievalContainer />;
    default:
      return <p>Please select a procedure</p>;
    }
  }, [selectedPatient, selectedProcedure, cycleExists]);
  
  const procedures = useMemo(() => {
    if (!selectedPatient) return [];
    return selectedPatient.gender === 'MALE'
      ? [{ name: 'SpermRetrieval', label: 'Sperm Retrieval', icon: <SpermRetrievalIcon /> }]
      : [
        { name: 'OocyteRetrieval', label: 'Oocyte Retrieval', icon: <UterusIcon /> },
        { name: 'EmbryoTransfer', label: 'Embryo Transfer', icon: <EmbryoIcon /> },
        { name: 'Laparoscopy', label: 'Laparoscopy', icon: <LaparoscopyIcon /> },
        { name: 'Hysteroscopy', label: 'Hysteroscopy', icon: <HysteroscopyIcon /> }
      ];
  }, [selectedPatient]);
  
  const borderAnimation = keyframes`
  0% {
    border-color: rgba(235, 235, 235, 1);
    border-width: 1px;
    box-shadow: 0 0 3px rgba(255, 126, 0, 0.8), 0 0 10px rgba(255, 126, 0, 0.6);
  }
`;

  
  const buttonStyles = useMemo(() => ({
    base: {
      border: '1px solid rgba(235, 235, 235, 1)',
      backgroundColor: 'rgba(250, 250, 250, 1)',
      boxShadow: 'none',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      color: 'rgba(193, 193, 193, 1)',
      '&:hover': {
        backgroundColor: 'rgba(255, 248, 246, 1)',
        boxShadow: '2px 2px 12px 0px rgba(0, 0, 0, 0.15)',
      }
    },
    selected: {
      border: '1px solid rgba(225, 126, 97, 1)',
      backgroundColor: 'rgba(255, 248, 246, 1)',
      boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.12)',
      color: 'rgba(225, 126, 97, 1)',
      fontWeight: 600,    
      animation: `${borderAnimation} 5s ease-in-out `, 
    }
  }), [borderAnimation]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <HeaderProfile onData={handleDatafromHeader} />

        {/* Render buttons dynamically */}
        <Box sx={{ display: 'flex', gap: 2, marginTop: 4, marginLeft: 3 }}>
          {procedures.map((procedure) => (
            <Button
              key={procedure.name}
              variant="contained"
              onClick={() => handleProcedureSelect(procedure.name)}
              sx={{
                ...buttonStyles.base,
                ...(selectedProcedure === procedure.name && buttonStyles.selected),
              }}
              startIcon={procedure.icon}
            >
              {procedure.label}
            </Button>
          ))}
        </Box>

        <div style={{ marginTop: '2rem', marginLeft: '2rem' }}>
          {renderProcedureForm}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SurgeryPage;
