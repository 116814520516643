import React from 'react';
import { ReactComponent as CycleTerminate } from '../../assets/SVG/treatment_plan_msg.svg';
import Grid2 from '@mui/material/Grid2';

const CyclesUnavailable: React.FC = () => {
  return (
    <Grid2 
      container 
      justifyContent="center" 
      alignItems="center" 
    >
      <Grid2>
        <CycleTerminate style={{ width: '600px', height: '400px', marginTop: '64px', }} />
        <p style={{ textAlign: 'center', fontWeight: 600 }}>
            No cycle has been created yet
        </p>
      </Grid2>
    </Grid2>
  );
};

export default CyclesUnavailable;
