import React, { useState, useCallback, useMemo } from 'react'; 
import HeaderProfile from '../../components/header_Profile/header_Profile.index';
import ProcedureList from '../../components/treatmentPlan/Procedure/procedureList';
import {  Tab, Tabs } from '@mui/material';
import { theme } from '../../theme';
import { ThemeProvider } from '@mui/material';
import { tabStyles } from '../../utils/commonUtils';
import DrugsHome from '../../components/treatmentPlan/Drugs/DrugsHome';
import { useLocation } from 'react-router-dom';

interface PatientData {
  id: number;
  gender: 'MALE' | 'FEMALE';
  age: number;
  nameTitle: string;
  firstName: string;
  lastName: string;
  cycleNo: string;
  mrNo: string;
  updatedDatetime: Date;
  LastModified: string;
  visitID: string;
  genderId: string;
  visitedDoctorID: string;
  therapyId: string;
}

const TreatmentPlanPage: React.FC = () => {
  const [selectedPatient, setSelectedPatient] = useState<PatientData | null>(null);
  const [tabIndex, setTabIndex] = useState(0); // Track the selected tab index
  const location = useLocation();
  const handleDatafromHeader = useCallback((data: PatientData) => {
    setSelectedPatient(data);
  }, []);

  const updateQueryParam = (key: string, value: string | null): void => {
    const queryParams = new URLSearchParams(location.search);
    if (value) {
      queryParams.set(key, value); 
    } else {
      queryParams.delete(key);
    }
    window.history.replaceState(null, '', `${location.pathname}?${queryParams.toString()}`);
  };

  const viewMapping: Record<number, string | null> = {
    0: 'drug_list',
    1: 'procedure_list',
  };
  

  // Handle tab change
  const handleChangeTab = (event: React.SyntheticEvent, newTabIndex: number) => {
    setTabIndex(newTabIndex);

    if (Object.prototype.hasOwnProperty.call(viewMapping, tabIndex)) {
      updateQueryParam('view', viewMapping[tabIndex]);
    }
  };

  const renderTabContent = useMemo(() => {
    if (!selectedPatient) {
      return <p>No patient selected</p>;
    }

    if (tabIndex === 0) {
      // Procedure tab
      return <ProcedureList patientId={selectedPatient.id} />;
    }

    // Drugs tab
    return <DrugsHome/>;
  }, [selectedPatient, tabIndex]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <HeaderProfile onData={handleDatafromHeader} />

        {/* Tabs for Procedure and Drugs */}
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          aria-label="Treatment Plan tabs"
          variant="fullWidth"
          sx={tabStyles}
        >
          <Tab label="Procedure" />
          <Tab label="Drugs" />
        </Tabs>

        {/* Display content based on selected tab */}
        <div className="mt-4">
          {renderTabContent}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default TreatmentPlanPage;
