/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { FormControl, Input, InputAdornment, IconButton, MenuItem, TextField, Checkbox, Button, Box } from '@mui/material';
import { ReactComponent as UsernameIcon } from '../../assets/SVG/user.svg';
import { ReactComponent as PasswordIcon } from '../../assets/SVG/lock.svg';
import { ReactComponent as HospitalIcon } from '../../assets/SVG/hospital.svg';
import styles from './LoginFormComponent.module.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginDetails } from '../../types';
import { loginUser } from '../../services/apiService';
import axios from 'axios';
import { useAlert } from '../alertProvider';
import { useLoader } from '../loaderProvider/loader';

interface Props {
    successroute: string
}

const LoginFormComponent: React.FC<Props> = () => {

  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {showLoader, hideLoader} = useLoader();
  const location = useLocation();
  const {addAlert} = useAlert();
  const [error, setError] = useState({ username: false, password: false });

  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  

  const Login = async () => {
    showLoader();
    if (username.trim() === '' || password.trim() === '') {
      addAlert('Please enter username and password', 'error');
      return;
    }
    
    const data:loginDetails={username,password};

       
    loginUser(data).then((res:any)=>{
      addAlert('Login successful', 'success');
      localStorage.setItem('token',res?.access);
      localStorage.setItem('username',res?.username);
      localStorage.setItem('designation',res?.designation);
      localStorage.setItem('dj_admin_panel', (res?.is_staff || res?.is_superuser));

      if (location.state?.from && location.state?.from !== '/login') {
        navigate(location.state?.from);
      } else {
        navigate('/admin/appointments');
      }
    }).catch ((error) => {
      console.error('Login failed:', error);
      if (axios.isAxiosError(error)) {
        let data;
        if (error.status === 401) {
          data = 'Invalid credentials';
        } else {
          data = error.message;
        }
        addAlert(data, 'error');
      } else {
        addAlert('Login Failed', 'error');
      }
      // Handle login failure (e.g., show an error message to the user)
    }).finally (() =>
      hideLoader()
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    // Check if username or password is empty
    if (username.trim() === '' || password.trim() === '') {
      setError({
        username: username.trim() === '',
        password: password.trim() === '',
      });
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.headings}>
        <h1>Login</h1>
        <span>Please enter your login details below</span>
      </div>
      <div className={styles.fields}>
        <Box className={styles.form} component="form" onSubmit={handleSubmit} noValidate>
          <FormControl sx={{ m: 1, width: '320px' }} variant="standard" style={{ width: '100%', margin: '0' }}>
            <Input
              required
              id="standard-adornment-username"
              type='text'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <UsernameIcon style={{ marginBottom: '12px', marginRight: '8px' }} />
                </InputAdornment>
              }
              inputProps={{
                style: { height: '50px', padding: '0 0 10px 0', lineHeight: '50px', fontSize: '1.4rem'},
              }}
              style={{ height: '50px', width: '100%' }}
              error={error.username}
              
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '320px' }} variant="standard" style={{ width: '100%', margin: '0' }}>
            <Input
              required
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <PasswordIcon style={{ marginBottom: '12px', marginRight: '8px' }} />
                </InputAdornment>
              }
              inputProps={{
                style: { height: '50px', padding: '0 0 10px 0', lineHeight: '50px' , fontSize: '1.4rem'},
              }}
              style={{ height: '50px' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              error={error.password}
            />
          </FormControl>
          <div className={styles.action}>
            <Button type="submit" className={styles.login} variant="contained" fullWidth onClick={() => Login()}>Login</Button>
            <Button  className={styles.password_fg} variant="text" fullWidth>Forgot Password ?</Button>
          </div>
        </Box>
      </div>
      
    </div>
  );
};

export default LoginFormComponent;