/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField, ThemeProvider,  MenuItem,  Button, Box, FormControlLabel, Checkbox, Link, Autocomplete, Table, TableContainer, TableBody, TableRow, TableCell, TableHead, Paper } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import {Clinician, ROLES, Role, SurgicalSpermRetrievalType, SurgicalSpermRetrievalValidationSchema,  addSurgicalSpermRetrieval,  anesthesiaOptions,  getSurgicalSpermRetrieval,  indicationItems,  initialSurgicalSpermRetrieval,  methodOfSurgicalSpermRetrievalItems,  siteItems,  specimenTypeItems,  updateSurgicalSpermRetrieval} from './spermRetrieval.helper';
import { theme } from '../../Medical_History_Forms/themeprovider';
import { useAlert } from '../../alertProvider';
import { useLoader } from '../../loaderProvider/loader';
import {ConfirmationModal} from '../../confirmationModal';
import FormObserver from '../../FormikFormObserver';
import { getMaxDate, getMaxDateForMonthInput, RequiredLabel } from '../../../utils/commonUtils';
import { usePatientContext } from '../../SubPatinetProvider';
import styles from '../oocyteRetrieval/oocyteRetrival.module.css';
import { useClinic } from '../../ClinicProvider';
import { getUsersByClinicId, uploadFileURL } from '../../../services/apiService';
import { FileTypes } from '../../../types';
import ImageUploadComponent from '../laparoscopy/imageUploadLaparoscopy';

interface SpermRetrievalFormProp {
  record: number; // Ensure this matches your data structure
  onFormChange?: (hasChanged: boolean) => void;
  onBack: () => void;
  isEditMode: boolean; 
}

const SurgicalSpermRetrieval: React.FC<SpermRetrievalFormProp> = ({ record, onFormChange , onBack, isEditMode = false}) => {
  const handleFormChange = onFormChange || (() => {});
  const [hasReset, setHasReset] = useState(false); 
  const { patientId } = usePatientContext();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [initialValues, setInitialValues] = useState<SurgicalSpermRetrievalType>(initialSurgicalSpermRetrieval);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const maxMonthDate = useMemo(() => getMaxDateForMonthInput(), []);
  const [openResetConfirmDialog, setOpenResetConfirmDialog] = useState(false); 

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ doctorList, setDoctorList ] = useState<any[]>([]);
  const { ClinicId } = useClinic();

  const [file, setFile] = useState<File | null>(null); 
  const [imagePathUrl, setImagePathUrl] = useState<string | null>(null);

  useEffect(() => {
    showLoader();
    getSurgicalSpermRetrieval(record)
      .then((data) => {
        setInitialValues({ ...initialSurgicalSpermRetrieval, ...data });
                    
        if (data.image_path_url) {
          setImagePathUrl(data.image_path_url); 
        }

      })
       
      .catch(() => console.error('Error fetching Oocyte Retrieval', 'error'))
      .finally(() => hideLoader());
  }, [record, addAlert, showLoader, hideLoader]);

  
  const sanitizeSpermRetrieval = (values: SurgicalSpermRetrievalType): SurgicalSpermRetrievalType => {
    const sanitizedValues = { ...values, patient_procedure: record };
    return sanitizedValues;
  };
  
  const handleSubmit = async (
    values: SurgicalSpermRetrievalType,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<SurgicalSpermRetrievalType>
  ) => {
    showLoader();

    // Use the utility function to sanitize values
    const sanitizedValues = sanitizeSpermRetrieval(values);
    
    let spermRetrievalApiCall; 
    if (isEditMode) {
      const id = sanitizedValues?.id;
    
      if (id !== undefined && id !== null) { 
        spermRetrievalApiCall = updateSurgicalSpermRetrieval(id, sanitizedValues);
      } else {
        console.error('ID is required for updating laparoscopy');
        hideLoader();
        setSubmitting(false);
        return; 
      }
    } else {
      spermRetrievalApiCall = addSurgicalSpermRetrieval(sanitizedValues);
    }

    spermRetrievalApiCall
      .then((updatedData) => {
        addAlert('Surgical sperm retrieval saved successfully', 'success');
        
        if (file) {
          const fileName = file.name ; 
          if (updatedData.id !== undefined && updatedData.id !== null) {
            uploadFileURL({ file_name: fileName, source: FileTypes.SPERM_RETRIEVAL, ref_id: updatedData.id }, file as File);
          }
        }

        setInitialValues(updatedData);
        resetForm({ values: updatedData });
        onBack();
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.type === 'validation_error') {
          const apiErrors = error.response.data.errors;
          const formikErrors: FormikErrors<SurgicalSpermRetrievalType> = {};

          apiErrors.forEach((error: { attr: keyof SurgicalSpermRetrievalType; detail: string }) => {
            formikErrors[error.attr] = error.detail;
          });

          setErrors(formikErrors);
        } else {
           
          console.error('An error occurred while saving SurgicalSpermRetrieval.');
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  const handleReset = (
    resetForm: FormikHelpers<SurgicalSpermRetrievalType>['resetForm'],
    setFieldValue: FormikHelpers<SurgicalSpermRetrievalType>['setFieldValue']
  ) => {
    setOpenResetConfirmDialog(false); 
    resetForm(); 
    setHasReset(true); 
    
    Object.keys(initialSurgicalSpermRetrieval).forEach((field) => {
      const initialValue = initialSurgicalSpermRetrieval[field as keyof SurgicalSpermRetrievalType];
      if (typeof initialValue === 'number' || initialValue === null) {
        setFieldValue(field, '');
      } else {
        setFieldValue(field, initialValue);
      }
    });
  };

  
  useEffect(() => {
    showLoader();
    const query = `clinic=${ClinicId}`;
    getUsersByClinicId(query)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setDoctorList(res?.objects ?? []);
      })
      .catch((error) => {
        console.error('Error fetching clinicians:', error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]);

  const getDisabledStyle = (disabled : boolean) => ({
    backgroundColor: disabled ? '#fafafa' : 'inherit',
    overflow: 'hidden', 
    borderRadius: '8px',
  });
  

  // Initialize state for clinician lists
  const [clinicianLists, setClinicianLists] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    ANESTHETIST: [],
    ANDROLOGIST: [],
  });
  
  const fetchCliniciansByRole = useCallback((role: Role) => {
    showLoader();
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {
        setClinicianLists(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]); // Add dependencies here
  
  // Fetch clinicians for each role on component mount or when ClinicId changes
  useEffect(() => {
    ROLES.forEach(fetchCliniciansByRole);
  }, [fetchCliniciansByRole]); // Use the memoize
  

  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={SurgicalSpermRetrievalValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >      
      {({         
        values,
        setFieldValue,
        submitForm,
        validateForm,
        errors,
        setFieldError, 
        dirty,  handleBlur, handleChange, handleSubmit, isSubmitting, touched, resetForm, setFieldTouched }) => (
        <>        
          <FormObserver dirty={dirty} onFormChange={handleFormChange} />          
          <ThemeProvider theme={theme}>
            <form onSubmit={handleSubmit}>
              <Box sx={{flexGrow: 1, marginRight:2}}>

                <Grid2 container spacing={2} justifyContent="start" sx={{ marginTop: 2 }}>
                  <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }} sx={{ mt: 1 }}>
                    <TextField
                      fullWidth
                      size="small"
                      label={RequiredLabel('Date', true)}
                      name="date"
                      type="date"
                      InputLabelProps={{shrink: true}}
                      value={values.date}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue('date', e.target.value || null)}
                      error={touched.date && Boolean(errors.date)}
                      // helperText={touched.date && errors.date}
                      inputProps={{ max: maxMonthDate  }}
                    />
                  </Grid2>

                  {/* Start Time Field */}
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ mt: 1 }}>
                    <TextField
                      fullWidth
                      size="small"
                      label={RequiredLabel('Start Time', true)}
                      name="time"
                      type="time"
                      InputLabelProps={{ shrink: true  }}
                      value={values.time}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue('time', e.target.value || null)}
                      error={touched.time && Boolean(errors.time)}
                      // helperText={touched.time && errors.time}
                    />
                  </Grid2>

                  {/* Indication Field */}
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ mt: 1 }}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={RequiredLabel('Indication', true)}
                      name="indication"
                      value={values.indication}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue('indication', e.target.value || null)}
                      error={touched.indication && Boolean(errors.indication)}
                      // helperText={touched.indication && errors.indication}
                    >
                      {indicationItems.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}  sx={{ mt: 1 }}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="clinician"                      
                      options={clinicianLists['DOCTOR'] || []} 
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['DOCTOR'].find((doctor) => doctor.id === values.clinician) || null}
                      onChange={(_, value) => {
                        setFieldValue('clinician', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Clinician', true)}
                          error={touched.clinician && Boolean(errors.clinician)}
                          // helperText={touched.clinician && errors.clinician}
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ mt: 1 }}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={RequiredLabel('Specimen Type', true)}
                      name="specimen_type"
                      value={values.specimen_type}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue('specimen_type', e.target.value || '')}
                      error={touched.specimen_type && Boolean(errors.specimen_type)}
                      // helperText={touched.specimen_type && errors.specimen_type}
                    >
                      {specimenTypeItems.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ mt: 1 }}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={RequiredLabel('Method of Surgical Sperm Retrieval', true)}
                      name="method_of_surgical_sperm_retrieval"
                      value={values.method_of_surgical_sperm_retrieval}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue('method_of_surgical_sperm_retrieval', e.target.value || '')}
                      error={touched.method_of_surgical_sperm_retrieval && Boolean(errors.method_of_surgical_sperm_retrieval)}
                      // helperText={touched.method_of_surgical_sperm_retrieval && errors.method_of_surgical_sperm_retrieval}
                    >
                      {methodOfSurgicalSpermRetrievalItems.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid2>


                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}  sx={{ mt: 1 }}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="surgeon"
                      options={clinicianLists['DOCTOR'] || []} 
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['DOCTOR'].find((doctor) => doctor.id === values.surgeon) || null}
                      onChange={(_, value) => {
                        setFieldValue('surgeon', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Surgeon', true)}
                          error={touched.surgeon && Boolean(errors.surgeon)}
                          // helperText={touched.surgeon && errors.surgeon}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}  sx={{ mt: 1 }}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="anesthetist"
                      options={clinicianLists['ANESTHETIST'] || []} 
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['ANESTHETIST'].find((doctor) => doctor.id === values.anesthetist) || null}
                      onChange={(_, value) => {
                        setFieldValue('anesthetist', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Anesthetist', true)}
                          error={touched.anesthetist && Boolean(errors.anesthetist)}
                          // helperText={touched.anesthetist && errors.anesthetist}
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ mt: 1 }}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={RequiredLabel('Anesthesia', true)}
                      name="anesthesia"
                      value={values.anesthesia}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue('anesthesia', e.target.value || '')}
                      error={touched.anesthesia && Boolean(errors.anesthesia)}
                      // helperText={touched.anesthesia && errors.anesthesia}
                    >
                      {anesthesiaOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid2>


                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}  sx={{ mt: 1 }}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="andrologist"
                      options={clinicianLists['ANDROLOGIST'] || []} 
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['ANDROLOGIST'].find((doctor) => doctor.id === values.andrologist) || null}
                      onChange={(_, value) => {
                        setFieldValue('andrologist', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Andrologist'
                          error={touched.andrologist && Boolean(errors.andrologist)}
                          // helperText={touched.andrologist && errors.andrologist}
                        />
                      )}
                    />
                  </Grid2>
                  
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}  sx={{ mt: 1 }}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="witness_andrologist"
                      options={clinicianLists['ANDROLOGIST'] || []}
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['ANDROLOGIST'].find((doctor) => doctor.id === values.witness_andrologist) || null}
                      onChange={(_, value) => {
                        setFieldValue('witness_andrologist', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Witness Andrologist'
                          error={touched.witness_andrologist && Boolean(errors.witness_andrologist)}
                          // helperText={touched.witness_andrologist && errors.witness_andrologist}
                          helperText={
                            touched.witness_andrologist &&
                        (errors.witness_andrologist === 'The witness andrologist cannot be the same as the andrologist.'
                          ? errors.witness_andrologist
                          : '')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ mt: 1 }}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={RequiredLabel('Site', true)}
                      name="site"
                      value={values.site}
                      onBlur={handleBlur}                      
                      onChange={(e) => {
                        const selectedSite = e.target.value;
                        setFieldValue('site', selectedSite);

                        // Clear `right_sperm_count` if LEFT_TESTIS is selected
                        if (selectedSite === 'LEFT_TESTIS') {
                          setFieldValue('right_sperm_count', null);
                          setFieldValue('right_motility', null);
                          setFieldValue('right_remark', null);
                        }
                        if (selectedSite === 'RIGHT_TESTIS') {
                          setFieldValue('left_sperm_count', null);
                          setFieldValue('left_motility', null);
                          setFieldValue('left_remark', null);
                        }
                      }}
                      error={touched.site && Boolean(errors.site)}
                      helperText={touched.site && errors.site}
                    >
                      {siteItems.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid2>


                  <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12 }} sx={{ mt: 3 }}>
                    <TableContainer>
                      <Table>
                        <TableHead  sx={{ height: '10px', padding: '12px 0 0 0', gap: '10px', borderRadius: '12px', background: '#F6F6F6CF', border: 'none'}}>
                          <TableRow sx={{ height: '10px'}}>
                            <TableCell sx={{ width: '22%' }}>Site</TableCell>
                            <TableCell>Sperm Count</TableCell>
                            <TableCell>Motility</TableCell>
                            <TableCell>Remark</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Right</TableCell>
                            <TableCell>                                
                              <TextField
                                size="small"
                                //   label='sperm_counts'
                                // InputLabelProps={{shrink: true}} 
                                name="right_sperm_count"
                                value={values.right_sperm_count}
                                type='number'
                                onBlur={handleBlur}
                                onChange={(e) => setFieldValue('right_sperm_count', e.target.value || null)}
                                error={touched.right_sperm_count && Boolean(errors.right_sperm_count)}
                                disabled={
                                  values.site === 'LEFT_TESTIS'
                                }
                                sx={getDisabledStyle(values.site === 'LEFT_TESTIS')}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                //   label='sperm_counts'
                                // InputLabelProps={{shrink: true}}
                                name="right_motility"
                                type='number'
                                value={values.right_motility}
                                onBlur={handleBlur}
                                onChange={(e) => setFieldValue('right_motility', e.target.value || null)}
                                error={touched.right_motility && Boolean(errors.right_motility)}
                                disabled={
                                  values.site === 'LEFT_TESTIS'
                                }
                                sx={getDisabledStyle(values.site === 'LEFT_TESTIS')}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                //   label='sperm_counts'
                                // InputLabelProps={{shrink: true}}
                                name="right_remark"
                                value={values.right_remark}
                                onBlur={handleBlur}
                                onChange={(e) => setFieldValue('right_remark', e.target.value || null)}
                                error={touched.right_remark && Boolean(errors.right_remark)}
                                disabled={
                                  values.site === 'LEFT_TESTIS'
                                }
                                sx={getDisabledStyle(values.site === 'LEFT_TESTIS')}
                              />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Left</TableCell>
                            <TableCell>                                
                              <TextField
                                size="small"
                                //   label='sperm_counts'
                                // InputLabelProps={{shrink: true}}
                                name="left_sperm_count"
                                type='number'
                                value={values.left_sperm_count}
                                onBlur={handleBlur}
                                onChange={(e) => setFieldValue('left_sperm_count', e.target.value || null)}
                                error={touched.left_sperm_count && Boolean(errors.left_sperm_count)}
                                disabled={
                                  values.site === 'RIGHT_TESTIS'
                                }
                                sx={getDisabledStyle(values.site === 'RIGHT_TESTIS')}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                //   label='sperm_counts'
                                // InputLabelProps={{shrink: true}}
                                name="left_motility"
                                type='number'
                                value={values.left_motility}
                                onBlur={handleBlur}
                                onChange={(e) => setFieldValue('left_motility', e.target.value || null)}
                                error={touched.left_motility && Boolean(errors.left_motility)}
                                disabled={
                                  values.site === 'RIGHT_TESTIS'
                                }
                                sx={getDisabledStyle(values.site === 'RIGHT_TESTIS')}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                //   label='sperm_counts'
                                // InputLabelProps={{shrink: true}}
                                name="left_remark"
                                value={values.left_remark}
                                onBlur={handleBlur}
                                onChange={(e) => setFieldValue('left_remark', e.target.value || null)}
                                error={touched.left_remark && Boolean(errors.left_remark)}
                                disabled={
                                  values.site === 'RIGHT_TESTIS'
                                }
                                sx={getDisabledStyle(values.site === 'RIGHT_TESTIS')}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid2>
                </Grid2>

                <Grid2 container spacing={2} justifyContent="start" sx={{ marginTop: 4 }}>
                  {/* General complications */}
                  <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Complications"
                      name="complications"
                      value={ values.complications || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.complications && Boolean(errors.complications)}
                      helperText={touched.complications && errors.complications}
                      multiline
                      minRows={1}  
                      maxRows={2}
                    />
                  </Grid2>
                  
                  {/* General Remark */}
                  <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Remark"
                      name="remarks"
                      value={ values.remarks || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.remarks && Boolean(errors.remarks)}
                      helperText={touched.remarks && errors.remarks}
                      multiline
                      minRows={1}  
                      maxRows={2}
                    />
                  </Grid2>

                  
                  <Grid2 size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                    <ImageUploadComponent onFileUpload={setFile}       imagePathUrl={imagePathUrl} />
                  </Grid2>

                </Grid2>

                <Box className={styles.customButtonDiv}>                  
                  <button
                    type="button"
                    className={styles.resetButton}
                    onClick={() => setOpenResetConfirmDialog(true)} 
                  >
                  Reset
                  </button>
                  <button
                    type="button"
                    className={`${styles.customButton} ${isSubmitting || !dirty ? styles.disabled : ''}`}
                    disabled={isSubmitting || !dirty} 
                    onClick={() => {
                      console.log('Starting form validation...');                        
                      validateForm().then((formErrors) => {
                        console.log('Form validation completed. Form errors:', formErrors);
                        if (Object.keys(formErrors || {}).length === 0 && !isSubmitting) {
                          console.log('No errors found and form is not submitting. Opening confirm dialog...');
                          setOpenConfirmDialog(true);
                        } else {
                          console.log('Form has errors or is submitting. Marking fields as touched...');
                            
                          Object.keys(values).forEach((field) => {
                            console.log(`Marking field ${field} as touched.`);
                            setFieldTouched(`${field}`, true, false);
                          }); 
                        }
                      }).catch((err) => {
                        console.error('Error during form validation:', err);
                      });
                    }}                  
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {isSubmitting ? 'Saving...' : 'Save'}
                    </span>
                  </button>                  
                </Box>

                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    setOpenConfirmDialog(false);
                    submitForm(); 
                  }}
                  type="save"
                  title="Oocyte Retrieval"
                  contentMessage="Are you sure you want to save oocyte retrieval?"
                />
                <ConfirmationModal
                  open={openResetConfirmDialog}
                  handleClose={() => setOpenResetConfirmDialog(false)} 
                  onConfirm={() => handleReset(resetForm, setFieldValue)} 
                  title="SurgicalSpermRetrieval"
                  contentMessage="Are you sure you want to reset oocyte retrieval ?"
                  type={'delete'}
                />
              </Box>
            </form>
          </ThemeProvider>
        </>
      )}
    </Formik>
  );
};

export default SurgicalSpermRetrieval;