import { getRequest } from '../../../services/httpService';



export interface ProcessedSemenSample {
    id: number;
    cycle: number;
    sperm_type: string | null;
    sample_number: string | null;
    date: string | null;
    time: string | null;
    timsi: string | null;
    sample_collected_at: string | null;
    collection_or_thaw_date: string | null;
    collection_or_thaw_time: string | null;
    receiving_date: string | null;
    receiving_time: string | null;
    cryo_number: string | null;
    semen_collection_method: string | null;
    surgical_extraction_type: string | null;
    abstinence_period: string | null;
    semen_preparation_method: string | null;
    gradient_used: string | null;
    gradient_mini_gradient: string | null;
    centrifuged_at_rpm: string | null;
    centrifuged_at_min: string | null;
    swim_up_ml: string | null;
    swim_up_min: string | null;
    wash_media_ml: string | null;
    wash_media_rpm: string | null;
    wash_media_min: string | null;
    remark: string | null;
    pre_volume_ml: string | null;
    pre_sperm_concentration: string | null;
    pre_rapid_progressive_percent: string | null;
    pre_slow_progressive_percent: string | null;
    pre_non_progressive_percent: string | null;
    pre_wbc_million_per_ml: string | null;
    pre_pus_cells: string | null;
    pre_rbc: string | null;
    pre_epithelial_cells: string | null;
    post_volume_ml: string | null;
    post_sperm_concentration: string | null;
    post_rapid_progressive_percent: string | null;
    post_slow_progressive_percent: string | null;
    post_non_progressive_percent: string | null;
    post_wbc_million_per_ml: string | null;
    post_pus_cells: string | null;
    post_rbc: string | null;
    post_epithelial_cells: string | null;
    processing_remark: string | null;
    done_by: number | null;
    witness_by: number | null;
    disposition_type: string | null;
    done_by_name: string | null;
    witness_name: string | null;
    pre_total_sperm_count: string | null;
    pre_all_progressive: string | null;
    pre_total_motility: string | null;
    pre_total_motility_count: string | null;
    pre_immotile_percent: string | null;
    post_total_sperm_count: string | null;
    post_all_progressive: string | null;
    post_total_motility: string | null;
    post_total_motility_count: string | null;
    post_immotile_percent: string | null;
    source_details:string |null
  }
  


export function getProcessedSemenSampleUrl(ID: number | string, query?:string): string {
  return `/semen-processing/patient/${ID}/${query}`;
}
  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getProcessedSemenSample(ID: number | string, query?:string): Promise<any> {
  return getRequest(getProcessedSemenSampleUrl(ID.toString(), query), { responseType: 'json' });
}
  
























