
import React, { useState } from 'react';
// import InfoIcon from '@mui/icons-material/Info'; // Or use your infoIcon image.
import { ReactComponent as InfoIcon } from '../../../assets/SVG/MaterialInfoCircle.svg';

interface OverlayContent {
    title: string;
    details: { label: string; value: string }[];
  }
  
  interface OverlayIconPanelProps {
    content: OverlayContent;
  }

  
const OverlayIconPanel: React.FC<OverlayIconPanelProps> = ({ content }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <InfoIcon
        style={{ cursor: 'pointer', color: '#555' }}
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => setShowOverlay(false)}
      />
      {showOverlay && (
        <div
          style={{
            position: 'absolute',
            top: '30px',
            left: '50%',
            width: '400px',
            transform: 'translateX(-50%)',
            padding: '16px',
            background: 'white',
            color: '#232323',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            fontSize: '0.9rem',
            textAlign: 'left',
            zIndex: 10,
          }}
        >
          <div
            style={{
              fontFamily:'Montserrat',
              fontWeight: 700,
              marginBottom: '8px',
              fontSize: '20px',
              borderBottom: '1px solid #ddd',
              paddingBottom: '4px',
            }}
          >
            {content.title}
          </div>
          {content.details.map((detail, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '6px',
              }}
            >
              <span style={{fontFamily:'Montserrat', fontWeight:400, fontSize:'16px', color:'#737373'}}>{detail.label}</span>
              <span style={{fontFamily:'Nunito', fontWeight:500, fontSize:'16px', color:'#232323'}}>{detail.value} </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
  
export default OverlayIconPanel;
  
