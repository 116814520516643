/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu, MenuListboxSlotProps } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { CssTransition } from '@mui/base/Transitions';
import { PopupContext } from '@mui/base/Unstable_Popup';
import ArrowDown from '../../assets/SVG/arrow-down-single.svg';
import styles from './UserSettingsComponent.module.css';
import { UserSettingsComponentProps } from '../../types';
import { useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PublicIcon from '@mui/icons-material/Public';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { redirectToAdminConsole } from '../../services/httpService';

export default function UserSettingsComponent({ user }: UserSettingsComponentProps) {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createHandleMenuClick = (menuItem: string) => {
    if (menuItem === 'dj_admin_panel') {
      return () => {
        redirectToAdminConsole(); // Navigate to dj-admin panel
      };
    }
    return () => {
      localStorage.clear();
      navigate('/login'); // Navigate to login
    };
  };
  const dj_admin_panel:boolean = localStorage.getItem('dj_admin_panel') === 'true';

  return (
    <Dropdown>
      <MenuButton className={styles.name}>{`${user.first_name} ${user.last_name}`}<img src={ArrowDown} alt='dropdown'/></MenuButton>
      <Menu slots={{ listbox: AnimatedListbox }}>
        <MenuItem className={styles.item} sx={{color: 'grey'}}><AlternateEmailIcon/>{`${user.email}`}</MenuItem>
        <MenuItem className={styles.item} sx={{color: 'grey'}}><AccountCircleIcon/>{`${user.username}`}</MenuItem>
        <MenuItem className={styles.item} sx={{color: 'grey'}}><PublicIcon/>{`${user.tenant.region}`}</MenuItem>
        {dj_admin_panel && <MenuItem className={styles.item} sx={{color: 'grey'}} onClick={createHandleMenuClick('dj_admin_panel')}><SettingsIcon/>Configuration</MenuItem>}
        <MenuItem className={styles.item} sx={{color: 'grey'}}><AccessTimeIcon/>{`${Intl.DateTimeFormat().resolvedOptions().timeZone}`}</MenuItem>
        <MenuItem className={styles.item} sx={{color: 'red', cursor: 'pointer'}} onClick={createHandleMenuClick('Log out')}><LogoutIcon/>Log out</MenuItem>
      </Menu>
    </Dropdown>
  );
}

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Listbox = styled('ul')(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
    z-index: 1;
  
    .closed & {
      opacity: 0;
      transform: scale(0.95, 0.8);
      transition: opacity 200ms ease-in, transform 200ms ease-in;
    }
    
    .open & {
      opacity: 1;
      transform: scale(1, 1);
      transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
    }
  
    .placement-top & {
      transform-origin: bottom;
    }
  
    .placement-bottom & {
      transform-origin: top;
    }
    `,
);

const AnimatedListbox = React.forwardRef<HTMLUListElement, MenuListboxSlotProps>(
  function AnimatedListbox(props, ref) {
    const { ...other } = props;
    const popupContext = React.useContext(PopupContext);

    if (popupContext == null) {
      throw new Error(
        'The `AnimatedListbox` component cannot be rendered outside a `Popup` component'
      );
    }

    const verticalPlacement = popupContext.placement.split('-')[0];

    return (
      <CssTransition
        className={`placement-${verticalPlacement}`}
        enterClassName="open"
        exitClassName="closed"
      >
        <Listbox {...other} ref={ref} />
      </CssTransition>
    );
  }
);

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
      color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
    }
    `,
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
      font-family: 'Nunito', sans-serif; /* Updated to Nunito */
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5;
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
      background: transparent; /* No background */
      border: none; /* Remove border */
      padding: 0; /* Remove padding */
      cursor: pointer; /* Keep cursor as pointer for interactivity */
  
      &:hover {
        background: transparent; /* No hover background change */
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[800]}; /* Optional color change on hover */
      }
    
      &:active {
        background: transparent; /* No active background change */
      }
    
      &:focus-visible {
        outline: none; /* Remove focus outline */
      }
    `,
);