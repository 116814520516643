/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';
import { getRequest, putRequest } from '../../services/httpService';
import { FormikErrors } from 'formik';

const isValidTime = (value: string) => {
  const [hours, minutes] = value.split(':').map((part) => parseInt(part, 10));
  return (
    !isNaN(hours) &&
    !isNaN(minutes) &&
    hours >= 0 && hours < 24 &&
    minutes >= 0 && minutes < 60
  );
};



export  const PlanInformationValidationSchema = Yup.object({
  disposition_plan: Yup.string().nullable(),
  disposition_plan_type: Yup.string()
    .test('plan-type-required', 'Plan is required', function(value) {
      const { disposition_plan } = this.parent;
      if (disposition_plan === 'THAW') {
        return value !== undefined && value !== null && value.trim() !== '';
      }
      return true; 
    }),
  disposition_date: Yup.date()
    .test('disposition-date-required', 'This field may not be null.', function(value) {
      const { disposition_plan } = this.parent;
      if (disposition_plan === 'THAW' || disposition_plan === 'DISCARD') {
        return value !== undefined && value !== null; 
      }
      return true; 
    }),
  disposition_time: Yup.string()
    .test('disposition-time-required', 'This field may not be null.', function(value) {
      const { disposition_plan } = this.parent;
      if (disposition_plan === 'THAW' || disposition_plan === 'DISCARD') {
        return value !== undefined && value !== null && isValidTime(value);
      }
      return true; 
    }),
  disposition_embryologist: Yup.string().nullable()
    .test('embryologist-required', 'Embryologist is required', function(value) {
      const { disposition_plan } = this.parent;
      if (disposition_plan === 'THAW' || disposition_plan === 'DISCARD') {
        return value !== undefined && value !== null && value.trim() !== ''; 
      }
      return true; 
    }),
  disposition_witness: Yup.string().nullable()
    .test('witness-required', 'Witness is required', function(value) {
      const { disposition_plan } = this.parent;
      if (disposition_plan === 'THAW' || disposition_plan === 'DISCARD') {
        return value !== undefined && value !== null && value.trim() !== ''; 
      }
      return true; 
    }),
  is_disposition_survived: Yup.string().nullable(),
  disposition_remarks: Yup.string().nullable(),
});


export const initialValues = {
  disposition_plan: 'THAW',
  disposition_plan_type: '',
  disposition_date: '',
  disposition_time: '',
  disposition_embryologist: '',
  disposition_witness: '',
  is_disposition_survived: '',
  thaw_id: '',
  disposition_remarks: '',

};

export interface PlanInformationFormValues {
    disposition_plan_type: string;
    disposition_date: string;
    disposition_time: string;
    disposition_embryologist: string;
    disposition_witness: string;
    is_disposition_survived: string;
    disposition_remarks: string;
    [key: string]: any;
  }
  


export const clearDispositionFields = (
  setFieldValue: { (field: string, value: any, shouldValidate?: boolean): Promise<void | FormikErrors<any>>; (arg0: string, arg1: string): void; },
  setFieldTouched: { (field: string, isTouched?: boolean, shouldValidate?: boolean): Promise<void | FormikErrors<any>>; (arg0: string, arg1: boolean): void; },
  setFieldError: { (field: string, message: string | undefined): void; (arg0: string, arg1: undefined): void; }
) => {
  const fields = [
    'disposition_plan_type',
    'disposition_date',
    'disposition_time',
    'disposition_embryologist',
    'disposition_witness',
    'is_disposition_survived',
    'disposition_remarks',
    'thaw_id'
  ];

  // Clear form field values
  fields.forEach(field => setFieldValue(field, ''));

  // Reset touched fields
  fields.forEach(field => setFieldTouched(field, false));

  // Clear errors
  fields.forEach(field => setFieldError(field, undefined));
};

  
export const plan = [
  { value: 'CULTURE', label: 'Culture' },
  { value: 'RESEARCH', label: 'Research' },
];




export const Survival = [
  { value: 'YES', label: 'yes' },
  { value: 'NO', label: 'No ' },
];


export function getDonebyWitnessby(role: string): string {
  return `/users/?role=${role}`;
}


 
export function getDonebyWitnessbyData(role: string): Promise<any> {
  return getRequest(getDonebyWitnessby(role));
}

// OOCYTE Oocyte
export function putembryoUrl(id: string): string {
  return `/embryo/${id}/`;
}
export function putEmbruo(analysisId: string, payload: PlanInformationFormValues): Promise<PlanInformationFormValues> {
  return putRequest(putembryoUrl(analysisId), payload); 
}

// OOCYTE Oocyte
export function putOocyteoUrl(id: string): string {
  return `/oocyte/${id}/`;
}

export function putOocyte(analysisId: string, payload: PlanInformationFormValues): Promise<PlanInformationFormValues> {
  return putRequest(putOocyteoUrl(analysisId), payload); 
}