// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import LaparoscopyList from './LaparoscopyList';
import Laparoscopy from './index';
import LaparoscopyViewDetails from './laparoscopyViewDetails';

const LaparoscopyContainer = () => {

  const [viewMode, setViewMode] = useState<'list' | 'edit' | 'view'>('list');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);


  // Handlers with useCallback to prevent unnecessary re-creation
  const handleEdit = useCallback(
    (id: number) => {
      // const record = laparoscopyDetails.find((item) => item.id === id);
      setSelectedRecord(id);
      setViewMode('edit');
      setIsEditMode(false); 
    },[]);

  const handleView = useCallback(
    (id: number) => {
      // const record = laparoscopyDetails.find((item) => item.id === id);
      setSelectedRecord(id);
      setViewMode('view');
    },[]);

  const handleBack = useCallback(() => {
    setSelectedRecord(null);
    setViewMode('list');
    
  }, []);

  const handleEditFromView = useCallback(() => {
    setViewMode('edit');
    setIsEditMode(true); 
  }, []);

  // Memoize JSX rendering logic to avoid unnecessary re-renders
  const currentView = useMemo(() => {
    switch (viewMode) {
    case 'list':
      return (
        <LaparoscopyList
          onEdit={handleEdit}
          onView={handleView}
        />
      );
    case 'edit':
      return (
        selectedRecord && (
          <Laparoscopy
            record={selectedRecord}
            onBack={handleBack} 
            isEditMode={isEditMode}
          />
        )
      );
    case 'view':
      return (
        selectedRecord && (
          <LaparoscopyViewDetails record={selectedRecord} onBack={handleBack} onEdit={handleEditFromView} />
        )
      );
    default:
      return null;
    }
  }, [viewMode, handleEdit, handleView, handleBack, selectedRecord, handleEditFromView, isEditMode]); 

  return <div>{currentView}</div>;
};

export default LaparoscopyContainer;
