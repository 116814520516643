import React from 'react'; 
import { ReactComponent as CycleTerminate } from '../../../assets/SVG/No-occyteGrading-Found.svg';
import Grid2 from '@mui/material/Grid2';

interface GradingUnavailableProps {
  message?: string;
}
const GradingUnavailable: React.FC<GradingUnavailableProps> = ({ message = 'No cycle has been created yet' }) => {

  return (
    <Grid2 
      container 
      justifyContent="center" 
      alignItems="center" 
    >
      <Grid2>
        <CycleTerminate style={{ width: '600px', height: '400px', marginTop: '64px' }} />
        <p style={{ textAlign: 'center', fontWeight: 600 }}>
          {message}
        </p>
      </Grid2>
    </Grid2>
  );
};

export default GradingUnavailable; 
