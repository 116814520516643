import { getRequest } from "../../../services/httpService";

export interface cycleProcedures{
    id ?: number,
    title:string,
    Description?:string,
}

export const CycleRelatedProcedures: cycleProcedures[]=[
  { id: 1, title: 'Natural Cycle', Description: 'Treatment approach where no fertility drugs are used to stimulate the ovaries.' },
  { id: 2 , title: 'Medicated (Ol) Cycle', Description: 'Stimulates ovulation in women with irregular or absent periods.' },
  { id: 3 , title: 'IUI Cycle', Description: 'Increases the chances of fertilization by placing sperm directly into the uterus.' },
  { id: 4 , title: 'IVF / ICSI Cycle', Description: 'Fertilizes eggs outside the body and transfers embryos to the uterus.' },
  { id: 5 , title: 'Thaw / FET Cycle', Description: 'Transfers previously frozen embryos into the uterus.' },
  { id: 6 , title: 'Egg Donation Cycle', Description: 'Used when a patient cannot produce viable eggs or in cases of advanced maternal age.' },
  { id: 7 , title: 'Surrogacy Cycle', Description: 'Embryo created from intended parents or donors is carried by a surrogate.' },
  { id: 8 , title: 'Reciprocal IVF Cycle', Description: 'One partner provides eggs, and the other carries the pregnancy.' },
  { id: 9 , title: 'Cryopreservation', Description: 'Allows individuals to freeze eggs or embryos for future use.' },

];

export interface Meta {
  limit: number,
  next?: string,
  offset: number,
  previous?: string,
  total_count: number
}

export interface ServiceMaster {
  id: number; // Read-only property
  name: string; // Required, max length: 32
  code: string; // Required, max length: 16
  description?: string | null; // Optional, nullable
  service_type: ServiceTypeEnum; // Required enum field
  notes?:string
}

export interface ProcedureMaster {
  id: string; // Unique identifier
  description?: string; // Optional description field
  icon_path: string; // Path to the icon (required)
  gender: GenderEnum; // Enum for gender
  procedure_type: ProcedureTypeEnum; // Enum for procedure type
  name: string; // Procedure name (max length: 32)
  for_patient_type: ForPatientTypeEnum; // Enum for patient type
}

export interface PaginatedServiceMasterList {
  meta: Meta;
  objects: ServiceMaster[];
}

export interface PaginatedProcedureMasterList{
  meta: Meta;
  objects: ProcedureMaster[];

}

export type ServiceTypeEnum = 'CYCLE_RELATED' | 'NON_CYCLE_RELATED';
export type GenderEnum= 'MALE' | 'FEMALE';
export type ProcedureTypeEnum = 'NATURAL_OR_MEDICAL' | 'OTHERS';
export type ForPatientTypeEnum = 'OOCYTE_DONOR'| 'PATIENT-COUPLE'| 'PATIENT-SINGLE'| 'SPERM_DONOR'| 'SURROGATE';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTreatmentServices(query:string):Promise<PaginatedServiceMasterList>{
  return getRequest(`/treatment-plan-services/${query}`);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getProcedures(query:string):Promise<PaginatedProcedureMasterList>{
  return getRequest(`/procedures/${query}`);
}