/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { IconButton, Button, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { uploadFileURL } from '../../../services/apiService';
import { FileTypes } from '../../../types';

interface ImageUploadComponentProps {
  id: number;
  onFileUpload: (file: File | null) => void; 
  imagePathUrl?: string | null; 
}

const EmbryoFileUpload: React.FC<ImageUploadComponentProps> = ({ id, onFileUpload, imagePathUrl }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);

  useEffect(() => {
    if (imagePathUrl) {
      setUploadedImage(imagePathUrl);
    }
  }, [imagePathUrl]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUploadedImage(imageUrl);
      onFileUpload(file);

      const fileName = file.name;
      if (id !== undefined && id !== null) {
        uploadFileURL(
          {
            file_name: fileName,
            source: FileTypes.EMBRYO_TRANSFER, 
            ref_id: id, 
          },
          file 
        )
          .then((response) => {
            console.log('File uploaded successfully', response);
          })
          .catch((error) => {
            console.error('File upload failed', error);
          });
      }
      
    } else {
      onFileUpload(null); 
    }
  };

  const handlePreviewClick = () => {
    if (uploadedImage) {
      window.open(uploadedImage, '_blank'); 
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {/* Image Upload Button and Preview on Same Line */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {/* Image Upload Button */}
        <label htmlFor="image-upload">
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
          <Button
            variant="outlined"
            component="span"
            style={{
              width: '10rem',
              height: '36px',
              padding: '12px 40px',
              gap: '8px',
              borderRadius: '10px',
              background: 'rgba(243, 243, 243, 1)',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FileUploadOutlinedIcon style={{ width: '20px', marginRight: '8px', color: 'black' }} />
            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '22px',
                textAlign: 'left',
                color: 'rgba(80, 80, 80, 1)',
                textTransform: 'capitalize',
              }}
            >
              Upload
            </Typography>
          </Button>
        </label>

        {/* Show Preview Icon After Upload */}
        {uploadedImage && (
          <IconButton onClick={handlePreviewClick}>
            <Visibility />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default EmbryoFileUpload;
