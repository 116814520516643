import React, { useState } from 'react';
import HeaderProfile from '../../components/header_Profile/header_Profile.index';
import CycleOverviewList from '../../components/CycleOverViewList/CycleOverviewList';
import { Box } from '@mui/material';

interface PatientData {
  id: number;
  gender: 'MALE' | 'FEMALE';
  age: number;
  nameTitle: string;
  firstName: string;
  lastName: string;
  cycleNo: string;
  mrNo: string;
  updatedDatetime: Date;
  LastModified: string;
  visitID: string;
  genderId: string;
  visitedDoctorID: string;
  therapyId: string;
}

const CycleOverview = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPatient, setSelectedPatient] = useState<PatientData | null>(null);

  const handleDataFromHeader = (data: PatientData) => {
    setSelectedPatient(data);
  };

  return (
    <div>
      <div>
        <HeaderProfile onData={handleDataFromHeader} />
      </div>
      <Box mt={4} px={2}>
        <CycleOverviewList />
      </Box>
    </div>
  );
};

export default CycleOverview;
