/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton, Grid, TextField, MenuItem, Button, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modals.module.css';
import { convertDateToISOWithOffset, convertToLocalDateTime, convertUTCToLocalTime, convertUTCToLocalTimeDate, formatDateToLocalYYYYMMDD } from '../../services/time';
import { createEvent, deleteEvents, EventAppointment, EventCycleRequest, EventData, getEvent, getRooms, getTables, getUsersByClinicOnly, OptionType, Room, Table, updateEvent } from './helper.stim';
import { PaginatedResponse, UserBasic } from '../../types';
import { useClinic } from '../../components/ClinicProvider';
import { showConfirmationModal } from '../../components/ConfirmationModel';

interface EventModelProps {
  scheduleEventModalOpen: boolean;
  handleScheduleModalClose: () => void;
  eventDate?: Date;
  patient: number;
  partner?: number;
  stim: number;
  id?: string;
}

const EventModel: React.FC<EventModelProps> = ({ scheduleEventModalOpen, handleScheduleModalClose, eventDate, patient, partner, stim, id }) => {
  const [doctors, setDoctors] = useState<OptionType[]>([]);
  const [tables, setTables] = useState<OptionType[]>([]);
  const [rooms, setRooms] = useState<OptionType[]>([]);
  const [event, setEvent] = useState<EventData | null>();
  const { ClinicId } = useClinic();

  useEffect(() => {
    if (id && !id.toString().includes('mbsc')) {
      getEvent(parseInt(id)).then((res: EventData) => {
        setEvent(res);
      });
    }
  }, [id]);

  useEffect(() => {
    getUsersByClinicOnly(ClinicId)
      .then((paginated: PaginatedResponse<UserBasic[]>) => paginated.objects)
      .then((users: UserBasic[]) => {
        const userOptions: OptionType[] = users.map((user: UserBasic) => ({
          label: `${user.first_name} ${user.last_name}`,
          value: user.id.toString(),
        }));
        setDoctors(userOptions);
      });

    getRooms()
      .then((paginated: PaginatedResponse<Room[]>) => paginated.objects)
      .then((rooms: Room[]) => {
        const roomOptions: OptionType[] = rooms.map((room: Room) => ({
          label: room.name,
          value: room.id.toString(),
        }));
        setRooms(roomOptions);
      });

    getTables()
      .then((paginated: PaginatedResponse<Table[]>) => paginated.objects)
      .then((tables: Table[]) => {
        const tableOptions: OptionType[] = tables.map((table: Table) => ({
          label: table.name,
          value: table.id.toString(),
        }));
        setTables(tableOptions);
      });
  }, [ClinicId]);

  const date = event?.date ? new Date(event.date) : eventDate || new Date();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      event: event?.event_type || '',
      date: formatDateToLocalYYYYMMDD(date),
      fromTime: event?.appointment.start_datetime
        ? convertUTCToLocalTimeDate(new Date(event.appointment.start_datetime))
        : '',
      toTime: event?.appointment.end_datetime
        ? convertUTCToLocalTimeDate(new Date(event.appointment.end_datetime))
        : '',
      doctor: event?.appointment.user.toString() || '',
      embryologist: event?.appointment.embryologist.toString() || '',
      operationTheater: event?.appointment.room.toString() || '',
      OTtable: event?.appointment.table.toString() || '',
      remark: event?.appointment.remark || '',
    },
    validationSchema: Yup.object({
      event: Yup.string().required('This field is required'),
      date: Yup.string().required('This field is required'),
      fromTime: Yup.string().required('This field is required'),
      toTime: Yup.string()
        .required('This field is required')
        .test('is-later', 'To Time cannot be less than From Time', function (value) {
          const { fromTime } = this.parent;
          if (!fromTime || !value) return true;
          const parseTime = (time: string) => {
            const [h, m] = time.split(':').map(Number);
            return h * 60 + m;
          };
          return parseTime(value) > parseTime(fromTime);
        }),
      doctor: Yup.string().required('This field is required'),
      embryologist: Yup.string().required('This field is required'),
      operationTheater: Yup.string().required('This field is required'),
      OTtable: Yup.string().required('This field is required'),
      remark: Yup.string().required('This field is required'),
    }),
    onSubmit: async (values) => {
      const start = new Date(values.date);
      start.setHours(parseInt(values.fromTime.split(':')[0]), parseInt(values.fromTime.split(':')[1]));

      const end = new Date(values.date);
      end.setHours(parseInt(values.toTime.split(':')[0]), parseInt(values.toTime.split(':')[1]));

      const stimApp: EventAppointment = {
        user: parseInt(values.doctor),
        appointment_date: formatDateToLocalYYYYMMDD(new Date(values.date)),
        start_datetime: convertDateToISOWithOffset(start),
        end_datetime: convertDateToISOWithOffset(end),
        patient: patient,
        partner: partner,
        room: parseInt(values.operationTheater),
        table: parseInt(values.OTtable),
        status: 'SCHEDULED',
        remark: values.remark,
        embryologist: parseInt(values.embryologist),
        type: 'APPOINTMENT',
      };

      const eventBody: EventCycleRequest = {
        event_type: values.event as 'OPU' | 'ET' | 'LP' | 'FA',
        date: formatDateToLocalYYYYMMDD(new Date(values.date)),
        stim_appointment: stimApp,
        stim_chart: stim,
      };

      if (event && id) {
        const stimApp = {
          user: parseInt(values.doctor),
          appointment_date: formatDateToLocalYYYYMMDD(new Date(values.date)),
          start_datetime: convertDateToISOWithOffset(start),
          end_datetime: convertDateToISOWithOffset(end),
          appointment_reason: null, // Nullable field
          patient: patient || null, // Nullable field
          partner: partner || null, // Nullable field
          room: values.operationTheater ? parseInt(values.operationTheater) : null, // Nullable field
          table: values.OTtable ? parseInt(values.OTtable) : null, // Nullable field
          remark: values.remark || null, // Nullable field
          status: 'SCHEDULED', // Ensure this matches the status Enum
          type: 'APPOINTMENT', // Ensure this matches the type Enum
          department: null, // Nullable field
          embryologist: values.embryologist ? parseInt(values.embryologist) : null, // Nullable field
        };
        
        const eventBody = {
          event_type: values.event as 'OPU' | 'ET' | 'LP' | 'FA', // Matches EventTypeEnum
          date: formatDateToLocalYYYYMMDD(new Date(values.date)),
          stim_appointment: stimApp,
          stim_chart: stim
        };
        updateEvent(eventBody, id)
          .then(() => {
            handleScheduleModalClose();
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            if (error.response?.data?.type === 'validation_error') {
              const serverErrors = error.response.data.errors;
              const formikErrors: Record<string, string> = {};
          
              // Define a mapping between server keys and Formik field names
              const errorKeyMap: Record<string, string> = {
                appointment_date: 'date',
                start_datetime: 'fromTime',
                end_datetime: 'toTime',
                user: 'doctor',
                embryologist: 'embryologist',
                table: 'OTtable',
                room: 'operationTheater',
                remark: 'remark',
              };
          
              serverErrors.forEach((err: { attr: string; detail: string }) => {
                // Extract the key from the server error
                const serverKey = err.attr.split('.')[1];
          
                // Map the server key to the Formik field name if it exists
                const formikField = errorKeyMap[serverKey];
                if (formikField) {
                  formikErrors[formikField] = err.detail;
                }
              });
          
              formik.setErrors(formikErrors);
            }
          });
        
      } else {
        createEvent(eventBody)
          .then(() => {
            handleScheduleModalClose();
          })
          .catch((error) => {
            if (error.response?.data?.type === 'validation_error') {
              const serverErrors = error.response.data.errors;
              const formikErrors: Record<string, string> = {};

              serverErrors.forEach((err: { attr: string; detail: string }) => {
                const formikField = err.attr.split('.')[1];
                if (formikField) formikErrors[formikField] = err.detail;
              });

              formik.setErrors(formikErrors);
            }
          });
      }

      
    },
  });

  const handleDelete = () => {
    if (event && event.id) {
      showConfirmationModal({
        message: 'Are you sure you want to delete this event?',
        onConfirm: () => {
          deleteEvents(event?.id).finally(() =>  handleScheduleModalClose());
        },
        onCancel: () => {
          handleScheduleModalClose();
        },
      });
    }
  };

  return (
    <Modal open={scheduleEventModalOpen} onClose={handleScheduleModalClose}>
      <Box className={styles.modal}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
            Schedule Event
          </Typography>
          <IconButton aria-label="close" onClick={handleScheduleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="Schedule *"
                name="event"
                value={formik.values.event}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                error={formik.touched.event && Boolean(formik.errors.event)}
                helperText={formik.touched.event && formik.errors.event}
              >
                <MenuItem key="opu" value="OPU">
                  OPU
                </MenuItem>
                <MenuItem key="et" value="ET">
                  ET
                </MenuItem>
                <MenuItem key="fa" value="FA">
                  FA
                </MenuItem>
                <MenuItem key="lp" value="LP">
                  LP
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Date *"
                name="date"
                type="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="From Time *"
                name="fromTime"
                type="time"
                value={formik.values.fromTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                error={formik.touched.fromTime && Boolean(formik.errors.fromTime)}
                helperText={formik.touched.fromTime && formik.errors.fromTime}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="To Time *"
                name="toTime"
                type="time"
                value={formik.values.toTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                error={formik.touched.toTime && Boolean(formik.errors.toTime)}
                helperText={formik.touched.toTime && formik.errors.toTime}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={doctors.find((doc) => doc.value === formik.values.doctor) || null}
                options={doctors}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => formik.setFieldValue('doctor', value?.value || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Doctor *"
                    margin="normal"
                    error={formik.touched.doctor && Boolean(formik.errors.doctor)}
                    helperText={formik.touched.doctor && formik.errors.doctor}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={doctors.find((doc) => doc.value === formik.values.embryologist) || null}
                options={doctors}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => formik.setFieldValue('embryologist', value?.value || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Embryologist *"
                    margin="normal"
                    error={formik.touched.embryologist && Boolean(formik.errors.embryologist)}
                    helperText={formik.touched.embryologist && formik.errors.embryologist}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={rooms.find((room) => room.value === formik.values.operationTheater) || null}
                options={rooms}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => formik.setFieldValue('operationTheater', value?.value || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operation Theater *"
                    margin="normal"
                    error={formik.touched.operationTheater && Boolean(formik.errors.operationTheater)}
                    helperText={formik.touched.operationTheater && formik.errors.operationTheater}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={tables.find((table) => table.value === formik.values.OTtable) || null}
                options={tables}
                getOptionLabel={(option) => option.label}
                onChange={(event, value) => formik.setFieldValue('OTtable', value?.value || '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="OT Table *"
                    margin="normal"
                    error={formik.touched.OTtable && Boolean(formik.errors.OTtable)}
                    helperText={formik.touched.OTtable && formik.errors.OTtable}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Remark *"
                name="remark"
                multiline
                rows={3}
                value={formik.values.remark}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="normal"
                error={formik.touched.remark && Boolean(formik.errors.remark)}
                helperText={formik.touched.remark && formik.errors.remark}
              />
            </Grid>
          </Grid>
          <Box className={styles.modalActions}>
            <div className={styles.primary}>
              <Button variant="outlined" onClick={handleScheduleModalClose}>
              Cancel
              </Button>
              <Button type="submit" variant="contained">
              Confirm Scheduled
              </Button>
            </div>
            {event && <Button variant="outlined" color="error" onClick={handleDelete}>
              Delete
            </Button>}
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EventModel;