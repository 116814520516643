import * as yup from 'yup';
import { getRequest, postRequest, putRequest } from '../../../services/httpService';
import { LaparoscopyDetailsEnum } from '../laparoscopy/laparoscopy.helper';
export type BlankEnum = '';
export type NullEnum = 'NULL';
export type ProcedureDoneEnum = 'POLYPECTOMY' | 'SEPTAL_RESECTION' | 'MYOMETOMY' | 'DIAGNOSTIC';
export type CervicalOsEnum = 'EASY' | 'DIFFICULT' | 'NOT_DONE';
export type CavityEnum = 'NORMAL' | 'ADHESIONS' | 'POLYPS' | 'SEPTUM' | 'TUBULAR';
export type EndometriumEnum = 'NORMAL'  | 'POLYPOIDAL'  | 'FLUFFY'  | 'ATROPHIC'  | 'TRIPLE_LAYER'  | 'HOMOGENEOUS'  | 'HYPERECHOIC'  | 'HYPOECHOIC'  | 'THICKENED'  | 'THIN'  | 'IRREGULAR'  | 'CYSTIC'  | 'SECRETORY_PHASE'  | 'PROLIFERATIVE_PHASE'  | 'MENSTRUAL_PHASE'  | 'HYPEREMIA';

export interface HysteroscopyType {
    id?: number | null;
    patient_procedure?: number | null;
    date: string;
    surgeon: number | null;
    assistant_surgeon: number | null;
    anesthetist: number | null;
    procedure_done: ProcedureDoneEnum | BlankEnum | NullEnum | null;
    procedure_duration: number;
    cervical_os: CervicalOsEnum | BlankEnum | NullEnum | null;
    endocervical_conal: string | null;
    uterus_cervical_length: number;
    cavity: CavityEnum | BlankEnum | NullEnum | null;
    endometrium: EndometriumEnum | BlankEnum | NullEnum | null;
    right_ostia: string | null;
    left_ostia: string | null;
    eb_hpe: string | null;
    other_details: string | null;
    remarks: string | null;
    done_by: number | null;
}

export const initialHysteroscopy: HysteroscopyType = {
  date: '',
  surgeon: null,
  assistant_surgeon: null,
  anesthetist: null,
  procedure_done: '',
  procedure_duration: 0,
  cervical_os: '',
  endocervical_conal: null,
  uterus_cervical_length: 0,
  cavity: '',
  endometrium: '',
  right_ostia: null,
  left_ostia: null,
  eb_hpe: null,
  other_details: null,
  remarks: null,
  done_by: null,
};
  
export const HysteroscopyValidationSchema = yup.object({
  date: yup.string().required('Date is required'),

  surgeon: yup
    .number()
    .required('Name of Surgeon is required'),

  assistant_surgeon: yup.number().nullable(),

  anesthetist: yup
    .number()
    .required('Anesthetist is required'),

  procedure_done: yup
    .string()
    .required('Procedure Done is required')
    .oneOf(['POLYPECTOMY', 'SEPTAL_RESECTION', 'MYOMETOMY', 'DIAGNOSTIC'], 'Invalid Procedure Done'),
  
  procedure_duration: yup.number(),

  cervical_os: yup.string(),
  // .required('Cervical Os is required')
  // .oneOf(['EASY', 'DIFFICULT', 'NOT_DONE'], 'Invalid Cervical Os'),

  endocervical_conal: yup
    .string()
    .nullable()
    .max(255, 'Endocervical Conal must be at most 255 characters'),

  uterus_cervical_length: yup.number(),

  cavity: yup
    .string(),
  // .required('Cavity is required')
  // .oneOf(['Normal', 'Adhesions', 'Polyps', 'Septum', 'Tubular'], 'Invalid Cavity'),

  endometrium: yup
    .string(),
  // .required('Endometrium is required')
  // .oneOf(['Normal', 'Polypoidal', 'Fluffy', 'Atrophic', 'Hyperemia'], 'Invalid Endometrium'),

  right_ostia: yup
    .string()
    .nullable()
    .max(255, 'Right Ostia must be at most 255 characters'),

  left_ostia: yup
    .string()
    .nullable()
    .max(255, 'Left Ostia must be at most 255 characters'),

  eb_hpe: yup
    .string()
    .nullable()
    .max(255, 'EB-HPE must be at most 255 characters'),

  other_details: yup
    .string()
    .nullable()
    .max(1000, 'Other Details must be at most 1000 characters'),

  remarks: yup
    .string()
    .nullable()
    .max(1000, 'Remarks must be at most 1000 characters'),

  done_by: yup
    .number()
    .nullable(),
});

export function generateHysteroscopyURL(id: number): string {
  // return `hysteroscopy/?patient_procedure=${id}`;
  return `hysteroscopy/patient-procedure/${id}`;
}
  
// Function to get hysteroscopy
export function getHysteroscopy(id: number): Promise<HysteroscopyType> {
  return  getRequest(generateHysteroscopyURL(id), { responseType: 'json' });
}
  
// Function to update hysteroscopy
export function updateHysteroscopy(id: number, data: HysteroscopyType): Promise<HysteroscopyType> {
  const url = `/hysteroscopy/${id}`;
  return putRequest(url, data, { responseType: 'json' });
}


export const cervicalOsItems: { value: CervicalOsEnum; label: string }[] = [
  { value: 'EASY', label: 'Easy' },
  { value: 'DIFFICULT', label: 'Difficult' },
  { value: 'NOT_DONE', label: 'Not Done' },
];
  
export const procedureDoneItems: { value: ProcedureDoneEnum; label: string }[] = [
  { value: 'POLYPECTOMY', label: 'Polypectomy' },
  { value: 'SEPTAL_RESECTION', label: 'Septal Resection' },
  { value: 'MYOMETOMY', label: 'Myometomy' },
  { value: 'DIAGNOSTIC', label: 'Diagnostic' },
];
  
export const cavityItems: { value: CavityEnum; label: string }[] = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'ADHESIONS', label: 'Adhesions' },
  { value: 'POLYPS', label: 'Polyps' },
  { value: 'SEPTUM', label: 'Septum' },
  { value: 'TUBULAR', label: 'Tubular' },
];


export const endometriumItems: { value: EndometriumEnum; label: string }[] = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'POLYPOIDAL', label: 'Polypoidal' },
  { value: 'FLUFFY', label: 'Fluffy' },
  { value: 'ATROPHIC', label: 'Atrophic' },
  { value: 'TRIPLE_LAYER', label: 'Triple Layer' },
  { value: 'HOMOGENEOUS', label: 'Homogeneous' },
  { value: 'HYPERECHOIC', label: 'Hyperechoic' },
  { value: 'HYPOECHOIC', label: 'Hypoechoic' },
  { value: 'THICKENED', label: 'Thickened' },
  { value: 'THIN', label: 'Thin' },
  { value: 'IRREGULAR', label: 'Irregular' },
  { value: 'CYSTIC', label: 'Cystic' },
  { value: 'SECRETORY_PHASE', label: 'Secretory Phase' },
  { value: 'PROLIFERATIVE_PHASE', label: 'Proliferative Phase' },
  { value: 'MENSTRUAL_PHASE', label: 'Menstrual Phase' },
  { value: 'HYPEREMIA', label: 'Hyperemia' },
];

export function getHysteroscopyList(id: number): Promise<LaparoscopyDetailsEnum> {
  const url = `patient-procedure/?patient=${id}&search=hysteroscopy`;
  return getRequest(url, { responseType: 'json' });
}



export function addHysteroscopy(data: HysteroscopyType): Promise<HysteroscopyType> {
  const url = 'hysteroscopy/';  
  return postRequest(url, data, { responseType: 'json' });
}
