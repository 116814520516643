import * as yup from 'yup'; 
import { getRequest, postRequest, putRequest } from '../../../services/httpService';
import { LaparoscopyDetailsEnum } from '../laparoscopy/laparoscopy.helper';

// Enums for Dropdown values
export type BlankEnum = '';
export type NullEnum = 'NULL';
export type IndicationEnum = 'ANEJACULATION'| 'IMPOTENCE'| 'SECRETORY_AZOOSPERMIA'| 'SEVERE_OLIGOASTHENOTERATOZOOSPERMIA'| 'INCOMPLETE_SPERMATOGENIC_ARREST'| 'CONGENITAL_BILATERAL_ABSENCE_OF_VAS_DEFERNS'| 'FAILED_REVERSAL_OF_VASECTOMY'| 'BLOCKED_VAS_FOLLOWING_GENITAL_TRACT_INFECTIONS'| 'EJACULATORY_DUCT_OBSTRUCTION'| 'BLOCKED_VAS_IN_KARTAGENER_S_SYNDROME'| 'YOUNG_S_SYNDROME';

export type AnesthesiaEnum = 'GENERAL_ANESTHESIA'| 'REGIONAL_ANESTHESIA'| 'LOCAL_ANESTHESIA'| 'MONITORED_ANESTHESIA_CARE_MAC_SEDATION'| 'SPINAL_ANESTHESIA'| 'EPIDURAL_ANESTHESIA';

export type MethodOfSurgicalSpermRetrievalEnum = 'TESA'| 'PESA'| 'MESA'| 'MICRO_TESE'| 'TESE'| 'PESE';

export type SiteEnum = 'RIGHT_TESTIS' | 'LEFT_TESTIS' | 'BOTH';
export type SpecimenTypeEnum = 'TISSUE' | 'ASPIRATE';



// Surgical Sperm Retrieval Interface
export interface SurgicalSpermRetrievalType {
  id?: number | null;
  patient_procedure: number;
  date: string;
  time: string;
  indication: IndicationEnum | BlankEnum | NullEnum;
  clinician: number | null;
  specimen_type: SpecimenTypeEnum | BlankEnum | NullEnum;
  method_of_surgical_sperm_retrieval: MethodOfSurgicalSpermRetrievalEnum | BlankEnum | NullEnum;
  surgeon: number | null;
  anesthetist: number | null;
  anesthesia: AnesthesiaEnum | BlankEnum | NullEnum;
  andrologist: number | null;
  witness_andrologist: number | null;
  site: SiteEnum | BlankEnum | NullEnum;

  left_sperm_count: number | null;
  left_motility: number | null;
  left_remark: string | null;

  right_sperm_count: number | null;
  right_motility: number | null;
  right_remark: string | null;

  remarks: string | null;
  complications: string | null;
  image_path_url: string | null;
}

// Initial State
export const initialSurgicalSpermRetrieval: SurgicalSpermRetrievalType = {
  patient_procedure: 0,
  date: '',
  time: '',
  indication: '',
  clinician: null,
  specimen_type: '',
  method_of_surgical_sperm_retrieval: '',
  surgeon: null,
  anesthetist: null,
  anesthesia: '',
  andrologist: null,
  witness_andrologist: null,
  site: '',
  left_sperm_count: null,
  left_motility: null,
  left_remark: '',
  right_sperm_count: null,
  right_motility: null,
  right_remark: '',
  remarks: '',
  complications: '',
  image_path_url: null
};


// Validation Schema
export const SurgicalSpermRetrievalValidationSchema = yup.object({
  date: yup.string().required('Date is required'),
  time: yup.string().required('Time is required'),
  indication: yup
    .string()
    .required('Indication is required')
    .oneOf(
      [
        'ANEJACULATION',
        'IMPOTENCE',
        'SECRETORY_AZOOSPERMIA',
        'SEVERE_OLIGOASTHENOTERATOZOOSPERMIA',
        'INCOMPLETE_SPERMATOGENIC_ARREST',
        'CONGENITAL_BILATERAL_ABSENCE_OF_VAS_DEFERNS',
        'FAILED_REVERSAL_OF_VASECTOMY',
        'BLOCKED_VAS_FOLLOWING_GENITAL_TRACT_INFECTIONS',
        'EJACULATORY_DUCT_OBSTRUCTION',
        'BLOCKED_VAS_IN_KARTAGENER_S_SYNDROME',
        'YOUNG_S_SYNDROME',
      ],
      'Invalid Indication'
    ),
  clinician: yup.number().nullable(),
  specimen_type: yup
    .string()
    .nullable()
    .oneOf(['TISSUE', 'ASPIRATE', 'UNKNOWN'], 'Invalid Specimen Type'),
  method_of_surgical_sperm_retrieval: yup
    .string()
    .nullable()
    .oneOf(['TESA', 'PESA', 'MESA', 'MICRO_TESE', 'TESE', 'PESE'], 'Invalid Method'),
  surgeon: yup.number().nullable(),
  anesthetist: yup.number().nullable(),
  anesthesia: yup
    .string()
    .nullable()
    .oneOf(
      [
        'GENERAL_ANESTHESIA',
        'REGIONAL_ANESTHESIA',
        'LOCAL_ANESTHESIA',
        'MONITORED_ANESTHESIA_CARE_MAC_SEDATION',
        'SPINAL_ANESTHESIA',
        'EPIDURAL_ANESTHESIA',
      ],
      'Invalid Anesthesia'
    ),
  andrologist: yup.number().nullable(),

  witness_andrologist: yup
    .number()
    .required('Witness Andrologist name is required')
    .test('not-same', 'The witness andrologist cannot be the same as the andrologist.', function (value) {
      const { andrologist } = this.parent; // Accessing the sibling field
      return value !== andrologist; // Validate that they are not the same
    }),

    
  site: yup
    .string()
    .nullable()
    .oneOf(['RIGHT_TESTIS', 'LEFT_TESTIS', 'BOTH'], 'Invalid Site'),

  left_sperm_count: yup.number().nullable(),
  left_motility: yup.number().nullable(),
  left_remark: yup.string().nullable().max(1000, 'Max 1000 characters'),

  right_sperm_count: yup.number().nullable(),
  right_motility: yup.number().nullable(),
  right_remark: yup.string().nullable().max(1000, 'Max 1000 characters'),

  remarks: yup.string().nullable().max(1000, 'Max 1000 characters'),
  complications: yup.string().nullable().max(1000, 'Max 1000 characters'),
});


export function generateSurgicalSpermRetrievalURL(id: number): string {
  return `surgical-sperm-retrieval/patient-procedure/${id}`;
}

export function addSurgicalSpermRetrieval(data: SurgicalSpermRetrievalType): Promise<SurgicalSpermRetrievalType> {
  const url = 'surgical-sperm-retrieval/';
  return postRequest(url, data, { responseType: 'json' });
}


export function getSurgicalSpermRetrieval(id: number): Promise<SurgicalSpermRetrievalType> {
  return getRequest(generateSurgicalSpermRetrievalURL(id), { responseType: 'json' });
}

export function updateSurgicalSpermRetrieval(id: number, data: SurgicalSpermRetrievalType): Promise<SurgicalSpermRetrievalType> {
  const url = `/surgical-sperm-retrieval/${id}`;
  return putRequest(url, data, { responseType: 'json' });
}

// Dropdown Options
export const indicationItems: { value: IndicationEnum; label: string }[] = [
  { value: 'ANEJACULATION', label: 'Anejaculation' },
  { value: 'IMPOTENCE', label: 'Impotence' },
  { value: 'SECRETORY_AZOOSPERMIA', label: 'Secretory Azoospermia' },
  { value: 'SEVERE_OLIGOASTHENOTERATOZOOSPERMIA', label: 'Severe Oligoasthenoteratozoospermia' },
  { value: 'INCOMPLETE_SPERMATOGENIC_ARREST', label: 'Incomplete Spermatogenic Arrest' },
  { value: 'CONGENITAL_BILATERAL_ABSENCE_OF_VAS_DEFERNS', label: 'Congenital Bilateral absence of Vas Defrens' },
];

export const anesthesiaOptions: { value: AnesthesiaEnum; label: string }[] = [
  { value: 'GENERAL_ANESTHESIA', label: 'General Anesthesia' },
  { value: 'REGIONAL_ANESTHESIA', label: 'Regional Anesthesia' },
  { value: 'LOCAL_ANESTHESIA', label: 'Local Anesthesia' },
  { value: 'MONITORED_ANESTHESIA_CARE_MAC_SEDATION', label: 'Monitored Anesthesia Care (MAC)/Sedation' },
  { value: 'SPINAL_ANESTHESIA', label: 'Spinal Anesthesia' },
  { value: 'EPIDURAL_ANESTHESIA', label: 'Epidural Anesthesia' },
];

export const specimenTypeItems: { value: SpecimenTypeEnum; label: string }[] = [
  { value: 'TISSUE', label: 'Tissue' },
  { value: 'ASPIRATE', label: 'Aspirate' },
];
  

export const methodOfSurgicalSpermRetrievalItems: { value: MethodOfSurgicalSpermRetrievalEnum; label: string }[] = [
  { value: 'TESA', label: 'TESA' },
  { value: 'PESA', label: 'PESA' },
  { value: 'MESA', label: 'MESA' },
  { value: 'MICRO_TESE', label: 'Micro TESE' },
  { value: 'TESE', label: 'TESE' },
  { value: 'PESE', label: 'PESE' },
];
  
export const siteItems: { value: SiteEnum; label: string }[] = [
  { value: 'RIGHT_TESTIS', label: 'Right Testis' },
  { value: 'LEFT_TESTIS', label: 'Left Testis' },
  { value: 'BOTH', label: 'Both' },
];
  
export function getSurgicalSpermRetrievalList(id: number): Promise<LaparoscopyDetailsEnum> {
  const url = `patient-procedure/?patient=${id}&search=Surgical Sperm Retrieval`;
  return getRequest(url, { responseType: 'json' });
}




// Define a constant for roles to avoid magic strings
export const ROLES = ['DOCTOR', 'ANESTHETIST', 'ANDROLOGIST'] as const;

export interface Clinician {
  id: number; 
  first_name: string;
  last_name: string;
}

// Create a type that represents the role strings
export type Role = typeof ROLES[number]; 

