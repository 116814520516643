/* eslint-disable @typescript-eslint/no-explicit-any */
import { getRequest } from '../../services/httpService';

export interface Meta {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  }
  
export interface Partner {
    id: number;
    age: number;
    created_on: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    gender__name: string;
    medical_record_no: string;
    mobile_number: string;
    clinic: number;
    alternate_email?: string;
  }
  
export interface Patient {
    id: number;
    age: number;
    created_on: string;
    first_name: string;
    last_name: string;
    middle_name: string | null;
    date_of_birth: string;
    gender__name: string;
    medical_record_no: string;
    mobile_number: string;
    mobile_number_country_code: string;
    partner: Partner | null;
    reason_for_visit: string | null;
    patient_type: string;
  }
  
export interface PatientRecord {
    patient: Patient;
    doctor_name: string;
    service: string;
    status: boolean;
  }
  
export interface PatientData {
    meta: Meta;
    objects: PatientRecord[];
  }
export interface TableRowData {
   patient_id: number;
    id: number;
    patientName: string;
    MRN: string;
    age: string;
    gender: string;
    date: string;
    mobileNumber: string;
    reasonOfVisit: string;
    partnerName: string;
    partnerMRN: string;
    partnerage: string;
    partnergender: string;
    doctor: string;
    firstvisit: string;
    status: JSX.Element;  
  }
  
export function getAndrologyUrl({
  search = '',
  startDate = '',
  endDate = '',
  selectedValue = '',
  itemsPerPage = 10,
  currentPage = 0,
  ClinicId = ''
}: {
    search?: string;
    startDate?: string;
    endDate?: string;
    selectedValue?: string;
    itemsPerPage?: number;
    currentPage?: number;
    ClinicId?: string;
  }): string {
  let queryString = `/patient-procedure/andrology/?limit=${itemsPerPage}&offset=${currentPage}`;
  if (search) queryString += `&name_search=${search}`;
  if (startDate) queryString += `&date_after=${startDate}`;
  if (endDate) queryString += `&date_before=${endDate}`;
  if (selectedValue) queryString += `&patient__patient_type__icontains=${selectedValue}`;
  if (ClinicId) queryString += `&clinic=${ClinicId}`;
  
  return queryString;
}

export function getAndrology({
  search = '',
  startDate = '',
  endDate = '',
  selectedValue = '',
  itemsPerPage = 10,
  currentPage = 0,
  ClinicId = ''
}: {
    search?: string;
    startDate?: string;
    endDate?: string;
    selectedValue?: string;
    itemsPerPage?: number;
    currentPage?: number;
    ClinicId?: string;
  }): Promise<any> {
  const url = getAndrologyUrl({
    search,
    startDate,
    endDate,
    selectedValue,
    itemsPerPage,
    currentPage,
    ClinicId
  });
  return getRequest(url);
}
  

export const mapPatientData = (fetchedData: any[]): PatientData[] => {
  return fetchedData.map(item => {
    const { patient, doctor_name, status } = item;
    const partner = patient.partner ? {
      id: patient.partner.id,
      age: patient.partner.age,
      created_on: patient.partner.created_on || '',
      first_name: patient.partner.first_name || '',
      last_name: patient.partner.last_name || '',
      date_of_birth: patient.partner.date_of_birth || '',
      gender__name: patient.partner.gender__name || '',
      medical_record_no: patient.partner.medical_record_no || '',
      mobile_number: patient.partner.mobile_number || '',
      clinic: patient.partner.clinic || 0,
      alternate_email: patient.partner.alternate_email || undefined
    } : null;
    const patientRecord: PatientRecord = {
      patient: {
        id: patient.id || 0,
        age: patient.age || 0,
        created_on: patient.created_on || '',
        first_name: patient.first_name || '',
        last_name: patient.last_name || '',
        middle_name: patient.middle_name || null,
        date_of_birth: patient.date_of_birth || '',
        gender__name: patient.gender__name || '',
        medical_record_no: patient.medical_record_no || '',
        mobile_number: patient.mobile_number || '',
        mobile_number_country_code: patient.mobile_number_country_code || '',
        partner: partner, 
        reason_for_visit: patient.reason_for_visit || null,
        patient_type: patient.patient_type || ''
      },
      doctor_name: doctor_name || '',
      service: item.service || '',
      status: status || false
    };
  
    return { 
      meta: { limit: 100, next: null, offset: 0, previous: null, total_count: 10 },
      objects: [patientRecord]
    };
  });
};

  
