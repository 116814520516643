import React, { useEffect, useState } from 'react';
import { ThemeProvider, Box, Typography } from '@mui/material';
import { theme } from '../../Medical_History_Forms/themeprovider';
import Grid from '@mui/material/Grid2';
import { convertUnderscoreToTitle, infoTextStyle, sectionTitleStyle } from '../../../utils/commonUtils';
import { useLoader } from '../../loaderProvider/loader';
import styles from '../oocyteRetrieval/oocyteRetrival.module.css';
import { getHysteroscopy } from './hysteroscopy.helper';

interface LaparoscopyViewDetailsProps {
  record: number; 
  onBack: () => void;
  onEdit?: () => void; 
}

const HysteroscopyViewDetails: React.FC<LaparoscopyViewDetailsProps> = ({ record, onBack, onEdit }) => {
  const { showLoader, hideLoader } = useLoader();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [retrievalData, setRetrievalData] = useState<any>(null);

  useEffect(() => {
    if (!record) return;

    showLoader();
    getHysteroscopy(record)
      .then((data) => setRetrievalData(data))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching Oocyte Retrieval data');
        setRetrievalData([]); // Set to default on error
      })
      .finally(() => hideLoader());
  }, [record, showLoader, hideLoader]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={4} mb={3}>
           
          {/* <Box sx={{ width: '100%'}}>
            <Typography variant="h6" sx={{ ...infoTextStyle }}>
          Details
            </Typography>
          </Box> */}

          <Grid size={12} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Date</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.date  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Name of Surgeon</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.surgeon_name  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Assistant Surgeon</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.assistant_surgeon_name  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Anesthetist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.anesthetist_name  || '-'} 
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Procedure Done</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.procedure_done) || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Duration of Procedure</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.procedure_duration || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Cervical Os</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.cervical_os) || '-'}
            </Typography>
          </Grid>
          
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Endocervical Conal</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.endocervical_conal) || '-'}
            </Typography>
          </Grid>


          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Uterus Cervical Length (mm)</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.uterus_cervical_length  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Cavity</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.cavity)  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Endometrium</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.endometrium)  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Right Ostia</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.right_ostia  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Left Ostia</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.left_ostia  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>EB-HPE</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.eb_hpe  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Other Details</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.other_details  || '-'} 
            </Typography>
          </Grid>


          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Remark</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.remarks  || '-'} 
            </Typography>
          </Grid>

          
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Done By</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.done_by_name  || '-'} 
            </Typography>
          </Grid>
        </Grid>


        <Box className={styles.customButtonDiv}>
          <button
            type="button"
            className={styles.resetButton}
            onClick={onBack}
          >
    Back to List
          </button>

          <button
            type="button"
            className={styles.customButton}
            onClick={onEdit}
          >
            <span style={{ fontWeight: 'bold' }}>
      Edit
            </span>
          </button>

        </Box>

      </Box>
    </ThemeProvider>
  );
};

export default HysteroscopyViewDetails;
