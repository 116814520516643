/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Styles from './Stimpage.module.css';
import { StimulationSchedular } from '../../components/stimSchedular';
import HeaderProfile from '../../components/header_Profile/header_Profile.index';
import { usePatientContext } from '../../components/SubPatinetProvider';
import { PatientData } from '../../types';
import CycleSelector from './cycleSelector.stim';
import { OptionType } from './helper.stim';
import {ReactComponent as CycleSVG} from '../../assets/SVG/In vitro fertilization-bro 1.svg';
import { Typography } from '@mui/material';

const StimPage: React.FC = () => {

  const [selectedPatient, setselectedPatient] = useState<PatientData | null>(null);
  const [selectedStimChart, setSelectedStimChart] = useState<number | null>(null);
  const {patientId} = usePatientContext();

  const handleDatafromHeader = (data: PatientData) => {
    setselectedPatient(data);
  };

  const handleChange = (event: React.SyntheticEvent | null, value: number | null) => {
    setSelectedStimChart(value);
  };


  return (
    <div className={Styles.stimpage}>
      <div className={Styles.header}>
        <HeaderProfile onData={handleDatafromHeader}/>
      </div>
      <div className={Styles.cycleSelector}>
        {selectedPatient && <CycleSelector patient={patientId} onCycleChange={handleChange}/>}
      </div>
      <div className={Styles.scheduler}>
        {selectedStimChart ? <StimulationSchedular id={selectedStimChart} height={'75vh'}/>: <div className={Styles.logo}><CycleSVG/><Typography variant='h6'>No stim cycle found.</Typography></div>}
      </div>
    </div>
  );
};

export default StimPage;