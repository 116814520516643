import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import StatusChip from '../../customTableComponent/statusChip';

import EditButton from '../../../assets/SVG/editPencil.svg';
import viewIcon from '../../../assets/SVG/eye_view.svg';
import PrescribedUnavailable from '../prescribedUnavailable';
import { getSurgicalSpermRetrievalList } from './spermRetrieval.helper';
import { usePatientContext } from '../../SubPatinetProvider';

const SurgicalSpermRetrievalList = ({
  onEdit,
  onView,
}: {
  onEdit: (id: number) => void;
  onView: (id: number) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [surgicalSpermRetrievalDetails, setSurgicalSpermRetrievalDetails] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { patientId } = usePatientContext();

  useEffect(() => {
    // Fetch surgicalSpermRetrieval details
    getSurgicalSpermRetrievalList(patientId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setSurgicalSpermRetrievalDetails(response.objects);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching surgicalSpermRetrieval details:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [patientId]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <CircularProgress />
      </div>
    );
  }


  if (!surgicalSpermRetrievalDetails.length) {
    return <PrescribedUnavailable />; // Show PrescribedUnavailable when no data is available
  }
  
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              height: '2rem',
              background: 'rgba(250, 250, 250, 1)',
              '& .MuiTableCell-root': {
                padding: '0.6rem 0.6rem',
              },
            }}
          >
            {['Date of Procedure', 'Procedure ID', 'Surgeon Name', 'Status', ''].map(
              (title, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.45',
                    textAlign: 'left',
                    color: 'rgba(35, 35, 35, 1)',
                  }}
                >
                  {title}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {surgicalSpermRetrievalDetails.map((surgicalSpermRetrieval) => (
            <TableRow key={surgicalSpermRetrieval.id}>
              <TableCell>
                {new Date(surgicalSpermRetrieval.created_on).toLocaleDateString('en-US')}
              </TableCell>
              <TableCell>{surgicalSpermRetrieval?.services[0]?.code}</TableCell>
              <TableCell>{surgicalSpermRetrieval?.doctor_name}</TableCell>
              <TableCell>
                <StatusChip
                  value={surgicalSpermRetrieval?.sperm_retrieval_status ? 'Completed' : 'Pending'}
                />
              </TableCell>

              <TableCell>
                {surgicalSpermRetrieval?.sperm_retrieval_status ? (
                  <Tooltip title="View Details">
                    <img 
                      src={viewIcon} 
                      style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                      alt="View Icon" 
                      onClick={() => onView(surgicalSpermRetrieval.id)} 
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Details">
                    <img 
                      src={EditButton} 
                      style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                      alt="Edit Icon" 
                      onClick={() => onEdit(surgicalSpermRetrieval.id)} 
                    />
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SurgicalSpermRetrievalList;
