import { getRequest, putRequest } from '../../../services/httpService';




export interface Donor {
    id: number;
    first_name: string;
    medical_record_no?:string;
    last_name: string;
    profile_url?:string;
    mobile_number: string;
    eye_color?: string;
    hair_color_as_adult?: string;
    skin_tone?: string;
    blood_group?: string;
    mobile_number_country_code?: string;
}


export function getLinkOocyteDonorUrl( patientTypeIcontains: string): string {
  return `/patients/?patient_type__icontains=${patientTypeIcontains}`;
}
  
  
  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLinkOocyteDonor(patientTypeIcontains: string): Promise<any> {
  return getRequest(getLinkOocyteDonorUrl(patientTypeIcontains), { responseType: 'json' });
}
  
interface donorIds {
  donor_ids: number[]; // Assuming an array of donor IDs (adjust according to the API)
  }
  
// URL generation for linking donors
export function LinkDonorURL(patientId: number): string {
  return `/patients/${patientId}/update-donors/`;
}
  
export function putLinkOocyteDonor(patientId: number, donorIds: donorIds): Promise<number[]> {
  return putRequest(LinkDonorURL(patientId), donorIds);
}