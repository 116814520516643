/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Grid2 from '@mui/material/Grid2'; 
import IconButton from '@mui/material/IconButton';
import { ReactComponent as More } from '../../../assets/SVG/close-circle-greay.svg';
import { RequiredLabel } from '../../../utils/commonUtils';
import styles from './embryoCulture.module.css';
import { Autocomplete, MenuItem, ThemeProvider } from '@mui/material';
import { theme } from '../../../components/Medical_History_Forms/themeprovider';
import { useFormik } from 'formik';
import { cryoPurposeItems, FreezeDialogValues, freezeValidationSchema, initialValuesCryo } from './embryoCulture.helpers';
import { getUsersByClinicId } from '../../../services/apiService';
import { useClinic } from '../../../components/ClinicProvider';
import { mediaItems } from '../Occyte-Grading/oocyteGrading.helper';

interface FreezeActionDialogProps {
  open: boolean;
  onClose: () => void;
  values: FreezeDialogValues;
  onSave: (formValues: FreezeDialogValues) => void;
  selectedEmbryos: number[];
}

// Define a constant for roles to avoid magic strings
const ROLES = ['DOCTOR', 'EMBRYOLOGIST', 'NURSE'] as const;
type Role = typeof ROLES[number];
interface Clinician {
  id: number; 
  first_name: string;
  last_name: string;
  role: Role;  
}

const FreezeActionDialog: React.FC<FreezeActionDialogProps> = ({ open, onClose, values, onSave, selectedEmbryos }) => {
    
  const [freezeValues, setFreezeValues] = useState<FreezeDialogValues>(values);
  const formik = useFormik({
    initialValues: freezeValues,    
    validationSchema: freezeValidationSchema,
    onSubmit: (formValues) => {
      console.log('Saved Values:', formValues);
      onSave(formValues);
    },
    enableReinitialize: true,
  });
  
  useEffect(() => {
    console.log('selectedEmbryos', selectedEmbryos);
  }, [selectedEmbryos]);

  const { ClinicId } = useClinic();
   
  const [clinicianLists, setClinicianLists] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });

  const fetchCliniciansByRole = useCallback((role: Role) => {
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {        
        setClinicianLists(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
      });
  }, [ClinicId]); 
    

  useEffect(() => {
    if (open) {
      ROLES.forEach(fetchCliniciansByRole); 
      setFreezeValues(initialValuesCryo);
    }
  }, [open, fetchCliniciansByRole]);
  


  return (    
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose}     
        fullWidth maxWidth='lg'
        PaperProps={{  sx: {  borderRadius: '12px', width: '55%',  minWidth: '700px',},  }}>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '1px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>Action
              <span style={{ display: 'flex', gap: '8px', marginTop: '5px' }}>
                {selectedEmbryos.map((num) => (
                  <span
                    key={num}
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: '#47B35F',
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      fontWeight: 400,
                    }}
                  >
                    {num}
                  </span>
                ))}
              </span>
            </div>

            <IconButton
              edge='end'
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <More />
            </IconButton>
          </div>
        </DialogTitle>


        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <TextField
                  name="cryo_preserved_date"
                  type="date"
                  label={RequiredLabel('Cryo Preserved Date', false)}
                  fullWidth
                  value={formik.values.cryo_preserved_date || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.cryo_preserved_date && Boolean(formik.errors.cryo_preserved_date)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid2>
              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <Autocomplete
                  fullWidth
                  size="small"
                  id="cryo_preserved_by"
                  options={clinicianLists['DOCTOR'] || []}
                  getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                  value={
                    clinicianLists['DOCTOR'].find(
                      (doctor) => doctor.id === formik.values.cryo_preserved_by // Use formik.values directly here
                    ) || null
                  }
                  onChange={(_, value) => {
                    // Manually update Formik's field value with the selected doctor's id
                    formik.setFieldValue('cryo_preserved_by', value?.id || '');  // Update with the clinician's ID
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cryo Preserved By"
                      error={formik.touched.cryo_preserved_by && Boolean(formik.errors.cryo_preserved_by)}
                    />
                  )}
                />
              </Grid2>

                     
              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <Autocomplete
                  fullWidth
                  size="small"
                  id="cryo_purpose"
                  options={cryoPurposeItems}  
                  getOptionLabel={(option) => option.label}
                  
                  value={cryoPurposeItems.find(option => option.value === formik.values.cryo_purpose) || null}
                  onChange={(_, value) => {
                    formik.setFieldValue('cryo_purpose', value?.value || '');  // Update with the clinician's ID
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cryo Purpose"
                      error={formik.touched.cryo_purpose && Boolean(formik.errors.cryo_purpose)}
                    />
                  )}
                />
              </Grid2> 

              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <Autocomplete
                  fullWidth
                  size="small"
                  id="cryo_media"
                  options={mediaItems}  
                  getOptionLabel={(option) => option.label}
                  
                  value={mediaItems.find(option => option.value === formik.values.cryo_media) || null}
                  onChange={(_, value) => {
                    formik.setFieldValue('cryo_media', value?.value || '');  
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Media"
                      error={formik.touched.cryo_media && Boolean(formik.errors.cryo_media)}
                    />
                  )}
                />
              </Grid2> 

              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  id="is_screened"
                  label="Screening Status"
                  value={formik.values.is_screened === true ? true : formik.values.is_screened === false ? false : ''}
                  onChange={(e) => {
                    formik.setFieldValue('is_screened', e.target.value === 'true' ? true : false);
                  }}
                  error={formik.touched.is_screened && Boolean(formik.errors.is_screened)}
                  helperText={formik.touched.is_screened && formik.errors.is_screened}
                >
                  <MenuItem value="true">Yes</MenuItem> 
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Grid2>
              
            </Grid2>
          </DialogContent>
          <DialogActions>
            <button type="button" onClick={onClose} className={styles.resetButton}>
              Cancel
            </button>
            <button type="submit" className={`${styles.customButton}`}>
              <strong>Save</strong>
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default FreezeActionDialog; 

