import React, { useState } from 'react';
import styles from '../FilterComponent/FilterComponent.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactComponent as More } from '../../../assets/SVG/close-circle-greay.svg';
import { FilterPopupProps } from '../../../types';
import { useAlert } from '../../alertProvider';


export default function FilterPopup({ onClose }: FilterPopupProps) {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const {addAlert} = useAlert();

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (toDate < fromDate) {
      addAlert('To Date should be greater than From Date', 'error');
      return;
    }
    setToDate(e.target.value);
  };

  return (
    <div className={styles.filterPopupContent}>
      <div className={styles.filterRow}>
        <div className={styles.filterName}>Filter</div>
        <More className={styles.closeBtn} onClick={onClose} />
      </div>
      <hr />
      <div className="row" style={{ marginTop: '20px' }}>
        <div className={`col-md-6 ${styles.dateInput}`}>
          <label htmlFor="" className={styles.labelText}>From Date</label>
          <input
            type="date"
            className={styles.inputPointer}
            value={fromDate}
            onChange={handleFromDateChange}
          />
        </div>
        <div className={`col-md-6 ${styles.dateInput}`}>
          <label htmlFor="" className={styles.labelText}>To Date</label>
          <input
            type="date"
            className={styles.inputPointer}
            value={toDate}
            onChange={handleToDateChange}
          />
        </div>
        <div className={'col-md-6'} style={{ marginTop: '10px' }}>
          <button className={styles.clear}>Clear</button>
        </div>
        <div className={'col-md-6'} style={{ marginTop: '10px' }}>
          <button className={styles.apply}>Apply</button>
        </div>
      </div>
    </div>
  );
}
