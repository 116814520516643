/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './Modals.module.css';
import { Drug, getDrugs, OptionType } from './helper.stim';
import { PaginatedResponse } from '../../types';

interface AddDrugModalProps {
  drugModalOpen: boolean;
  handleDrugModalClose: () => void;
  handleSubmitClose: (id: string, name: string, type: 'Stim' | 'Trigger') => void;
  type: 'Stim' | 'Trigger';
}

const CreateDrugModal: React.FC<AddDrugModalProps> = ({
  drugModalOpen,
  handleDrugModalClose,
  type,
  handleSubmitClose,
}) => {
  const [meds, setMeds] = useState<OptionType[]>([]);
  const [selectedDrug, setSelectedDrug] = useState<OptionType | null>(null);

  useEffect(() => {
    getDrugs(type)
      .then((res: PaginatedResponse<Drug[]>) => res.objects)
      .then((drugs: Drug[]) => {
        const drugOptions: OptionType[] = drugs.map((drug: Drug) => ({
          label: `${drug.name} ${drug.brand_name ? ' - ' + drug.brand_name : ''}`,
          value: drug.id.toString(),
        }));
        setMeds(drugOptions);
      });
  }, [type]);

  // Formik validation schema
  const validationSchema = Yup.object({
    drugName: Yup.string().required('Drug Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      drugName: '',
    },
    validationSchema,
    onSubmit: () => {
      if (selectedDrug) {
        handleSubmitClose(selectedDrug.value, selectedDrug.label, type);
        handleDrugModalClose(); // Close the modal after submission
      }
    },
  });

  return (
    <Modal open={drugModalOpen} onClose={handleDrugModalClose}>
      <Box className={styles.modal}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
            {`Add ${type.toLowerCase()}`}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} className={styles.modalBody}>
          <Autocomplete
            options={meds}
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => {
              setSelectedDrug(value);
              formik.setFieldValue('drugName', value?.label || '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Drug Name *"
                name="drugName"
                error={formik.touched.drugName && Boolean(formik.errors.drugName)}
                helperText={formik.touched.drugName && formik.errors.drugName}
                margin="normal"
              />
            )}
          />
          <Box className={styles.primary} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleDrugModalClose}
              className={styles.modalCancelBtn}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              className={styles.modalSaveBtn}
              disabled={!selectedDrug} // Disable button if no drug is selected
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateDrugModal;