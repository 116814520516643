import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { MenuItem, TextField, Button, Link } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import StatusChip from '../customTableComponent/statusChip';
import { CycleData, getARTCycleList } from '../Surgery/oocyteRetrieval/OocyteRetrival.helper';
import { usePatientContext } from '../SubPatinetProvider';
import styles from '../Surgery/oocyteRetrieval/oocyteRetrival.module.css';
import { useNavigate } from 'react-router-dom';

interface CyclePickerProps {
  onCycleChange: (selectedCycle: CycleData | null) => void;
  onCycleAvailabilityChange?: (isAvailable: boolean) => void; 
}

const CyclePicker: React.FC<CyclePickerProps> = ({ onCycleChange, onCycleAvailabilityChange }) => {
  const [cycleList, setCycleList] = useState<CycleData[]>([]);
  const [selectedCycle, setSelectedCycle] = useState<CycleData | null>(null);
  const { patientId } = usePatientContext();
  const navigate = useNavigate();

  // Fetch cycle list only when patientId changes
  useEffect(() => {
    if (patientId) {
      getARTCycleList(patientId)
        .then((data) => {
          setCycleList(data);
          if (data.length > 0) {
            const initialCycle = data[0];
            setSelectedCycle(initialCycle);
            onCycleChange(initialCycle);
            onCycleAvailabilityChange?.(true); // Notify parent about cycle availability
          } else {
            setSelectedCycle(null);
            onCycleChange(null); // Notify parent about no cycles
            onCycleAvailabilityChange?.(false);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching cycle list:', error);
          setCycleList([]);
          setSelectedCycle(null);
          onCycleChange(null);
          onCycleAvailabilityChange?.(false);
        });
    }
  }, [patientId, onCycleChange, onCycleAvailabilityChange]);

  // Handle cycle selection
  const handleCycleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selected = cycleList.find((cycle) => cycle.id === Number(event.target.value)) || null;
      setSelectedCycle(selected);
      onCycleChange(selected);
    },
    [cycleList, onCycleChange]
  );

  // Navigate to cycle overview
  const navigateToCycleOverview = useCallback(() => {
    if (patientId && selectedCycle) {
      navigate(`/clinical/patients/cycle-overview/${patientId}`);
    }
  }, [navigate, patientId, selectedCycle]);

  // Memoize options to avoid recalculations
  const cycleListOptions = useMemo(
    () =>
      cycleList.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          <span>{item.cycle_number}</span>&nbsp;|&nbsp;
          <StatusChip
            value={item.status === 'TERMINATED' ? 'Inactive' : item.status === 'ACTIVE' ? 'Active' : item.status}
            height="22px"
          />
        </MenuItem>
      )),
    [cycleList]
  );

  return (
    <Grid2 container spacing={2} sx={{ marginTop: 1 }}>
      {/* Cycle Selector */}
      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4 }} >
        <TextField
          fullWidth
          size="small"
          label="Cycle No."
          name="cycle"
          select
          value={selectedCycle ? selectedCycle.id : ''}
          onChange={handleCycleChange}
          variant="outlined"
        >
          {cycleListOptions}
        </TextField>
      </Grid2>

      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4 }} sx={{ textAlign: 'left' }}>
        {selectedCycle ? (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigateToCycleOverview();
            }}
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '23.2px',
              textAlign: 'left',
              marginLeft: 2,
            }}
          >
      Cycle Overview
          </Link>
        ) : (
          <span style={{ display: 'none' }} /> 
        )}
      </Grid2>


      {/* Terminate/Create Cycle Button */}
      <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4 }} sx={{ textAlign: 'right' }}>
        {selectedCycle ? (
          selectedCycle.status !== 'TERMINATED' ? (
            <Button variant="outlined" color="error" onClick={navigateToCycleOverview}>
        Terminate Cycle
            </Button>
          ) : (
            <button className={styles.terminateButton} onClick={navigateToCycleOverview}>
        Create New Cycle
            </button>
          )
        ) : (
          <button className={styles.terminateButton} onClick={navigateToCycleOverview}>
      Create New Cycle
          </button>
        )}
      </Grid2>

    </Grid2>
  );
};

export default CyclePicker;
