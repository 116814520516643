import { getRequest } from '../../services/httpService';


export interface Cycle {
  id: string;
  cycle_number: string;
  cycle_name: string;
  status: string;
  lmp_date: string | null;
  medication_protocol: string | null;
  procedure: {
    indication: string | null;
    procedures: string | null;
    donor_info: {
      sperm_source: Array<{
        first_name: string;
        last_name: string;
        medical_record_no: string;
        photo_url: string | null;
      }>;
      oocyte_source: Array<{
        first_name: string;
        last_name: string;
        medical_record_no: string;
        photo_url: string | null;
      }>;
    };
  };
  follicules: number | null;
  opu: string | null;
  opu_status:boolean | null;
  oocyte_count: number | null;
  oocyte_details: number | null;
  fertilized_count: number | null;
  cleaved_count: number | null;
  cryopreserved_count: number | null;
  fresh_transfer_count: number | null;
  frozen_transfer_count: number | null;
  bhcg_upt: string | null;
  pregnancy_confirmation: string | null;
  remaining_embryos: number | null;
  remarks: string | null;
}

export function getcycleListUrl(patientId: string): string {
  return `/cycle/patient/${patientId}/`; 
}

export function getcycleList(patientId: string): Promise<Cycle[]> {
  return getRequest(getcycleListUrl(patientId), { responseType: 'json' });
}
