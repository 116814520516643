/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import CryopreservedComponent from './CryopreservedComponent';
import ThawedComponent from './ThawedComponent';
import LogsComponent from './LogsComponent';
import styles from './CryoThawingComponent.module.css';

interface OocyteComponentProps {
  label: string; 
}
const OocyteComponent: React.FC<OocyteComponentProps> = ({ label }) => {
  const [activeTab, setActiveTab] = useState('Cryopreserved');
  const [storedLabel, setStoredLabel] = useState(label);

  const renderContent = () => {
    switch (activeTab) {
    case 'Cryopreserved':
      return <CryopreservedComponent label={storedLabel}/>;
    case 'Thawed':
      return <ThawedComponent label={storedLabel}/>;
    case 'Logs':
      return <LogsComponent label={storedLabel}/>;
    default:
      return null;
    }
  };

  return (
    <div className={styles.oocyteContainer}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'Cryopreserved' ? styles.active : ''}`}
          onClick={() => setActiveTab('Cryopreserved')}
        >
          Cryopreserved
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'Thawed' ? styles.active : ''}`}
          onClick={() => setActiveTab('Thawed')}
        >
          Thawed
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'Logs' ? styles.active : ''}`}
          onClick={() => setActiveTab('Logs')}
        >
          Logs
        </button>
      </div>
      <div className={styles.tabContent} style={{ marginTop: '4rem' }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default OocyteComponent;
