import React, { useEffect } from 'react';
import styles from './login.module.css';
import login from '../../assets/SVG/SpinnerWithSpermAndLines.svg';
import logo from '../../assets/SVG/VIDAI_NEW_LOGO.svg';
import LoginFormComponent from '../../components/loginFormComponent';
import { useLocation, useNavigate } from 'react-router-dom';

export const RedirectLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const access = params.get('access');

    if (access) {
      localStorage.setItem('token', access);
      navigate('/admin/appointments');
    }
    else {
      navigate('/login');
    }
  }, [location, navigate]);
  return null;
};
export default function Login() {

  return (
    <div className={styles.login}>
      <div className={styles.banner}>
        <img src={login} alt='login' />
        <div className={styles.bannertext}>
          Unlock the potential of the <span className={`${styles.AIPowerCss}`}>AI-Powered EMR</span> to enhance your experience & clinical outcome
        </div>
      </div>
      <div className={styles.panel}>
        <div className={styles.logo}>
          <img src={logo} alt='login' />
        </div>
        <div className={styles.form}>
          <LoginFormComponent successroute='/dashboard' />
        </div>
      </div>
    </div>
  );
}