import { getRequest } from '../../../services/httpService';



interface OptimumFollicles {
    left_follicles: number;
    right_follicles: number;
  }
  
export interface OocyteRetrievalData {
    id: number;
    cycle: number;
    opu_date: string;
    start_time: string;
    end_time: string;
    clinician: number;
    embryologist: number;
    witness_embryologist: number;
    anesthetist: number;
    nurse: number;
    type_of_anesthesia: string;
    type_of_needle: string;
    sub_type_of_needle: string;
    difficulty_level: string;
    procedure_findings: string;
    notes: string;
    optimum_follicles: OptimumFollicles;
    oocytes_retrieved_left: number;
    oocytes_retrieved_right: number;
    tube_id: string;
    no_of_needles_used: number;
    pre_anesthesia_checkup: boolean;
    nurse_name: string;
    anesthetist_name: string;
    witness_embryologist_name: string;
    embryologist_name: string;
    clinician_name: string;
    cycle_start_date: string;
  }




export function getOocyteRetrievalUrl(Id: string): string {
  return `/opu/cycle/${Id}/`; 
}
export function getOocyteRetrieval(Id: string): Promise<OocyteRetrievalData> {
  return getRequest(getOocyteRetrievalUrl(Id), { responseType: 'json' });
}
