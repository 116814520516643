import React from 'react';
import CryoThawing from '../CryoThawing/CryoThawing';



export const ExampleForm = () => {


  return (
    <div><CryoThawing/></div>
  );
};

export default ExampleForm;