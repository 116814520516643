/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { navigateTo } from './nevigationService';
import { getAdminConsoleToken } from './apiService';


// Create a base Axios instance
const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 300000, // Default timeout
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer your_token',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Replace with actual token retrieval method

    config.baseURL = process.env.REACT_APP_API_URL; // Fallback to default base URL

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      navigateTo('/login', {state: {from: window.location.pathname}});
    }

    return config;
  },
  (error) => {
    navigateTo('/login', {state: {from: window.location.pathname}});
    return Promise.reject(error);
  }
);

// Example of how to change the tenant URL
export function setTenantUrl(newUrl: string) {
  localStorage.setItem('tenantBaseUrl', newUrl);
}

// Generic GET method
export const getRequest = async <T>(
  url: string,
  config?: AxiosRequestConfig,
  responseType: AxiosRequestConfig['responseType'] = 'json'
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.get(url, {
      ...config,
      responseType,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

// Generic POST method
export const postRequest = async <T>(
  url: string,
  data: unknown,
  config?: AxiosRequestConfig,
  responseType: AxiosRequestConfig['responseType'] = 'json'
): Promise<T> => {

  try {
    const response: AxiosResponse<T> = await axiosInstance.post(url, data, {

      ...config?.headers, // Allow overriding/adding custom headers
      ...config,
      responseType,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

// Generic PUT method
export const putRequest = async <T>(
  url: string,
  data: unknown,
  config?: AxiosRequestConfig,
  responseType: AxiosRequestConfig['responseType'] = 'json'
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.put(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json', // Ensure content type is set
        ...config?.headers,
      },
      ...config,
      responseType,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

// Generic DELETE method
export const deleteRequest = async <T>(
  url: string,
  config?: AxiosRequestConfig,
  responseType: AxiosRequestConfig['responseType'] = 'json'
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.delete(url, {
      ...config,
      responseType,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

// Generic PATCH method
export const patchRequest = async <T>(
  url: string,
  data: unknown,
  config?: AxiosRequestConfig,
  responseType: AxiosRequestConfig['responseType'] = 'json'
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.patch(url, data, {
      ...config,
      responseType,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
// Error handling function
const handleError = (error: unknown): void => {
  // const { addAlert } = useAlert(); // Use the alert provider for showing alerts

  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      navigateTo('/login', {state: {from: window.location.pathname}});
      return;
    }
    const validationErrors = error.response?.data.errors;
    if (Array.isArray(validationErrors) && validationErrors.length > 0) {
      console.error('Validation error detail:', validationErrors[0].detail);
      // addAlert(validationErrors[0].detail, 'warning'); // Display alert
    } else {
      console.error('Axios error:', error.response?.data);
      // addAlert('An error occurred while processing your request.', 'error'); // General error alert
    }
  } else {
    console.error('General error:', error);
    // addAlert('An unexpected error occurred.', 'error');
  }
};


export async function uploadFileToS3(file: File, presignedUrl: string): Promise<void> {
  try {
    const response = await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type, // Setting the content type of the file
      },
    });

    // eslint-disable-next-line no-empty
    if (response.status === 200) {

    } else {
      console.error('File upload failed:', response);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error during file upload:', error.response?.data);
    } else {
      console.error('Unknown error during file upload:', error);
    }
  }
}

export const redirectToAdminConsole = async (
): Promise<void> => {

  const data = getAdminConsoleToken();
  Promise.resolve(data).then((response) => {
    const adminConsoleUrl = response.url;
    const token = response.redirect_token;
    window.location.href = adminConsoleUrl + '?redirect_token=' + token;
  }).catch((error) => {
    console.error(error);
  });
};