/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Styles from './Stimpage.module.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CycleData, getCycle, getStim, getStimEvents, OptionType, Stim } from './helper.stim';
import { convertToLocalDateTime, formatDateToYYYYMMDD } from '../../services/time';
import { PaginatedResponse } from '../../types';
import { AxiosError } from 'axios';
import { useAlert } from '../../components/alertProvider';
import { usePatientContext } from '../../components/SubPatinetProvider';

interface CycleSelectorProps {
    onCycleChange: (event: React.SyntheticEvent | null, value: number | null) => void;
    patient: number
}


const CycleSelector: React.FC<CycleSelectorProps> = ({onCycleChange, patient}) => {

  const [stim, setStim] = useState<OptionType[] | null>();
  const [selectedStim, setSelectedStim] = useState<Stim | null>();
  const [selectedStimChart, setSelectedStimChart] = useState<number | null>();
  const [cycle, setStselectedCycle] = useState<OptionType | null>();
  const {addAlert} = useAlert();
  const { patientId } = usePatientContext(); 
  useEffect(() => {
    getCycle(patient).then((value: CycleData[]) => {
      const options: OptionType[] = value.filter((data: CycleData) => data.stimulation_chart && data.stimulation_chart).map((data: CycleData) => ({label: `${data.cycle_number} - ${data.status}`, value: data.stimulation_chart.toString()}));
      setStim(options);
  
      value.forEach((cycle: CycleData) => {
        
        if (cycle.status === 'ACTIVE') {
          setSelectedStimChart(cycle.stimulation_chart);
          setStselectedCycle({
            label: `${cycle.cycle_number} - ${cycle.status}`,
            value: cycle.stimulation_chart.toString(),
          });
        }
      });
    }).catch((error: AxiosError) => {
      setSelectedStim(null);
      setSelectedStimChart(null);
      addAlert(error.response?.statusText || 'An error occurred', 'error');
    });
  }, [patient, addAlert]);
  


  useEffect(() => {
    if (selectedStimChart) {
      // Pass the selectedStimChart id (not the full object) to getStim
      getStim(selectedStimChart).then((stim: Stim) => setSelectedStim(stim));
      onCycleChange(null, selectedStimChart);  // Pass id to onCycleChange
    } else {
      onCycleChange(null, null);
    }
    /* eslint-disable-next-line */
  }, [selectedStimChart]);
  const handleChange = (event: React.SyntheticEvent, value: OptionType | null) => {
    if (value) {
      setSelectedStimChart(parseInt(value.value));
      onCycleChange(event, parseInt(value.value));
      setStselectedCycle(value);
    }
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {<div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingRight: '0.5rem'}}>
            <Typography fontFamily={'Montserrat'} fontWeight={600} fontSize={'18px'} variant='h6'>Cycle Details</Typography>
            <div style={{display: 'flex' , flexDirection: 'row', gap: '0.2rem'}}>
              {/* <Button color='info' variant='text'><Typography sx={{textDecoration: 'underline'}} fontFamily={'Montserrat'} fontWeight={600}  fontSize={'16px'}  variant='h6'>Cycle Overview</Typography></Button> */}
              {stim && <Autocomplete
                value={cycle}
                disablePortal
                options={stim}
                onChange={handleChange}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Cycle No." />}
              />}
              {/* <Button color='error' variant='outlined'>Terminate Cycle</Button> */}
            </div>
          </div>}
          
        </AccordionSummary>
        {selectedStim && <AccordionDetails>
          <div className={Styles.detailsContainer}>
            <div className={Styles.info}><label>{'Cycle Name'}</label><span>{selectedStim.cycle.cycle_name}</span></div>
            <div className={Styles.info}><label>{'Cycle No.'}</label><span>{selectedStim.cycle.cycle_number}</span></div>
            <div className={Styles.info}><label>{'Medication Protocol'}</label><span>{selectedStim.cycle.medication_protocol || 'N/A'}</span></div>
            <div className={Styles.info}><label>{'Cycle Provider'}</label><span>{selectedStim.cycle.provider_name}</span></div>
            <div className={Styles.info}><label>{'Cycle Co-ordinator'}</label><span>{selectedStim.cycle.coordinator_name}</span></div>
            <div className={Styles.info}><label>{'Cycle Start date'}</label><span>{formatDateToYYYYMMDD(convertToLocalDateTime(selectedStim.cycle.cycle_start_date))}</span></div>
            <div className={Styles.info}><label>{'LMP date'}</label><span>{selectedStim.cycle.lmp_date ? formatDateToYYYYMMDD(convertToLocalDateTime(selectedStim.cycle.lmp_date)) : 'N/A'}</span></div>
            <div className={Styles.info}><label>{'Remarks'}</label><span>{selectedStim.cycle.remarks || 'N/A'}</span></div>
          </div>
        </AccordionDetails>}
      </Accordion>
    </div>
  );
};

export default CycleSelector;