import * as Yup from 'yup';
import {  ActionsEnum, BlankEnum, CleavageEnum, CryoMediaEnum, CryoPurposeEnum, CultureDishConfigEnum, EmbryoFinilizeUpdateType, IncubatorEnum,  NullEnum,  StatusDa2Enum } from './embryoCulture.helpers';
import { getRequest, putRequest } from '../../../services/httpService';

// Define the initial embryo culture and update details types
export interface EmbryoCultureType {
    time: string; 
    embryologist?: number | null; 
    witness?: number | null; 
    incubator?: string | null; 
    remarks?: string | null; 
    culture_dish_config?: string | BlankEnum | NullEnum | null; 
    day: number; 
    cycle: number;
    id?: number | null | undefined;
  }
  
export interface EmbryoUpdateDetailsType {    
    cycle: number;
    embryos: number[] | null;
    stage: string | null;
    grade: string | null;
    cleavage: string | null;
    action: string | BlankEnum | null;
    day: number | null;
    status: string | null;
    pgt_order_date:string | null;
    pgt_order_time:string | null;
    pgt_order_biopsy_cells:string | null;
    pgt_type:string | null;
    pgt_facility:string | null;
    pgt_result_embryo_sex:string | null;
    pgt_results:string | null;
    pgt_result_moisture:string | null;
    pgt_result_notes:string | null;

    symmetry :string | null; 
    
    cryo_preserved_date?: string | null;
    cryo_preserved_by?: number | null;
    cryo_purpose?: string | null;
    cryo_media?: string | null;
    is_screened?: boolean | null;
  }
  
// Define initial values for single object
export const initialEmbryoCultureValues: EmbryoCultureType = {
  embryologist: null,
  witness: null,
  cycle: 0,
  remarks: '',
  culture_dish_config: '',
  day: 0,
  time: '',
  incubator: '',
};
  
export const initialEmbryoUpdateValues: EmbryoUpdateDetailsType = {
  cycle: 55,
  embryos: [],
  action: null,
  stage: null,
  grade: null,
  cleavage: null,
  day: 4,
  status: 'DRAFT',
  pgt_order_date: null,
  pgt_order_time: null,
  pgt_order_biopsy_cells: null,
  pgt_type: null,
  pgt_facility: null,
  
  pgt_result_embryo_sex: null,
  pgt_results: null,
  pgt_result_moisture: null,
  pgt_result_notes: null,  

  symmetry:null, 
  
  cryo_preserved_date: null,
  cryo_preserved_by: null,
  cryo_purpose: null,
  cryo_media: null,
  is_screened: false,
};
  
  
// Validation Schema for single embryo culture
export const EmbryoCultureValidationSchema = Yup.object().shape({

  embryologist: Yup.number()
    .required('Embryologist name is required'),
  witness: Yup.number()
    .required('Witness Embryologist name is required')
    .test('not-same', 'The witness embryologist cannot be the same as the embryologist.', function (value) {
      const { embryologist } = this.parent; // Accessing the sibling field
      return value !== embryologist; // Validate that they are not the same
    }),
  
  cycle: Yup.number().integer(),
  remarks: Yup.string().nullable(),
  culture_dish_config: Yup.mixed<CultureDishConfigEnum | BlankEnum | NullEnum>()
    .oneOf([
      'NORMAL_SINGLE_CULTURE', 'EMBRYO_SLIDE_TIMELAPSE_SINGLE_CULTURE', 
      'CULTURE_PRO_TIMELAPSE_SINGLE_CULTURE', 'EMBRYO_SLIDE_IC8_DISH_TIMELAPSE_SINGLE_CULTURE', 
      '38_SPECIAL_GPS_SINGLE_CULTURE', 'EMBRYO_GPS_SINGLE_CULTURE', 
      'MINI_GPS_GPS_SINGLE_CULTURE', '4_WELL_GPS_SINGLE_CULTURE', 
      'U_DROP_GPS_SINGLE_CULTURE', 'EMBRYO_CORRAL_GPS_SINGLE_CULTURE', 
      'NORMAL_GROUP_CULTURE', 'MICROWELL_GROUP_CULTURE', '', 'NULL'
    ])
    .nullable(),
  day: Yup.number().min(0).max(30).integer(),
  time: Yup.string().required(),
  incubator: Yup.mixed<IncubatorEnum | BlankEnum | NullEnum>()
    .oneOf(['HERA_CELL', 'MINC_K_SYSTEMS', 'MEDGYN', 'OOSAFE', 'VITROLIFE', 'IVF_TECH', '', 'NULL'])
    .nullable(),
});
  
// Validation Schema for single embryo update details
export const EmbryoValidationSchema = Yup.object().shape({
  cycle: Yup.number().positive('Cycle must be a positive number'),
  embryos: Yup.array()
    .required('Embryo number is required'), 
  action: Yup.mixed<ActionsEnum | BlankEnum>()
    .oneOf(['CULTURE', 'FREEZE', 'DISCARD', 'TRANSFER'], 'Invalid action')
    .required(),
  stage: Yup.string().nullable(),
  grade: Yup.string().nullable(),
  cleavage: Yup.mixed<CleavageEnum>()
    .nullable()
    .oneOf(['INTACT', 'SYNGAMY', 'EARLY_CLEAVAGE'], 'Invalid cleavage value'),
  day: Yup.number().positive('Day must be a positive number'),
  status: Yup.mixed<StatusDa2Enum>()
    .oneOf(['DRAFT', 'FINALIZED'], 'Invalid status'),
  cryo_preserved_date: Yup.string().nullable(),
  cryo_preserved_by: Yup.string().nullable(),
  cryo_purpose: Yup.mixed<CryoPurposeEnum>()
    .nullable()
    .oneOf(
      [
        'PRESERVATION_OF_EXCESS_EMBRYOS', 
        'TIMED_EMBRYO_TRANSFER', 
        'FERTILITY_PRESERVATION', 
        'GENETIC_TESTING', 
        'FLEXIBLE_FAMILY_PLANNING', 
        'REDUCING_MULTIPLE_PREGNANCIES',
      ], 
      'Invalid cryo purpose'
    ),
  cryo_media: Yup.mixed<CryoMediaEnum>()
    .nullable()
    .oneOf(['KITAZATO', 'SWEMED', 'IRVINE_SCIENTIFIC'], 'Invalid cryo media'),
  is_screened: Yup.boolean(),
});
  
// Combined Validation Schema for single objects
export const CombinedValidationSchema = Yup.object().shape({
  embryoCulture: EmbryoCultureValidationSchema,
  embryoDetails: EmbryoValidationSchema,
});



// Function to update embryo details
export function updateEmbryoSummary(id: number, data: EmbryoCultureType): Promise<EmbryoCultureType> {
  const url = `/embryo/cycle/${id}/`;
  return putRequest(url, data, { responseType: 'json' });
}


// Function to get Embryo retrieval
export function getEmbryoDetails(id: number): Promise<EmbryoCultureType> {
  const url =`oocyte-details/${id}`;
  return getRequest(url, { responseType: 'json' });
}

// Function to get Embryo Details
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEmbryoCultureDetails(id: number,  day: number): Promise<any> {
  const url =`cycle/${id}/embryos/?day=${day}`;
  return getRequest(url, { responseType: 'json' });
}
  
// Function to update Embryo details
export function updateEmbryoDetails(id: number, data: EmbryoCultureType): Promise<EmbryoCultureType> {
  const url = `/embryo-culture/${id}/details`;
  return putRequest(url, data, { responseType: 'json' });
}

// Function to update Embryo Summary details
export function updateEmbryoSummaryDetails(cycle_id : number, data: EmbryoUpdateDetailsType | EmbryoFinilizeUpdateType): Promise<EmbryoUpdateDetailsType> {
  const url = `/embryo/cycle/${cycle_id}`;
  return putRequest(url, data, { responseType: 'json' });
}


export const columns = [
  { label: 'Embryo No.', field: 'embryoNo', editable: false },
  { label: 'Image', field: 'image', editable: false },
  { label: 'Stage', field: 'stage', editable: false },
  { label: 'Grade', field: 'grade', editable: false },
  { label: 'PGT Order Details', field: 'pgt_order', editable: false },
  { label: 'PGT Result', field: 'pgt_results', editable: false },
  { label: 'Action', field: 'action', editable: false }, 
  { label: 'Freeze Location', field: 'freezeLocation', editable: true },
];

export const rows = [];