import * as Yup from 'yup';
import { getRequest, postRequest } from '../../services/httpService';

export interface CycleData {

  procedure: string;
  indication: string;
  medication_protocol: string;
  cycle_creator: string;
  cycle_coordinator: string;
  cycle_provider: string;
  cycle_start_date: string; 
  cycle_end_date: null; 
  lmp_date: string;
  cycle_warning: string;
  remarks: string | null; 
}
export const initialValues: CycleData = {
  procedure: '',
  indication: '',
  medication_protocol: '',
  cycle_creator: '',
  cycle_coordinator: '',
  cycle_provider: '',
  cycle_start_date: '', 
  cycle_end_date: null, 
  lmp_date: '',
  cycle_warning: '',
  remarks: '',
};
export const validationSchema = Yup.object({
  procedure: Yup.number().nullable(),
  medication_protocol: Yup.number().required('Medication Protocol is required'),
  cycle_provider: Yup.string().nullable(),
  cycle_coordinator: Yup.string().nullable(),
  cycle_creator: Yup.string().nullable(),
  cycle_start_date: Yup.date()
    .nullable()
    .required('Cycle Start Date is required'),
  cycle_end_date: Yup.date().nullable(),
  lmp_date:  Yup.date()
    .nullable()
    .required('LMP  Date  is required'),
  remarks: Yup.string().nullable(),
  status: Yup.string().nullable(),
  patient: Yup.number().nullable(),
  cycle_number: Yup.string().nullable(),
});


export const cycleFields = {
  procedure: true,
  medication_protocol: true,
  cycle_provider: true,
  cycle_coordinator: true,
  cycle_creator: true,
  cycle_start_date: true,
  cycle_end_date: true,
  lmp_date: true,
  remarks: true,
  status: true,
  patient: true,
  cycle_number: true,
};
export interface TouchedFields {
  [key: string]: boolean;
}







export function putcycleCreatorsearchUrl(role: string,): string {
  return `/users?role=${role}`;
}
export function cycleCreatorsearch( role: string = 'DOCTOR'): Promise<string> {
  return getRequest(putcycleCreatorsearchUrl(role,), { responseType: 'json' });
}



export function postCreateCycleUrl(): string {
  return '/cycle/';
}

export function createCycle(payload: CycleData): Promise<string> {
  return postRequest(postCreateCycleUrl(), payload, { responseType: 'json' });
}



export function cycleCoordinatorsearch(search: string, role: string = 'NURSE'): Promise<string> {
  return getRequest(putcycleCreatorsearchUrl(role,), { responseType: 'json' });
}



export function getProcedureUrl(patientId: number): string {
  return `/patient-procedure/?patient=${patientId}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getProcedure(patientId: number): Promise<any> {
  return getRequest(getProcedureUrl(patientId), { responseType: 'json' });
}


// getmedicationprotocol 
export interface MedicationProtocolResponse {
  meta: {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  };
  objects: {
    id: number;
    name: string;
    clinic: number;
    protocol_medications: {
      id: number;
      drug: {
        id: number;
        name: string;
        brand_name: string;
        molecule: string;
        strength: string | null;
        stock_uom_code: string;
        route_code: string;
      };
      drug_type: string;
      start_day: number;
      end_day: number;
      quantity: number;
    }[];
  }[];
}

export function getmedicationprotocolUrl(clinic__id: string): string {
  return `/protocols/?clinic__id=${clinic__id}`;
}

export function getmedicationprotocol(clinic__id: string): Promise<MedicationProtocolResponse> {
  return getRequest<MedicationProtocolResponse>(getmedicationprotocolUrl(clinic__id), { responseType: 'json' });
}
