import React, { useEffect, useState } from 'react';
import { ThemeProvider, Box, Typography } from '@mui/material';
import { theme } from '../../Medical_History_Forms/themeprovider';
import { useLoader } from '../../loaderProvider/loader';
import Grid from '@mui/material/Grid2';
import { infoTextStyle, sectionTitleStyle } from '../../../utils/commonUtils';
import { catheterItems, endometriumPatternItems, getEmbryoTransfer, levelOfDifficultyItems, transferMethodItems, typeOfAnesthesiaItems, ultrasoundMethodItems } from './embryoTransfer.helpers';
interface EmbryoTransferDetailsProps {
  cycleId: number | null;
}

const EmbryoTransferDetails: React.FC<EmbryoTransferDetailsProps> = ({ cycleId }) => {
  const { showLoader, hideLoader } = useLoader();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [transferData, setTransferData] = useState<any>(null); // Adjust type as needed

  useEffect(() => {
    if (!cycleId) return;

    showLoader();
    getEmbryoTransfer(cycleId)
      .then((data) => setTransferData(data))
      .catch(() => {
        // setTransferData(defaultEmbryoTransferData); // Default data on error
        setTransferData([]);
      })
      .finally(() => hideLoader());
  }, [cycleId, showLoader, hideLoader]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={4} mb={3}>
          <Grid size={10} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Transfer Method</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                transferMethodItems.find(item => item.value === transferData?.transfer_method)?.label || '-'
              }
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Oocyte Fertilized</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.oocyte_fertilized_method || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Embryo Cryopreserved</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.embryo_cryo_preservation_method || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Fresh Transfer</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.fresh_embryo_transfer_method || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Frozen Transfer</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.frozen_embryo_transfer_method || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Date</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.date || '-'}
            </Typography>
          </Grid>
          
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Start Time</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.start_time || '-'}
            </Typography>
          </Grid>
          
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>End Time</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.end_time || '-'}
            </Typography>
          </Grid>
          
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>ET Dish ID</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.et_dish_id || '-'}
            </Typography>
          </Grid>
          
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Embryologist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.embryologist_name || '-'}
            </Typography>
          </Grid>
          
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Witness Embryologist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.witness_embryologist_name || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Nurse</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.nurse_name || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Performed Under Anesthesia</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.performed_under_anesthesia ? 'Yes' : 'No'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Anesthesia</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                typeOfAnesthesiaItems.find(item => item.value === transferData?.anesthesia)?.label || '-'
              }
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Anesthetist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.anesthetist_name || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Endometrium Thickness (mm)</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.endometrium_thickness || '-'}
            </Typography>
          </Grid>


          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Endometrium Pattern</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                endometriumPatternItems.find(item => item.value === transferData?.endometrium_pattern)?.label || '-'
              }
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Ultrasound</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.is_ultrasound ? 'Yes' : 'No'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Ultrasound Method</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                ultrasoundMethodItems.find(item => item.value === transferData?.ultrasound_method)?.label || '-'
              }
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Transfer Depth</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.transfer_depth || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Level of Difficulty</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                levelOfDifficultyItems.find(item => item.value === transferData?.difficulty_level)?.label || '-'
              }
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Catheter</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {
                catheterItems.find(item => item.value === transferData?.catheter)?.label || '-'
              }
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Catheter Tube</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.catheter_tube || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Catheter Remarks</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.catheter_remarks || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>No of Attempts</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.no_of_attempts || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Distance From Fundus (cm)</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.distance_from_fundus || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Embryo Retained</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.embryo_retained || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Use Of Stylet</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.is_stylet_used ? 'Yes' : 'No'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Use Of Tenaculum</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.is_tenaculum_used ? 'Yes' : 'No'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Use Of Embryo Glue</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.is_embryo_glue_used ? 'Yes' : 'No'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Complications</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.complications || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Set Pregnancy Date</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.pregnancy_date || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Cycle Close</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.is_cycle_closed ? 'Yes' : 'No'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Cycle Close Reason</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.cycle_close_reason || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Notes</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {transferData?.notes || '-'}
            </Typography>
          </Grid>

        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default EmbryoTransferDetails;
