/* eslint-disable @typescript-eslint/no-explicit-any */
import { getRequest } from '../../services/httpService';
export interface LogsData {
  id: number | null;
  cycle: number;
  cryo_date: string;
  location: string;
  media_volume: number | null;
  semen_volume: number | null;
  expiry_date: string | null;
  remark: string | null;
  thaw_date: string | null;
  thaw_time: string | null;
  is_survived: boolean | null;
  plan: string | null;
  doctor: number;
  witness: number;
  reason: string | null;
  thaw_remark: string | null;
  recipient: number;
  status: string;
  cycle_number: string;
  recipient_name: string;
  recipient_mrn: string;
  cryo_preserved_by_name: string;
  doctor_name: string;
  sample_id: number;
  witness_name: string;
  type_of_sperm: string | null;
  volume: number | null;
  sperm_concentration_million_per_ml: number | null;
  total_sperm_count: number | null;
  all_progressive: number | null;
  total_motility: number | null;
  immotile_percent: number | null;
  non_progressive_percent: number | null;

  // Newly added fields
  day: string | null;
  cryo_count: number;
  thaw_count: number;
  processing_count: number;
  research_count: number;
  survival_count: number;
  discard_count: number;
  balance: number;
}

export const mapLogsData = (fetchedData: any[]): LogsData[] => {
  return fetchedData.map(item => ({
    id: item.id || null,
    cycle: item.cycle || 0,
    cryo_date: item.cryo_preserved_date || item.cryo_date || '',
    location: item.location || '',
    media_volume: item.cryo_media || null,
    semen_volume: item.semen_volume || null,
    expiry_date: item.expiry_date || null,
    remark: item.remarks || null,
    thaw_date: item.thaw_date || null,
    thaw_time: item.thaw_time || null,
    is_survived: item.is_survived || null,
    plan: item.disposition_plan || null,
    doctor: item.doctor || 0,
    witness: item.witness || 0,
    reason: item.reason || null,
    thaw_remark: item.thaw_remark || null,
    recipient: item.recipient || 0,
    status: item.status || '',
    cycle_number: item.cycle_number || '',
    recipient_name: item.recipient_name || '',
    recipient_mrn: item.recipient_mrn || '',
    cryo_preserved_by_name: item.cryo_preserved_by_name || '',
    doctor_name: item.doctor_name || '',
    sample_id: item.sample_id || 0,
    witness_name: item.witness_name || '',
    type_of_sperm: item.type_of_sperm || null,
    volume: item.volume || null,
    sperm_concentration_million_per_ml: item.sperm_concentration_million_per_ml || null,
    total_sperm_count: item.total_sperm_count || null,
    all_progressive: item.all_progressive || null,
    total_motility: item.total_motility || null,
    immotile_percent: item.immotile_percent || null,
    non_progressive_percent: item.non_progressive_percent || null,

    // Mappings for newly added fields
    day: item.day || null,
    cryo_count: item.cryo_count || 0,
    thaw_count: item.thaw_count || 0,
    processing_count: item.processing_count || 0,
    research_count: item.research_count || 0,
    survival_count: item.survival_count || 0,
    discard_count: item.discard_count || 0,
    balance: item.balance || 0,
  }));
};


export const updatedLabels = (item: any) => [
  { label: '#Cultured', value: item.grade || '-' },
  { label: '#Researched', value: item.oocyteId || '-' },
  { label: '#Discarded', value: item.is_screened ? 'YES' : 'NO' },
];


export function getLogsUrl(id: number, label: string): string {
  const sanitizedLabel = label.toLowerCase(); 
  return `/cryo-storage/${sanitizedLabel}/patient/${id}/?status=LOGS`;
}

export function getLogs(id: number, label: string): Promise<LogsData> {
  return getRequest(getLogsUrl(id, label));
}