import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Typography, Box,  Avatar,  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, AvatarGroup, Tooltip, ThemeProvider } from '@mui/material';
import { ReactComponent as More } from '../../../../src/assets/SVG/close-circle-greay.svg';
import Warning from '../../../../src/assets/SVG/Warning-red.svg';
import { proceduresListType, procedureColumns, procedureGroupConfig, ServiceType, deleteCycle, Patient } from './proceduresHelper';
import Grid from '@mui/material/Grid2';
import EditableTable from '../../customTableComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { theme } from '../../Medical_History_Forms/themeprovider';
import styles from './procedureComponent.module.css';
import { useLoader } from '../../loaderProvider/loader';
import { useAlert } from '../../alertProvider';
import { putLinkOocyteDonor } from '../createCycle/LinkOocyteDonorHelper';
import { usePatientContext } from '../../SubPatinetProvider';
// import { ConfirmationModal } from '../../confirmationModal';

interface ProcedureDialogProps {
  open: boolean;
  handleClose: () => void;
  procedureData: proceduresListType;
  onSave: (updatedData: proceduresListType) => void;
}

const ProcedureDialog: React.FC<ProcedureDialogProps> = ({ open, handleClose, procedureData, onSave }) => {
  const [tableData, setTableData] = useState<ServiceType[]>(procedureData?.services || []);
  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const [openModal, setOpenModal] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [donors, setDonors] =useState<Patient[]>([]);
  const titles = ['Remove Procedure?', 'Remove Donor?', 'Delete Procedure?'];
  const [donorsIds, setDonorsIds] =useState<number[]>([]);
  const [selectedDonors, setSelectedDonors] = useState<number[]>([]);
  const { patientId } = usePatientContext();

  useEffect(() => {
    setActiveStep(0);
    setSelectedDonors([]);
    const combinedSources =(procedureData && procedureData.donor_info && procedureData.donor_info.recipient !== null)? [...procedureData.donor_info.sperm_source, ...procedureData.donor_info.oocyte_source].filter(
      (item) => item.type !== 'self'
    ): [];
    setDonors(combinedSources);
    const donorIdArray = combinedSources.map(donor => donor.id);
    
    setDonorsIds(donorIdArray);
    if (donorIdArray.length === 0) {
      setActiveStep(2);
    }
  }, [procedureData]);

  const handleNext = () => {

    if (activeStep === 1) {
      const donorsNotSelected = donorsIds.filter(id => !selectedDonors.includes(id));
      const donorData = {
        donor_ids: donorsNotSelected,
      };
      putLinkOocyteDonor(patientId, donorData)
        .then(() => {
          addAlert('Donor Delinked successfully.', 'success');
          setActiveStep((prevStep) => prevStep + 1);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          
          addAlert('Error Delinked donors. Please try again.', 'error'); 
          setActiveStep(0);
          setOpenModal(false);
          return;
        });
      
    }else if (activeStep === 2) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleDelete(procedureData.id);
    }else{
      setActiveStep((prevStep) => prevStep + 1);

    }
  };

  const handleToggle = (donorId: number) => {
    setSelectedDonors((prevSelected) =>
      prevSelected.includes(donorId)
        ? prevSelected.filter((id) => id !== donorId) // Deselect if already selected
        : [...prevSelected, donorId] // Add to selected if not already present
    );
  };
  
  useEffect(() => {

    
    if (open) {
      setTableData(procedureData?.services || []);
    }
  }, [open, procedureData?.services]);


  const handleDelete=(id:number)=>{
    showLoader();
    deleteCycle(id).then((res)=>{
      // eslint-disable-next-line no-console
      console.log(res);
      addAlert('Procedure deletes successfully', 'success');
      onSave({ ...procedureData });
      setActiveStep(0);
      setOpenModal(false);
      handleClose();
      
    })
      .catch((error) => {
      // Handle errors
        // eslint-disable-next-line no-console
        console.error('An error occurred:', error);
        addAlert('An error occurred:', 'error');
      }).finally(() => {
        hideLoader();
          
      });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select all donors
      setSelectedDonors(donors.map(donor => donor.id));
    } else {
      // Deselect all donors
      setSelectedDonors([]);
    }
  };

  return (

    <ThemeProvider theme={theme}>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="lg" fullWidth
        PaperProps={{  sx: {  borderRadius: '12px', width: '60%',  minWidth: '700px',}  }}
      >
        <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '1px' }}>
            <span style={{ marginLeft: '12px' }}>Procedure Details  </span>
            <IconButton edge='end' color="inherit" onClick={handleClose} aria-label="close">
              <More />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContent>

            {/* Procedure Info Section */}
            <Grid container spacing={2} mb={3}>
              <Grid size={3} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">Procedure Type</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {procedureData?.procedures_type}
                </Typography>
              </Grid>
              <Grid size={3} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">Advised Date</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {procedureData?.advised_date}
                </Typography>
              </Grid>
            
              <Grid size={3} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">Indication</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{procedureData?.indication}</Typography>
              </Grid>

              <Grid size={3} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">Doctor Name </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{procedureData?.doctor_name}</Typography>
              </Grid>

            </Grid>

            {/* Cycle Info Section donor_info  */}
            {procedureData?.donor_info && procedureData.donor_info.recipient && (
              <Grid container spacing={2} mb={3}>
                <Grid size={3} sx={{ textAlign: 'left' }}>
                  <Typography variant="body1">Oocyte Source</Typography>
                  <Box>
                    {(() => {
                      const sources = procedureData.donor_info.oocyte_source;

                      if (sources.length === 1 && sources[0].type === 'self') {
                        return (
                          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Patient
                          </Typography>
                        );
                      } else {
                        return (
                          <Box display="flex" alignItems="center" gap={1}>
                            {/* Show Patient if 'self' exists */}
                            {sources.some((source) => source.type === 'self') && (
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Patient, 
                              </Typography>
                            )}
                            {/* Show Donor Avatars */}
                            {/* Show Donor Avatars using AvatarGroup */}
                            {sources.some((source) => source.type !== 'self') && (
                              <AvatarGroup >
                                {sources
                                  .filter((source) => source.type !== 'self')
                                  .map((donor, index) => (
                                    <Tooltip
                                      key={index}
                                      title={`${donor.first_name || 'Unknown'} ${donor.last_name || ''} - ${donor.medical_record_no || ''}`}
                                      arrow
                                    >
                                      <Avatar
                                        alt={donor.first_name || 'Donor'}
                                        src={donor.photo_url || ''}
                                        sx={{ width: 30, height: 30 }}
                                      />
                                    </Tooltip>
                                  ))}
                              </AvatarGroup>
                            )}
                          </Box>
                        );
                      }
                    })()}
                  </Box>
                </Grid>
                <Grid size={3} sx={{ textAlign: 'left' }}>
                  <Typography variant="body1">Sperm Source</Typography>
                  <Box>
                    {(() => {
                      const sources = procedureData.donor_info.sperm_source;

                      if (sources.length === 1 && sources[0].type === 'self') {
                        return (
                          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Partner
                          </Typography>
                        );
                      } else {
                        return (
                          <Box display="flex" alignItems="center" gap={1}>
                            {/* Show Partner if 'self' exists */}
                            {sources.some((source) => source.type === 'self') && (
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Partner, 
                              </Typography>
                            )}
                            {/* Show Donor Avatars */}
                            {/* Show Donor Avatars using AvatarGroup */}
                            {sources.some((source) => source.type !== 'self') && (
                              <AvatarGroup >
                                {sources
                                  .filter((source) => source.type !== 'self')
                                  .map((donor, index) => (
                                    <Tooltip
                                      key={index}
                                      title={`${donor.first_name || 'Unknown'} ${donor.last_name || ''} - ${donor.medical_record_no || ''}`}
                                      arrow
                                    >
                                      <Avatar
                                        alt={donor.first_name || 'Donor'}
                                        src={donor.photo_url || ''}
                                        sx={{ width: 30, height: 30 }}
                                      />
                                    </Tooltip>
                                  ))}
                              </AvatarGroup>
                            )}
                          </Box>
                        );
                      }
                    })()}
                  </Box>
                </Grid>
                <Grid size={3} sx={{ textAlign: 'left' }}>
                  <Typography variant="body1">Recipient</Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {(() => {
                      const sources = procedureData.donor_info.recipient;
                      if (sources.type === 'self') {
                        return 'Patient';
                      } else {
                        return (
                          <Tooltip title={`${sources.first_name || 'Unknown'} ${sources.last_name || ''} - ${sources.medical_record_no || ''}` } arrow>
                            <Avatar
                              alt={sources.first_name || 'Donor'}
                              src={sources.photo_url || ''}
                              sx={{ width: 30, height: 30 }}
                            />
                          </Tooltip>
                        );
                      }
                    })()}
                  </Typography>
                </Grid>
              </Grid>
            )}
          
            <Grid size={12}>
              {tableData?.length === 0 ? (
                <div>No procedures found.</div>
              ) : (
                <EditableTable
                  columns={procedureColumns}
                  data={tableData}
                  actionType="none"
                  groupConfig={procedureGroupConfig}
                />
              )}
            </Grid>
          </DialogContent>      
        </DialogContent>

        <DialogActions sx={{ mx: '24px', mb:'16px' }}>
          {/* Left Button */}
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
            <Button onClick={handleClose} color="secondary" variant='outlined'
              className={`${styles.btn_css}`}
              style={{
                fontFamily: 'Montserrat',
                width: '220px',
                height: '46px',
                borderRadius: '12px',
                padding: '12px 40px',
                gap: '10px',
                backgroundColor: '#23323',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ArrowBackIcon style={{ marginRight: '8px' }} /> {/* Add back arrow icon */}
        Back
            </Button>
          </div>

          {/* Right Button */}
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={()=>setOpenModal(true)} 
              className={`${styles.delete_btn}`}
              style={{
                fontFamily: 'Montserrat',
                
                height: '46px',
                borderRadius: '12px',
                padding: '12px 40px',
                gap: '10px',
                backgroundColor: '#333',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DeleteIcon style={{ marginRight: '8px' }} /> 
        Delete Procedure
            </Button>
            {/* </Button> */}
          </div>
        </DialogActions>

        {/* <ConfirmationModal
        open={openModal}
        handleClose={() => setOpenModal(false)} 
        onConfirm={()=>handleDelete(procedureData.id)} 
        title="Link Donor"
        contentMessage="Are you sure you want to delete Cycle?"
        type={'warning'}
      /> */}

        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="remove-cycle-dialog-title"
          aria-describedby="remove-cycle-dialog-description"
          maxWidth="xs"
          PaperProps={{  sx: {  borderRadius: '12px', minWidth: '500px',}  }}
          fullWidth
        >
          <DialogTitle id="remove-cycle-dialog-title" sx={{ textAlign: 'center' }}>
            <Box display="flex" justifyContent="center" mb={2}>
              <img src={Warning} alt="warning" />
            </Box>
            <Typography variant="h6" fontWeight="700">
              {titles[activeStep]}
            </Typography>
          </DialogTitle>

          <DialogContent>

            {activeStep === 0 && (
              <>
                <Typography variant="body1" textAlign="center">
          You will need to remove the Donor first
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  textAlign="center"
                  sx={{ marginTop: 2 }}
                >
          &ldquo;&nbsp;Cycle Related Procedure&nbsp;&ldquo;
                </Typography>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Typography  textAlign="center">Select the donor(s) to remove:</Typography>
                <TableContainer sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: '0px' }} align="center">
                          <Checkbox
                            checked={donors.length > 0 && donors.every(donor => selectedDonors.includes(donor.id))}
                            onChange={handleSelectAll}
                            inputProps={{ 'aria-label': 'select all donors' }}
                          />
                        </TableCell>
                        <TableCell>Select All</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {donors.map((donor) => (
                        <TableRow key={donor.id}>
                          <TableCell sx={{ padding: '0px' }} align="center">
                            <Checkbox
                              checked={selectedDonors.includes(donor.id)}
                              onChange={() => handleToggle(donor.id)}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: '0px', paddingY: '10px', display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ marginRight: '10px', width: '25px', height: '25px' }} src={donor.photo_url} alt={donor.first_name} />
                            {donor.first_name} {donor.last_name}
                          </TableCell>
                          <TableCell sx={{ padding: '0px' }}>{donor.medical_record_no || '-'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
         
            {activeStep === 2 && (
              <>
                <Typography variant="body1" textAlign="center">
              Are you sure want to delete procedure
                </Typography>
              </>
            )}
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', gap: 2, padding: 2 }}>
            <Button
              onClick={() => {setOpenModal(false);setActiveStep(0);}}
              variant="outlined"
              color="inherit"
              sx={{
                textTransform: 'none',
                fontWeight: '600',
                padding: '8px 20px',
                borderRadius: '6px',
              }}
            >
          Cancel
            </Button>
            <Button
              onClick={()=>handleNext()}
              variant="contained"
 
              sx={{
                textTransform: 'none',
                fontWeight: '600',
                padding: '8px 20px',
                borderRadius: '6px',
                bgcolor:'#505050',
                color:'#FFF'
              }}
            >
              {activeStep === 0 && 'Proceed'}
              {activeStep === 1 && 'Remove'}
              {activeStep === 2 && 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>

      
      </Dialog>
    </ThemeProvider>
  );
};

export default ProcedureDialog;
