import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import StatusChip from '../../customTableComponent/statusChip';
import EmbryoFileUpload from './embryoFileUpload';

  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmbryoDetailsTable = ({ embryoDetails }: { embryoDetails: any[] }) => {
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File | null>(null); 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imagePathUrl, setImagePathUrl] = useState<string | null>(null);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              height: '2rem',
              background: 'rgba(250, 250, 250, 1)',
              border: 'none',
              '& .MuiTableCell-root': {
                padding: '0.6rem 0.6rem',
              },
            }}
          >
            {['Embryo No.', 'Grade', 'Cell Storage', 'Status', 'Upload'].map((title, index) => (
              <TableCell
                key={index}
                sx={{
                  fontFamily: 'Nunito, sans-serif',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  lineHeight: '1.45',
                  textAlign: 'left',
                  color: 'rgba(35, 35, 35, 1)',
                }}
              >
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {embryoDetails.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <Typography variant="h6" color="textSecondary">
                  No data found
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            embryoDetails.map((embryo, index) => (
              <TableRow key={index}>
                <TableCell>{embryo.embryo_number}</TableCell>
                <TableCell>{embryo.grade}</TableCell>
                <TableCell>{embryo.stage}</TableCell>
                <TableCell>
                  <StatusChip value='Fresh' />  
                </TableCell>
                <TableCell>
                  {embryo.transfer_files_url ? (
                    <>
                      <EmbryoFileUpload id={embryo.id} onFileUpload={setFile} imagePathUrl={embryo.transfer_files_url} />
                    </>
                  ) : (
                    <EmbryoFileUpload id={embryo.id} onFileUpload={setFile}  imagePathUrl={imagePathUrl} />
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmbryoDetailsTable;
