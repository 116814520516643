import React, { useMemo } from 'react';
import { Chip } from '@mui/material';

interface StatusChipProps {
  value: string;
  width?: string | number; // Optional width
  height?: string | number; // Optional height
}

type StatusKey = 'Active' | 'Inactive' | 'Prescribed' | 'Completed' | 'Fresh' | 'Frozen' | 'ACTIVE' | 'TERMINATED' | 'Pending';

const statusColors: Record<StatusKey, { color: string; background: string }> = {
  Active: { color: '#47B35F', background: '#F4FFF6' },
  Inactive: { color: '#949494', background: '#F3F3F3' },
  Prescribed: { color: '#EFAE78', background: '#FFF7F1' },
  Completed: { color: '#47B35F', background: '#F4FFF6' },
  Fresh: { color: '#47B35F', background: '#F4FFF6' },
  Frozen: { color: '#00BCD4', background: '#e8fcff' },
  TERMINATED: { color: '#949494', background: '#F3F3F3' },
  ACTIVE: { color: '#47B35F', background: '#F4FFF6' },
  Pending: { color: '#EFAE78', background: '#FFF5ED' },
};

// Update function to ensure fallback for unknown statuses
const getStatusChipStyles = (value: StatusKey | string) => {
  const status = statusColors[value as StatusKey];
  if (!status) {
    return statusColors['Inactive'];
  }
  return status;
};

const StatusChip: React.FC<StatusChipProps> = ({ value, width, height = 25 }) => {
  // UseMemo to memoize the styles, handle invalid values gracefully
  const { color, background } = useMemo(() => {
    const statusKey = value as StatusKey; // Assume value is a valid StatusKey or default
    return getStatusChipStyles(statusKey);
  }, [value]);

  return (
    <Chip
      label={value}
      variant="outlined"
      size="small"
      sx={{
        border: '1px solid',
        borderColor: color,
        fontFamily: 'Nunito',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '14.4px',
        letterSpacing: '0.04em',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        padding: '0 6px',
        color: color,
        backgroundColor: background,
        width: width || 'auto',
        height: height || 'auto',
      }}
    />
  );
};

export default StatusChip;
