/* eslint-disable @typescript-eslint/no-explicit-any */
import { getRequest } from '../../services/httpService';

export const updatedLabels = (item: any) => [
  { label: 'Grade', value: item.grade || '-' },
  { label: 'Protocol', value: item.oocyteId || '-' },
  { label: 'Screening Status', value: item.is_screened ? 'YES' : 'NO' },
];

export const billing = [
  { label: 'Bill No.', value: '957421' },
  { label: 'Plan', value: 'Culture' },
  { label: 'Charges', value: '$190' },
  { label: 'Due.', value: '$45' },
  { label: 'Last Billed Date', value: '16/12/2024' },
  { label: 'Next Billed On', value: '16/12/2024' },
];

export interface CryopreservedData {
  id: number | null;
  cycle: number | null;
  remarks: string | null;
  oocyte_number: number | null;
  grade: string | null;
  location: string | null;
  disposition: string | null;
  files: string | null;
  cryo_preserved_date: string | null;
  cryo_preserved_by: number | null;
  cryo_purpose: string | null;
  cryo_media: string | null;
  is_screened: boolean | null;
  disposition_plan: string | null;
  disposition_plan_type: string | null;
  disposition_date: string | null;
  disposition_time: string | null;
  cryo_preserved_by_name: string | null;
  disposition_recipient: number | null;
  disposition_recipient_name: string | null;
  billing: string | null; 
}


export function getCryopreservedUrl(id: number, label: string): string {
  const sanitizedLabel = label.toLowerCase(); 
  return `/cryo-storage/${sanitizedLabel}/patient/${id}/?status=FREEZE`;
}

export function getCryopreserved(id: number, label: string): Promise<CryopreservedData> {
  return getRequest(getCryopreservedUrl(id, label));
}



