import { getRequest } from '../../../services/httpService';

export interface Event {
  event_type: string;
  date: string;
  appointment: number | null;
}

export interface Medication {
  drug: {
    id: number;
    name: string;
    brand_name: string;
    molecule: string;
    strength: string | null;
    stock_uom_code: string;
    route_code: string;
  };
  drug_type: string;
  start_date: string;
  end_date: string;
  quantity: number;
}

export interface StimulationChartData {
  events: Event[];
  medications: Medication[];
}

export interface DayData {
  date: string;
  name: string;
}


export function getstimulationChartUrl(Id: string): string {
  return `/stimulation-chart/${Id}/`; 
}


export function getstimulationChart(Id: string): Promise<StimulationChartData> {
  return getRequest(getstimulationChartUrl(Id), { responseType: 'json' });
}
