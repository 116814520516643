 
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CalendarNav, Eventcalendar, MbscCalendarDayData, MbscCalendarEvent, MbscCalendarEventData, MbscEventCreateEvent, MbscResource, setOptions } from '@mobiscroll/react';
import React, { useCallback, useEffect, useState } from 'react';
import Styles from './stemsche.module.css';
import { Button, Typography } from '@mui/material';
import {ReactComponent as AddCircle} from './../../assets/SVG/add-circle.svg';
import {ReactComponent as CloseCircle} from './../../assets/SVG/closecircle.svg';
import {ReactComponent as Eye} from './../../assets/SVG/eye.svg';
import { showConfirmationModal } from '../ConfirmationModel';
import { ReactComponent as Camera } from './../../assets/SVG/camera copy.svg';
import { ReactComponent as CheckBox } from './../../assets/SVG/CheckboxGrantAccess.svg';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { createTest, FollicularStudyDetails, FollicularStudyResultsList, getFSResults, getStim, getTestResults, Stim, StimulationEvent, StimulationMedication, TestRequest, TestResult } from '../../pages/Stim/helper.stim';
import { useAlert } from '../alertProvider';
import { convertToLocalDateTime, formatDateToLocalYYYYMMDD } from '../../services/time';
import DefaultLoader from '../defaultLoader';
import { EventcalendarBase } from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';
import EventModel from '../../pages/Stim/eventModal';
import { title } from 'process';
import AddDrugModal from '../../pages/Stim/addDrugModel';
import CreateDrugModal from '../../pages/Stim/createMedModal';
import CreateHormoneModal from '../../pages/Stim/createHormone';
import { PaginatedResponse } from '../../types';
import { usePatientContext } from '../SubPatinetProvider';
import HormoneValueModal from '../../pages/Stim/createHormoneTestModal';
import FolliculerModal from '../../pages/Stim/follicularModal';
import FollicularImageModal from './FollicularImageModal';
import { convertDateToDDMMYYYY } from '../../utils/commonUtils';

setOptions({
  theme: 'ios',
  themeVariant: 'light'
});

interface StimulationSchedularProps {
  id: number,
  height?: string
}

export const StimulationSchedular: React.FC<StimulationSchedularProps> = ({id, height}) => {

  const [stim, setStim] = useState<MbscResource[] | null>(null);
  const [stimEvents, setStimEvents] = useState<MbscCalendarEvent[] | null>(null);
  const [trigger, setTrigger] = useState<MbscResource[] | null>(null);
  const [triggerEvents, setTriggerEvents] = useState<MbscCalendarEvent[] | null>(null);
  const [hormone, setHormone] = useState<MbscResource[] | null>(null);
  const [hormoneEvents, setHormoneEvents] = useState<MbscCalendarEvent[] | null>(null);

  const [follicularStudyEvents, setFollicularStudyEvents] = useState<MbscCalendarEvent[] | null>(null);
  const [follicularImageEvents, setFollicularImageEvents] = useState<MbscCalendarEvent[] | null>(null);
  const [follicularDetailsEvents, setFollicularDetailsEvents] = useState<MbscCalendarEvent[] | null>(null);
  const [follicularETEvents, setFollicularETEvents] = useState<MbscCalendarEvent[] | null>(null);
  const [follicularSizeEvents, setFollicularSizeEvents] = useState<MbscCalendarEvent[] | null>(null);

  const [firstDate, setFirstDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const [fullScreen, showFullScreen] = useState<boolean>(false);

  const [selectedStim, setSelctedStim] = useState<Stim | null>();
  
  const [focusEvent, setFocusEvent] = useState<MbscEventCreateEvent | null>();
  const [focusEventCal, setFocusEventCal] = useState<MbscCalendarEventData | null>();

  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [showDrugModal, setShowDrugModal] = useState<boolean>(false);
  const [showCreateDrugModal, setShowCreateDrugModal] = useState<boolean>(false);
  const [showCreateHormoneModal, setShowCreateHormoneModal] = useState<boolean>(false);
  const [showHormoneValueModal, setShowHormoneValueModal] = useState<boolean>(false);
  const [showFolliculareModal, setShowFollicularModal] = useState<boolean>(false);
  const [showFolliculareImageModal, setShowFollicularImageModal] = useState<boolean>(false);

  const [events, setEvents] = useState<MbscCalendarEvent[] | undefined>();
  const [referesh, doReferesh] = useState<boolean>(false);
  const [type, setType] = useState<'Trigger' | 'Stim' | null>(null);

  const {patientId} = usePatientContext();
  const { addAlert } = useAlert();

  const handleModelClose = () => {
    setShowCreateHormoneModal(false);
    setShowCreateDrugModal(false);
    setFocusEvent(null);
    setFocusEventCal(null);
  };

  const handleModelCloseAndReferesh = () => {
    setShowDrugModal(false);
    setShowEventModal(false);
    setFocusEvent(null);
    setFocusEventCal(null);
    setShowFollicularModal(false);
    setShowFollicularImageModal(false);
    setShowHormoneValueModal(false);
    setFocusEvent(null);
    setFocusEventCal(null);
    doReferesh(!referesh);
  };
  
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Update on load and resize
    window.addEventListener('resize', setVh);
    window.addEventListener('orientationchange', setVh);
    setVh();
  }, []);

  useEffect(() => {
    if (id) {
      getStim(id).then((stim: Stim) => setSelctedStim(stim)).catch(() => addAlert('Failed to fetch stim details.', 'error'));
    }
  }, [id, addAlert, referesh]);

  useEffect(() => {
    if (selectedStim?.cycle.cycle_start_date) {
      setFirstDate(convertToLocalDateTime(selectedStim?.cycle.cycle_start_date));
    }

    if (selectedStim?.cycle.cycle_end_date) {
      setEndDate(convertToLocalDateTime(selectedStim?.cycle.cycle_end_date));
    }
  }, [selectedStim]);

  useEffect(() => {
    if (patientId) {
      getTestResults(patientId).then((results: TestResult[])=> {
        const hormoneResources: MbscResource[] = Array.from(
          new Map(
            results.map((result: TestResult) => [
              result.laboratory_test.id,
              {
                id: `hormone-${result.laboratory_test.id}`,
                name: result.laboratory_test.name,
                eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false
              },
            ])
          ).values()
        );
        console.log('hormone', 'setting resources', hormoneResources, firstDate, endDate);
        setHormone(hormoneResources);
        const hormoneEvents: MbscCalendarEvent[] = results
          .filter((result: TestResult) => 
            firstDate && convertToLocalDateTime(result.date) >= firstDate && (endDate ? convertToLocalDateTime(result.date) <= (endDate) : true)
          )
          .map((result: TestResult) => ({
            resource: `hormone-${result.laboratory_test.id}`,
            name: result.laboratory_test.name,
            title: result.results?.toString() || '+',
            date: convertToLocalDateTime(result.date),
            cssClass: 'hormone',
            id: result.id
          }));
        console.log('hormone', 'setting events', hormoneEvents);
        setHormoneEvents(hormoneEvents);
      });
    }
    if (selectedStim?.medications) {
      const stimDrugs: MbscResource[] = Array.from(
        new Map(
          selectedStim.medications
            .filter((med: StimulationMedication) => med.drug_type === 'STIMULATION') // Filter before mapping
            .map((med: StimulationMedication) => [`stim-${med.drug.id}`, med]) // Use drug.id as the unique key
        ).values()
      ).map((med: StimulationMedication) => ({
        id: `stim-${med.drug.id}`,
        name: med.drug.name,
      }));
      setStim(stimDrugs);
      const triggerDrugs: MbscResource[] = Array.from(
        new Map(
          selectedStim.medications
            .filter((med: StimulationMedication) => med.drug_type === 'TRIGGER') // Filter before mapping
            .map((med: StimulationMedication) => [`trigger-${med.drug.id}`, med]) // Use drug.id as the unique key
        ).values()
      ).map((med: StimulationMedication) => ({
        id: `trigger-${med.drug.id}`,
        name: med.drug.name,
      }));
      setTrigger(triggerDrugs);
      const eventsMedStim: MbscCalendarEvent[] = selectedStim?.medications?.filter((med: StimulationMedication) => med.drug_type === 'STIMULATION')
        .map((med: StimulationMedication) => ({
          id: med.id.toString(),
          title: med.quantity.toString(),
          start: convertToLocalDateTime(med.start_date),
          end: convertToLocalDateTime(med.end_date),
          resource: `stim-${med.drug.id}`,
        }));
      setStimEvents(eventsMedStim);
      const eventsMedtrigger: MbscCalendarEvent[] = 
      selectedStim?.medications?.filter((med: StimulationMedication) => med.drug_type === 'TRIGGER')
        .map((med: StimulationMedication) => ({
          id: med.id.toString(),
          title: med.quantity.toString(),
          start: convertToLocalDateTime(med.start_date),
          end: convertToLocalDateTime(med.end_date),
          resource: `trigger-${med.drug.id}`,
        }));
      setTriggerEvents(eventsMedtrigger);
    }
    if (selectedStim?.events) {
      const events: MbscCalendarEvent[] = selectedStim?.events?.map((med: StimulationEvent) => ({
        id: med.id,
        title: med.event_type.toUpperCase(),
        date: convertToLocalDateTime(med.date),
        resource: 'Event',
        allDay: true
      }));
      setEvents(events);
    }

    if (selectedStim) {
      getFSResults(selectedStim.id)
        .then((res: PaginatedResponse<FollicularStudyResultsList[]>) => res.objects)
        .then((results: FollicularStudyResultsList[]) => {
          const uniqueEventsMap = new Map<string, MbscCalendarEvent>();

          results.forEach((result: FollicularStudyResultsList) => {
            // Base events
            const baseEvents = [
              { date: formatDateToLocalYYYYMMDD(new Date(result.date)), resource: 'fs' },
              { date: formatDateToLocalYYYYMMDD(new Date(result.date)), resource: 'fi', title: result.left_ovary_path_url, id: result.id},
              { date: formatDateToLocalYYYYMMDD(new Date(result.date)), resource: 'fd', title: result.details?.toString(), id: result.id},
              { date: formatDateToLocalYYYYMMDD(new Date(result.date)), resource: 'et', title: result.endometrium_thickness || '-' },
            ];

            baseEvents.forEach((event) => {
              const key = `${event.date}-${event.resource}`;
              if (!uniqueEventsMap.has(key)) {
                uniqueEventsMap.set(key, event);
              }
            });

            // Group follicular details
            if (result.details) {
              result.details.forEach((detail: FollicularStudyDetails) => {
                const processDetail = (size: string | null | undefined, count: string | null | undefined, side: 'L' | 'R') => {
                  const numericSize = size ? parseFloat(size) : null;
                  const follicleCount = count ? parseInt(count, 10) : 0;

                  if (numericSize !== null) {
                    const resource = numericSize < 2 ? '<2' : numericSize === 2 ? '2' : numericSize === 3 ? '3' : '4';
                    const key = `${result.date}-${resource}`;

                    if (!uniqueEventsMap.has(key)) {
                      uniqueEventsMap.set(key, {
                        date: formatDateToLocalYYYYMMDD(new Date(result.date)),
                        resource,
                        title: 'L:0 R:0', // Initialize with compact format
                      });
                    }

                    // Update the respective L or R count
                    const event = uniqueEventsMap.get(key);
                    if (event && event.title) {
                      const match = event.title.match(new RegExp(`${side}:(\\d+)`));
                      const currentCount = match ? parseInt(match[1], 10) : 0;
                      const updatedTitle = event.title.replace(
                        `${side}:${currentCount}`,
                        `${side}:${currentCount + follicleCount}`
                      );
                      event.title = updatedTitle;
                    }
                  }
                };

                processDetail(detail.left_follicle_size, detail.left_no_of_follicles, 'L');
                processDetail(detail.right_follicle_size, detail.right_no_of_follicles, 'R');
              });
            }
          });

          // Convert Map to array and set the state
          const uniqueEvents = Array.from(uniqueEventsMap.values());
          setFollicularStudyEvents(uniqueEvents);
          console.log('Unique Events:', uniqueEvents); // Debugging log
        });
    }
    // eslint-disable-next-line 
  }, [selectedStim, firstDate]);


  const myResources: MbscResource[] = [
    {
      name: 'Event',
      id: 'Event',
      eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false
    },
    {
      name: 'Medication',
      id: 'Medication',
      collapsed: false,
      isParent: true,
      eventCreation: false,
      children: [
        { name: 'Stim', id: 'med-stim', children: stim? stim: [], eventCreation: false, },
        { name: 'Trigger', id: 'med-trigger', children: trigger? trigger: [], eventCreation: false, },
        { name: 'Hormone', id: 'med-hormone', children: hormone? hormone: [], eventCreation: false, },
      ],
    },
    {
      name: 'Follicular Study',
      id: 'fs',
      collapsed: false,
      isParent: true,
      eventCreation: true,
      eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false,
      children: [
        { name: 'Follicular Image', id: 'fi',  eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false },
        { name: 'Follicular Details', id: 'fd', eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false },
        { name: 'Endometrium Thickness', id: 'et',  eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false },
        {
          name: 'Follicular Size (mm)',
          id: 'fsm',
          collapsed: false,
          eventCreation: false,
          children: [
            { name: 'Less than 2', id: '<2', eventCreation: false,  eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false },
            { name: '2', id: '2', eventCreation: false,  eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false },
            { name: '3', id: '3', eventCreation: false,  eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false},
            { name: '4', id: '4', eventCreation: false,  eventDragInTime: false, eventDragBetweenSlots: false, eventDragBetweenResources: false, eventResize: false},
          ],
        },
      ],
    },
  ];

  function handleFollicularDetailsClick(data: MbscCalendarEventData): void {
    setFocusEventCal(data);
    setShowFollicularModal(true);
  }

  function handleFollicularImageClick(data: MbscCalendarEventData): void {
    setFocusEventCal(data);
    setShowFollicularImageModal(true);
  }

  function handleHormonClick(data: MbscCalendarEventData) {
    setFocusEventCal(data);
    setShowHormoneValueModal(true);
  }

  function handleMedClick(data: MbscCalendarEventData): void {
    setFocusEventCal(data);
    setShowDrugModal(true);
  }
  

  function handleScheduleEventRender(data: MbscCalendarEventData) {
    if (data?.resource === 'Event') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return (<span className={Styles.eventE} onClick={() => {handleEventClick(data);}}>{data.title}</span>);
    }
    if (data?.resource === 'fi') {
      if (data.title) {
        return (<div className={Styles.camera}><Eye onClick={() => handleFollicularImageClick(data)}/></div>);
      }
      return (<div className={Styles.camera}><Camera onClick={() => handleFollicularImageClick(data)}/></div>);
    }
    if (data?.resource === 'fs') {
      return (<div className={Styles.camera}><CheckBox/></div>);
    }
    if (data?.resource === 'fd') {
      if (data.title) {
        return (<div  className={Styles.camera}><Eye onClick={() => handleFollicularDetailsClick(data)}/></div>);
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return (<div className={Styles.camera}><AddCircle onClick={() => handleFollicularStudyModal(data)}/></div>);
    }
    if (data.resource?.toString().startsWith('hormone-')) {
      return (<span className={Styles.event_hm} onClick={() => handleHormonClick(data)}>{data.title}</span>);
    }
    if (data.resource?.toString().startsWith('trigger-') || data.resource?.toString().startsWith('stim-')) {
      return (<span onClick={() => handleMedClick(data)} className={Styles.event}>{data.title}</span>);
    }
    return (<span className={Styles.event}>{data.title}</span>);
  }

  const handleClickDeleteMed = () => {
    showConfirmationModal({
      message: 'Are you sure you want to delete?',
      onConfirm: () => {
        console.log('Confirmed!');
      },
      onCancel: () => {
        console.log('Cancelled!');
      },
    });
  };

  const handleCreateDrugModelOpen = (name: string) => {
    switch(name) {
    case 'Hormone' : setShowCreateHormoneModal(true);
      return;  
    case 'Stim': setType('Stim');
      break;
    case 'Trigger': setType('Trigger');
      break;
    }

    setShowCreateDrugModal(true);
  };

   
  function handleResourceRender(resource: MbscResource, _day?: Date | undefined) {
    if (resource.id?.toString().includes('med-')) {
      return (<div className={Styles.medRes}><span className={Styles.resourceNameP}>{resource.name}</span><AddCircle onClick={() => resource.name? handleCreateDrugModelOpen(resource.name): addAlert('Error', 'error')}/></div>);
    }
    if (resource.id?.toString().includes('stim-') || resource.id?.toString().includes('trigger-') || resource.id?.toString().includes('hormone-')) {
      return (<div className={Styles.medResClose}><span className={Styles.resourceNameNP}>{resource.name}</span><CloseCircle onClick={handleClickDeleteMed}/></div>);
    }
    if (resource.isParent) {
      return (<span className={Styles.resourceNameP}>{resource.name}</span>);
    }
    return (<span className={Styles.resourceNameNP}>{resource.name}</span>);
  }

  function getWeekday(arg0: number): React.ReactNode {
    switch (arg0) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    }
  }

  function daysFromFirstDate(date: Date) {
    if (firstDate) {
      return Math.ceil((date.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    }
    return Math.ceil((date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1;
  }

  function daysFromLastDate(date: Date) {
    if (endDate) {
      return Math.ceil((date.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    }
    return null;
  }


  function handleRenderDay(args: MbscCalendarDayData) {
    const diff = daysFromFirstDate(args.date);
    const diffEnd = daysFromLastDate(args.date);
    return <div {...(diff > 0 && (diffEnd ? (diffEnd * -1) >= -1: true) ? {className: Styles.custHeaderc}: {className: Styles.custHeader})}>
      {diff > 0 ? <label className={Styles.headDay}>{diff.toString().padStart(2, '0')}</label>: <label className={Styles.headDay}>00</label>}
      <div className={Styles.dayHeader}><label>{args.date.getDate()}</label><span>{getWeekday(args.date.getDay())}</span></div>
    </div>;
  }


  const handleHeaderRender = useCallback(() => {
    return <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
      <CalendarNav className="cal-header-nav"/>;
      {!fullScreen && <Button onClick={() => showFullScreen(true)} variant="text" startIcon={<FullscreenIcon />}>
       Show full screen
      </Button>}
      {fullScreen && <Button onClick={() => showFullScreen(false)} variant="text" startIcon={<FullscreenExitIcon />}>
       Exit full screen
      </Button>}
    </div>;
  }, [fullScreen]);

  function handleEventClick(data: MbscCalendarEventData) {
    setFocusEventCal(data);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleEventTypeCreation();
  }

  function handleEventTypeCreation(): void {
    setShowEventModal(true);
  }

  function handleDrugCreation() {
    setShowDrugModal(true);
  }

  function handleHormoneTestCreation() {
    setShowHormoneValueModal(true);
  }

  function handleFollicularStudyModal(data: MbscCalendarEventData) {
    setFocusEventCal(data);
    setShowFollicularModal(true);
  }

  function handleFollicularStudyCreation(args: MbscEventCreateEvent) {
    const newEvents = [
      { date: args?.event.start, resource: 'fs' },
      { date: args?.event.start, resource: 'fi' },
      { date: args?.event.start, resource: 'fd' },
    ];
  
    setFollicularStudyEvents((prevEvents) => {
      const updatedEvents = [...(prevEvents || []), ...newEvents];
      console.log('Updated Events:', updatedEvents); // Debugging to verify updates
      return updatedEvents;
    });
  }

  function handleEventCreate(args: MbscEventCreateEvent, inst: EventcalendarBase): void {
    console.log('feve', args);  
    if (args.event.start && (args.event.start as Date) <= new Date()) {
      addAlert('Choose a future date', 'error');
      return;
    }
    if (endDate && args.event.start && (args.event.start as Date) > endDate) {
      addAlert('Cycle ends on ' + convertDateToDDMMYYYY(endDate), 'error');
      return;
    }

    setFocusEvent(args);
    if (args.event.resource === 'Event') {
      handleEventTypeCreation();
    } else if (args.event.resource?.toString().startsWith('stim-') || args.event.resource?.toString().startsWith('trigger-')) {
      handleDrugCreation();
    } else if (args.event.resource?.toString().startsWith('hormone-')) {
      handleHormoneTestCreation();
    } else if (args.event.resource?.toString()==='fs') {
      handleFollicularStudyCreation(args);
    }
  }

  function handleDrugAddSubmit(id: string, name: string, type: 'Trigger' | 'Stim'): void {
    if (type === 'Stim' && stim) {
      if (!stim.some((entry) => entry.id === `stim-${id}`)) {
        setStim([...(stim || []), { id: `stim-${id}`, name }]);
      } else {
        addAlert('Stim already exists', 'warning');
      }
    }
  
    if (type === 'Trigger') {
      if (!trigger?.some((entry) => entry.id === `trigger-${id}`)) {
        setTrigger([...(trigger || []), { id: `trigger-${id}`, name }]);
      } else {
        addAlert('Trigger already exists', 'warning');
      }
    }
  }

  function handleHormoneSubmit(id: string, name: string) {
    if (hormone && !hormone.some((entry) => entry.id === `stim-${id}`)) {
      setHormone([...(hormone || []), { id: `hormone-${id}`, name }]);
    } else {
      addAlert('Hormone already exists', 'warning');
    }
  }

  const getValidRanges = () => {
    if (firstDate && endDate) {
      return [{ start: firstDate, end: endDate }]; // Enable between fromDate and toDate
    } else if (firstDate) {
      return [{ start: firstDate }]; // Enable from fromDate onward if no toDate
    }
    return []; // Default to no valid range if no dates are provided
  };

  const getInvalidRanges = () => {
    const invalidRanges = [];
    
    if (firstDate) {
      // Invalid range before `firstDate` (exclusive)
      invalidRanges.push({
        start: new Date(0), // Start from epoch time
        end: new Date(firstDate.getTime() - 24 * 60 * 60 * 1000), // Subtract one day
      });
    }
    
    if (endDate) {
      // Invalid range after `endDate` (exclusive)
      invalidRanges.push({
        start: convertDateToDDMMYYYY(endDate), // One millisecond after `endDate`
        end: new Date(8640000000000000), // Maximum valid JavaScript date
      });
    }
    
    return invalidRanges;
  };

  if (selectedStim) {
    return (<div {...(fullScreen ? {className: Styles.fullscreen}: {className: Styles.exitFullscreen})}>
      {/* eslint-disable-next-line */}
      {showFolliculareImageModal && focusEventCal && showFolliculareImageModal && <FollicularImageModal date={focusEventCal.startDate} cycleId={selectedStim.cycle.id} patient={patientId} id={focusEventCal.id} open={showFolliculareImageModal} onClose={handleModelCloseAndReferesh}/>}
      {/* eslint-disable-next-line */}
      {showHormoneValueModal && (focusEvent && focusEvent?.event.resource?.toString().split('-')[1] && focusEvent.event.start || focusEventCal?.title && focusEventCal.id) && <HormoneValueModal value={focusEventCal?.title} id={focusEventCal?.id} cycle={selectedStim.cycle.id} patientId={patientId} {...(focusEvent?.event.start ? {date: new Date(focusEvent.event.start.toString())}: focusEventCal?.startDate && {date: new Date(focusEventCal?.startDate)})} {...(focusEvent?.event.resource?.toString().split('-')[1] ? {hormoneId: parseInt(focusEvent?.event.resource?.toString().split('-')[1])}: focusEventCal?.resource && {hormoneId: parseInt(focusEventCal?.resource?.toString().split('-')[1])})} hormonesModalOpen={showHormoneValueModal} handleHormonesModalClose={handleModelCloseAndReferesh}/>}
      {/* eslint-disable-next-line */}
      {showCreateDrugModal && type && <CreateDrugModal handleDrugModalClose={handleModelClose} handleSubmitClose={handleDrugAddSubmit} drugModalOpen={showCreateDrugModal} type={type}/>}
      {/* eslint-disable-next-line */}
      {showDrugModal && (focusEvent || focusEventCal) && (focusEvent?.event.resource || focusEventCal?.resource) && (focusEvent?.event.start || focusEventCal?.start) && <AddDrugModal medId={focusEventCal?.id} type={focusEvent?.event?.resource?.toString().split('-')[0] || focusEventCal?.resource?.toString().split('-')[0]} id={focusEvent?.event?.resource?.toString().split('-')[1] || focusEventCal?.resource?.toString().split('-')[1]} drugModalOpen={showDrugModal} handleDrugModalClose={handleModelCloseAndReferesh} stim={selectedStim.id} date={new Date(focusEvent?.event?.start?.toString() || focusEventCal?.start?.toString() || new Date())}/>}
      {showEventModal && (focusEventCal || focusEvent) && <EventModel id={focusEventCal?.id} stim={selectedStim.id} patient={selectedStim.patient.id} partner={selectedStim.patient.partner?.id} scheduleEventModalOpen={showEventModal} handleScheduleModalClose={handleModelCloseAndReferesh} {...(focusEvent?.event.start && {eventDate: new Date(focusEvent?.event.start.toString())})}/>}
      {showCreateHormoneModal && <CreateHormoneModal handleHormoneModalClose={handleModelClose} hormoneModalOpen={showCreateHormoneModal} handleSubmitClose={handleHormoneSubmit}/>}
      {showFolliculareModal && focusEventCal && focusEventCal.startDate && <FolliculerModal id={focusEventCal.id}  cycleDay={daysFromFirstDate(new Date(focusEventCal.startDate.toString()))} {...(selectedStim.cycle.lmp_date && {lmp: new Date(selectedStim.cycle.lmp_date)})} date={new Date(focusEventCal.startDate.toString())} stim={selectedStim.id} handleHormoneModalClose={handleModelCloseAndReferesh} hormoneModalOpen={showFolliculareModal}/>}
      <Eventcalendar
        invalid={getInvalidRanges()}
        onEventCreate={handleEventCreate}
        renderHeader={handleHeaderRender}
        {...(firstDate && {refDate: firstDate})}
        clickToCreate={true}
        showControls={true}
        renderDay={handleRenderDay}
        dragToResize={true}
        renderScheduleEvent={handleScheduleEventRender}
        renderResource={handleResourceRender}
        dragBetweenResources={true}
        dragToMove={true}
        dragBetweenSlots={true}
        resources={myResources}
        data={[...(events || []), ...(stimEvents || []), ...(triggerEvents || []), ...(hormoneEvents || []), ...(follicularDetailsEvents || []), ...(follicularETEvents || []), ...(follicularImageEvents || []), ...(follicularStudyEvents || []), ...(follicularSizeEvents || [])]}
        {...(fullScreen? {height: '100vh'}:height && {height: height})}
        {...(fullScreen && {width: '100%'})}
        view={{
          timeline: {
            virtualScroll: true,
            eventList: true,
            rowHeight: 'variable',
            type: 'month',
            resolutionHorizontal: 'day', // Full-day resolution
            timeCellStep: 1440, // Each cell represents 1 day
          },
        }}
      />
    </div>);
  } else {
    return <DefaultLoader/>;
  }
};