import { getRequest, postRequest } from '../../services/httpService';
import { getDiagnosisURL, getDoctorsURL, getPutDiagnosisURL, postDiagnosisURL } from '../../services/urls';
import {DiagnosisMaster, PaginatedResponse} from '../../types';
import * as yup from 'yup';


export type DiagnosisTypeEnum = 'DIFFERENTIAL' | 'FINAL';
export type BlankEnum = '';



export const DiagnosisMenuItems: { value: DiagnosisTypeEnum | BlankEnum; label: string }[] = [
  { value: 'DIFFERENTIAL', label: 'Differential' },
  { value: 'FINAL', label: 'Final' }
];

export interface PatientDiagnosis {
  id?: number;
  patient: number;
  diagnosis: number;
  diagnosis_type: DiagnosisTypeEnum;
  doctor: number;
  notes?: string;
}

export interface getPatientDiagnosisinterface {
  id: number;
  patient: number;
  diagnosis: number;
  diagnosis_code: string;
  diagnosis_name: string;
  diagnosis_type: DiagnosisTypeEnum;
  doctor: number;
  doctor_name: string;
  notes?: string; // Optional if not always present
  created_on:string
}


export interface PaginatedPatientDiagnosisList{
  meta: {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  };
  objects: getPatientDiagnosisinterface[];
}

export enum RoleEnum {
    ADMIN = 'ADMIN',
    DOCTOR = 'DOCTOR',
    NURSE = 'NURSE',
    RECEPTIONIST = 'RECEPTIONIST',
}

export interface User {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    username: string;
    gender_name?: string;
    role?: RoleEnum;
}

export interface PaginatedUserListList {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: User[];
  }

export interface diagnosistype{
  id ?:number,
  patient:number |null,
  diagnosis: number | null,
  diagnosis_code: string | null,
  diagnosis_name:string | null,
  diagnosis_type: DiagnosisTypeEnum |BlankEnum,
  doctor: number | null,
  notes: string | null,
}

export const initialDiagnosis:diagnosistype = {
  patient: null,
  diagnosis: null,
  diagnosis_code:null,
  diagnosis_name: null,
  diagnosis_type: '',
  doctor: null,
  notes: null,
};




export const PatientDiagnosisValidationSchema = yup.object({
  diagnosis: yup.array().of(
    yup.object({
      diagnosis_code: yup.string().required('Diagnosis code is required'),
      diagnosis_name: yup.string().required('Diagnosis name is required'),
      diagnosis_type: yup.string().required('Diagnosis type is required'),
      doctor: yup.string().required('Doctor is required'),
    })
  ),
});


  
export function getDiagnosis(query:string):Promise<PaginatedResponse<DiagnosisMaster[]>>{
  return getRequest(getPutDiagnosisURL(query));
}

export function getDoctors():Promise<PaginatedUserListList>{
  return getRequest(getDoctorsURL());
}

export function postDiagnosis(data:PatientDiagnosis):Promise<PatientDiagnosis>{
  return postRequest(postDiagnosisURL(), data);
}

export function getPatientDiagnosis(id:number):Promise<PaginatedPatientDiagnosisList>{
  return getRequest(getDiagnosisURL(id));
}

