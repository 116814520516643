import React, { useEffect, useState } from 'react';
import styles from './OocyteRetrieval.module.css';
import { ReactComponent as EmbryoTransferProcedure } from '../../../assets/SVG/Embryo Transfer Procedure.svg';
import Grid from '@mui/material/Grid';
import { getOocyteRetrieval, OocyteRetrievalData } from './OocyteRetrievalHelper';

interface OocyteRetrievalProps {
  cycle: string;
}

const OocyteRetrieval: React.FC<OocyteRetrievalProps> = ({ cycle }) => {
  const [OocyteRetrieval, setOocyteRetrievalData] = useState<OocyteRetrievalData | null>(null);

  useEffect(() => {
    if (cycle) {
      getOocyteRetrieval(cycle)
        .then((data) => {
          setOocyteRetrievalData(data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching Oocyte Retrieval data:', error);
        });
    }
  }, [cycle]);

  return (
    <div className={styles.container}>
      <Grid container spacing={3} alignItems="flex-start">
        {/* Image Section */}
        <Grid item xs={12} md={4} className={styles.embryoProcedure}>
          <EmbryoTransferProcedure />
        </Grid>

        {/* Text Data Section */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Retrieval Date</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.opu_date || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Retrieval Time</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.start_time || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Clinician</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.clinician_name || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Embryologist</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.embryologist_name || '-'}</p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Nurse</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.nurse_name || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Left Optimum Follicles</strong>
                <p className={styles.customTextStyle}>
                  {OocyteRetrieval?.optimum_follicles?.left_follicles ?? '-'}
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Right Optimum Follicles</strong>
                <p className={styles.customTextStyle}>
                  {OocyteRetrieval?.optimum_follicles?.right_follicles ?? '-'}
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Left Oocytes Retrieved</strong>
                <p className={styles.customTextStyle}>
                  {OocyteRetrieval?.oocytes_retrieved_left ?? '-'}
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Right Oocytes Retrieved</strong>
                <p className={styles.customTextStyle}>
                  {OocyteRetrieval?.oocytes_retrieved_right ?? '-'}
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Level Of Difficulty</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.difficulty_level || '-'}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OocyteRetrieval;
