import * as yup from 'yup'; 
import { getRequest, postRequest, putRequest } from '../../../services/httpService';
// Blank and Null Enum Types
export type BlankEnum = '';
export type NullEnum = 'NULL';

// Type for Transfer Methods
export type TransferMethodEnum = 'FRESH_TRANSFER'| 'FROZEN_EMBRYO_TRANSFER'| 'BLASTOCYST_TRANSFER'| 'DAY_3_TRANSFER'| 'ASSISTED_HATCHING_TRANSFER'| 'NATURAL_CYCLE_TRANSFER'| 'MEDICATED_CYCLE_TRANSFER';

// Type for Ultrasound Methods
export type UltrasoundMethodEnum = 'TRANSABDOMINAL'| 'TRANSVAGINAL'| 'DOPPLER'| '3D_4D'| 'ECHOCARDIOGRAPHY'| 'ULTRASOUND_GUIDED';

// Type for Catheters
export type CatheterEnum = 'COOK'| 'SWEMED'| 'SURGIMEDIK'| 'WALLACE_1816N'| 'KITAZATO'| 'WALLACE_SOFT'| 'WALLACE_STYLET';

// Type for Endometrium Patterns
export type EndometriumPatternEnum = 'NORMAL'| 'POLYPLOIDAL'| 'FLUFFY'| 'ATROPHIC'| 'TRIPLE_LAYER'| 'HOMOGENEOUS'| 'HYPERECHOIC'| 'HYPOECHOIC'| 'THICKENED'| 'THIN'| 'IRREGULAR'| 'CYSTIC'| 'SECRETORY_PHASE'| 'PROLIFERATIVE_PHASE'| 'MENSTRUAL_PHASE';

export type LevelOfDifficulty =  'STEP_PRESENT'| 'SIMS_VULSELLUM_USED'| 'MINIMAL_DIFFICULTY'| 'ATOSIBAN_INFUSION';


// Interface for individual embryo details
export interface EmbryoDetailType {
    embryo_no: number;
    embryo_day: number;
    grade: string;
    cell_stage: string;
    status: 'Fresh' | 'Frozen'; // Constrain to specific statuses
    upload_icon: boolean;
  }
  
// Interface for Embryo Transfer
export interface EmbryoTransferType {
  cycle: number;
  transfer_method: string;
  id?:number | null;

  oocyte_fertilized_method: number | null;
  embryo_cryo_preservation_method: number | null;
  fresh_embryo_transfer_method: number | null;
  frozen_embryo_transfer_method: number | null;

  date: string | null;
  start_time: string | null;
  end_time: string | null;

  et_dish_id: string | null;
  embryologist: number | null;
  witness_embryologist: number | null;
  nurse: number | null;

  performed_under_anesthesia: boolean;
  anesthesia: string | null;
  anesthetist: number | null;

  endometrium_thickness: number | null;
  endometrium_pattern: string | null;

  is_ultrasound: boolean;
  ultrasound_method: string | null;

  transfer_depth: string;
  difficulty_level: string;
  catheter: string;

  catheter_tube: number | null;
  catheter_remarks: string | null;

  no_of_attempts: number | null;
  distance_from_fundus: number | null;
  embryo_retained: number;

  is_tenaculum_used: boolean;
  is_stylet_used: boolean;
  is_embryo_glue_used: boolean;

  complications: string | null;
  clinician_notes: string | null;
  notes: string | null;
  pregnancy_date: string | null;
  is_cycle_closed: boolean;
  cycle_close_reason: string | null;
  // Embryo details array
  // embryoDetails?: EmbryoDetailType[] | null;
}

// Initial State for Embryo Transfer
export const initialEmbryoTransfer: EmbryoTransferType = {
  cycle: 0,
  transfer_method: '',
  id:null,
  oocyte_fertilized_method:  null,
  embryo_cryo_preservation_method:  null,
  fresh_embryo_transfer_method:  null,
  frozen_embryo_transfer_method:  null,
  date: null,
  start_time: null,
  end_time: null,

  et_dish_id: null,
  embryologist: null,
  witness_embryologist: null,
  nurse: null,

  performed_under_anesthesia: false,
  anesthesia: null,
  anesthetist: null,

  endometrium_thickness: null,
  endometrium_pattern: null,

  is_ultrasound: false,
  ultrasound_method: null,

  transfer_depth: '',
  difficulty_level: '',

  catheter: '',
  catheter_tube: null,
  catheter_remarks: null,

  no_of_attempts: null,
  distance_from_fundus: null,
  embryo_retained: 0,

  is_tenaculum_used: false,
  is_stylet_used: false,
  is_embryo_glue_used: false,

  complications: null,
  clinician_notes: null,
  notes: null,
  pregnancy_date: null,
  is_cycle_closed: false,
  cycle_close_reason: null,
};



// Validation Schema for Embryo Transfer Form using Yup
export const EmbryoTransferValidationSchema = yup.object({
  cycle: yup
    .string()
    .required('Cycle Number is required'),

  transfer_method: yup
    .string()
    .required('Transfer Method is required'),

  oocyte_fertilized_method: yup
    .number()
    .nullable(),

  embryo_cryo_preservation_method: yup
    .number()
    .nullable(),

  fresh_embryo_transfer_method: yup
    .number()
    .nullable(),

  frozen_embryo_transfer_method: yup
    .number()
    .nullable(),

  date: yup
    .string()
    .nullable()
    .required('Date is required'),

  start_time: yup
    .string()
    .nullable()
    .required('Start Time is required'),


  end_time: yup
    .string()
    .required('End Time is required')
    .test(
      'is-after-start-time',
      'End Time should be after Start Time',
      function (value) {
        const { start_time } = this.parent;
        if (!start_time || !value) return true; 
        return value > start_time;
      }
    ),

  et_dish_id: yup
    .string()
    .nullable(),

  embryologist: yup
    .number()
    .required('Embryologist name is required'),

  witness_embryologist: yup
    .number()
    .required('Witness Embryologist name is required')
    .test('not-same', 'The witness embryologist cannot be the same as the embryologist.', function (value) {
      const { embryologist } = this.parent; // Accessing the sibling field
      return value !== embryologist; // Validate that they are not the same
    }),

  nurse: yup
    .number()
    .nullable(),

  performed_under_anesthesia: yup
    .boolean(),

  anesthesia: yup
    .string()
    .nullable()
    .test('required-if-anesthesia', 'Anesthesia is required when performed under anesthesia', function(value) {
      const { performed_under_anesthesia } = this.parent;
      // Check if performed_under_anesthesia is true and the value is either null or undefined
      return performed_under_anesthesia ? value != null : true;
    }),
    
  anesthetist: yup
    .number()    
    .nullable(),

  endometrium_thickness: yup
    .number()
    .nullable(),

  endometrium_pattern: yup
    .string()
    .nullable(),

  is_ultrasound: yup
    .boolean(),

  ultrasound_method: yup
    .string()
    .nullable()
    .test('required-if-is_ultrasound', 'Ultrasound Method is required when Ultrasound is checked', function(value) {
      const { is_ultrasound } = this.parent;  // Accessing the parent object to get the value of 'is_ultrasound'
      return is_ultrasound ? value != null && value.trim() !== '' : true; // If is_ultrasound is true, ensure ultrasound_method is not empty
    }),
  
  transfer_depth: yup
    .string(),

  difficulty_level: yup
    .string(),

  catheter: yup
    .string(),

  catheter_tube: yup
    .number()
    .nullable(),

  catheter_remarks: yup
    .string()
    .nullable(),

  no_of_attempts: yup
    .number()
    .nullable(),

  distance_from_fundus: yup
    .number()
    .nullable(),

  embryo_retained: yup
    .number()
    .required('Embryo Retained is required'),

  is_tenaculum_used: yup
    .boolean(),

  is_stylet_used: yup
    .boolean(),

  is_embryo_glue_used: yup
    .boolean(),


  complications: yup
    .string()
    .nullable(),
  
  clinician_notes: yup
    .string()
    .nullable(),
  
  notes: yup
    .string()
    .nullable(),
  
  pregnancy_date: yup
    .string()
    .nullable(),

  is_cycle_closed: yup
    .boolean(),

 
  cycle_close_reason: yup.string().nullable()
    .test('cycle-close-reason', 'Cycle Close Reason is required when Cycle Close is checked', function(value) {
      const { is_cycle_closed } = this.parent;
      if (is_cycle_closed === true && !value) {
        return false; // Returns false if is_cycle_closed is true and value is empty, making it invalid
      }
      return true; // Otherwise, the validation passes (nullable case)
    })
});

// URL Generation Function for Embryo Transfer
export function generateEmbryoTransferURL(id: number): string {
  return `embryo-transfer/${id}`;
  
}

export function addEmbryoTransfer(data: EmbryoTransferType): Promise<EmbryoTransferType> {
  const url = 'embryo-transfer/';  
  return postRequest(url, data, { responseType: 'json' });
}


export function getEmbryoTransfer(id: number): Promise<EmbryoTransferType> {
  const url = `/embryo-transfer/cycle/${id}`;
  return getRequest(url, { responseType: 'json' });

}

// Update Embryo Transfer Function
export function updateEmbryoTransfer(id: number, data: EmbryoTransferType): Promise<EmbryoTransferType> {
  return putRequest(generateEmbryoTransferURL(id), data, { responseType: 'json' });
}



// Dropdown Items for Transfer Methods
export const transferMethodItems: { label: string; value: TransferMethodEnum | BlankEnum }[] = [
  { label: 'Fresh Transfer', value: 'FRESH_TRANSFER' },
  { label: 'Frozen Embryo Transfer (FET)', value: 'FROZEN_EMBRYO_TRANSFER' },
  { label: 'Blastocyst Transfer', value: 'BLASTOCYST_TRANSFER' },
  { label: 'Day 3 Embryo Transfer', value: 'DAY_3_TRANSFER' },
  { label: 'Assisted Hatching Transfer', value: 'ASSISTED_HATCHING_TRANSFER' },
  { label: 'Natural Cycle Transfer', value: 'NATURAL_CYCLE_TRANSFER' },
  { label: 'Medicated Cycle Transfer', value: 'MEDICATED_CYCLE_TRANSFER' },
];
  
// Dropdown Items for Endometrium Patterns
export const endometriumPatternItems: { label: string; value: EndometriumPatternEnum | BlankEnum }[] = [
  { label: 'Normal', value: 'NORMAL' },
  { label: 'Polyploidal', value: 'POLYPLOIDAL' },
  { label: 'Fluffy', value: 'FLUFFY' },
  { label: 'Atrophic', value: 'ATROPHIC' },
  { label: 'Triple Layer', value: 'TRIPLE_LAYER' },
  { label: 'Homogeneous', value: 'HOMOGENEOUS' },
  { label: 'Hyperechoic', value: 'HYPERECHOIC' },
  { label: 'Hypoechoic', value: 'HYPOECHOIC' },
  { label: 'Thickened', value: 'THICKENED' },
  { label: 'Thin', value: 'THIN' },
  { label: 'Irregular', value: 'IRREGULAR' },
  { label: 'Cystic', value: 'CYSTIC' },
  { label: 'Secretory Phase', value: 'SECRETORY_PHASE' },
  { label: 'Proliferative Phase', value: 'PROLIFERATIVE_PHASE' },
  { label: 'Menstrual Phase', value: 'MENSTRUAL_PHASE' },
];
  
  
// Dropdown Items for Ultrasound Methods
export const ultrasoundMethodItems: { label: string; value: UltrasoundMethodEnum | BlankEnum }[] = [
  { label: 'Transabdominal Ultrasound', value: 'TRANSABDOMINAL' },
  { label: 'Transvaginal Ultrasound', value: 'TRANSVAGINAL' },
  { label: 'Doppler Ultrasound', value: 'DOPPLER' },
  { label: '3D and 4D Ultrasound', value: '3D_4D' },
  { label: 'Echocardiography', value: 'ECHOCARDIOGRAPHY' },
  { label: 'Ultrasound-Guided Procedures', value: 'ULTRASOUND_GUIDED' },
];
  
  
// Dropdown Items for Catheters
export const catheterItems: { label: string; value: CatheterEnum | BlankEnum }[] = [
  { label: 'Cook', value: 'COOK' },
  { label: 'Swemed', value: 'SWEMED' },
  { label: 'Surgimedik', value: 'SURGIMEDIK' },
  { label: 'Wallace 1816N', value: 'WALLACE_1816N' },
  { label: 'Kitazato', value: 'KITAZATO' },
  { label: 'Wallace Soft', value: 'WALLACE_SOFT' },
  { label: 'Wallace Stylet', value: 'WALLACE_STYLET' },
];
  
  
export const cycleItems: { label: string; value: string }[] = [
  { label: 'Cycle 1', value: 'CYCLE_1' },
  { label: 'Cycle 2', value: 'CYCLE_2' },
  { label: 'Cycle 3', value: 'CYCLE_3' },
  { label: 'Cycle 4', value: 'CYCLE_4' },
  { label: 'Cycle 5', value: 'CYCLE_5' },
];

// Item list for Level of Difficulty Dropdown
export const levelOfDifficultyItems:{ label: string; value: LevelOfDifficulty | BlankEnum }[] = [
  { label: 'Step Present', value: 'STEP_PRESENT' },
  { label: 'Sims & Vulsellum Used', value: 'SIMS_VULSELLUM_USED' },
  { label: 'Minimal Difficulty', value: 'MINIMAL_DIFFICULTY' },
  { label: 'Atosiban Infusion', value: 'ATOSIBAN_INFUSION' },
];
  

export const mockEmbryoTransferData: EmbryoTransferType = {
  cycle: 1,
  transfer_method: 'FRESH_TRANSFER',
  
  oocyte_fertilized_method: 8,
  embryo_cryo_preservation_method: 4,
  fresh_embryo_transfer_method: 3,
  frozen_embryo_transfer_method: 1,
  
  date: '2024-12-05',
  start_time: '09:00',
  end_time: '16:30',
  
  et_dish_id: 'ET12345',
  embryologist: 51,
  witness_embryologist: 49,
  nurse: 11,
  
  performed_under_anesthesia: true,
  anesthesia: '',
  anesthetist: 36,
  
  endometrium_thickness: 8.5,
  endometrium_pattern: 'TRIPLE_LAYER',
  
  is_ultrasound: true,
  ultrasound_method: 'TRANSVAGINAL',
  
  transfer_depth: '5 cm',
  difficulty_level: 'SIMS_VULSELLUM_USED',
  catheter: 'COOK',
  
  catheter_tube: 18,
  catheter_remarks: 'No issues during transfer',
  
  no_of_attempts: 2,
  distance_from_fundus: 3.5,
  embryo_retained: 2,
  
  is_tenaculum_used: true,
  is_stylet_used: false,
  is_embryo_glue_used: true,
  
  complications: 'None',
  clinician_notes: 'Transfer was successful, monitor for pregnancy confirmation.',
  notes: 'Embryos were viable, fresh transfer performed.',
  pregnancy_date: '2025-01-10',
  is_cycle_closed: true,
  cycle_close_reason: 'Successful transfer, awaiting pregnancy confirmation.',

//   embryoDetails: [
//     {
//       embryo_no: 1,
//       embryo_day: 3,
//       grade: 'A',
//       cell_stage: '8-cell',
//       status: 'Fresh',
//       upload_icon: true,
//     },
//     {
//       embryo_no: 2,
//       embryo_day: 5,
//       grade: 'B',
//       cell_stage: 'Blastocyst',
//       status: 'Frozen',
//       upload_icon: true,
//     },
//   ],
};
  

export const embryoDetails = [
  // {
  //   embryo_no: 1,
  //   embryo_day: 3,
  //   grade: 'A',
  //   cell_stage: '8-cell',
  //   status: 'Fresh',
  //   upload_icon: true,
  // },
  // {
  //   embryo_no: 2,
  //   embryo_day: 5,
  //   grade: 'B',
  //   cell_stage: 'Blastocyst',
  //   status: 'Frozen',
  //   upload_icon: true,
  // },
];
  


export enum TypeOfAnesthesiaEnum {
  GENERAL_ANESTHESIA = 'GENERAL_ANESTHESIA',
  REGIONAL_ANESTHESIA = 'REGIONAL_ANESTHESIA',
  LOCAL_ANESTHESIA = 'LOCAL_ANESTHESIA',
  MAC_SEDATION = 'MAC_SEDATION',
  SPINAL_ANESTHESIA = 'SPINAL_ANESTHESIA',
  EPIDURAL_ANESTHESIA = 'EPIDURAL_ANESTHESIA',
}

export type TypeOfAnesthesia =
  | TypeOfAnesthesiaEnum.GENERAL_ANESTHESIA
  | TypeOfAnesthesiaEnum.REGIONAL_ANESTHESIA
  | TypeOfAnesthesiaEnum.LOCAL_ANESTHESIA
  | TypeOfAnesthesiaEnum.MAC_SEDATION
  | TypeOfAnesthesiaEnum.SPINAL_ANESTHESIA
  | TypeOfAnesthesiaEnum.EPIDURAL_ANESTHESIA;

export const typeOfAnesthesiaItems: { label: string; value: TypeOfAnesthesia }[] = [
  { label: 'General Anesthesia', value: TypeOfAnesthesiaEnum.GENERAL_ANESTHESIA },
  { label: 'Regional Anesthesia', value: TypeOfAnesthesiaEnum.REGIONAL_ANESTHESIA },
  { label: 'Local Anesthesia', value: TypeOfAnesthesiaEnum.LOCAL_ANESTHESIA },
  { label: 'Monitored Anesthesia Care (MAC)/Sedation', value: TypeOfAnesthesiaEnum.MAC_SEDATION },
  { label: 'Spinal Anesthesia', value: TypeOfAnesthesiaEnum.SPINAL_ANESTHESIA },
  { label: 'Epidural Anesthesia', value: TypeOfAnesthesiaEnum.EPIDURAL_ANESTHESIA },
];
  



export type CycleEmbryoDetails = {
  id: number;
  cycle: number;
  day: number;
  grade: string;
  action: string;
  cleavage: string | null;
  embryo_number: number;
  fragmentation_percentage: number | null;
  insemination_technique: string | null;
  multi_nuclei: string | null;
  number_of_cells: number | null;
  pb: string | null;
  pn: string | null;
  sperm_source: string | null;
  symmetry: string | null;
  stage: string;
  files: string | null;
  location: string | null;
  is_screened: boolean;
  status: string;
  transfer_files_url: string | null;
};

export function getEmbryoTransferDetails(id: number): Promise<CycleEmbryoDetails[]> {
  const url = `/embryo/cycle/${id}`;
  return getRequest(url, { responseType: 'json' });
}

export type EmbryoDetailsType = {
  id:number;
  cycle:number;
  day:number;
  embryo_number: number;
  grade: string;
  cell_stage: string;
  status: string;
  upload_icon?: boolean; // Optional property
};
