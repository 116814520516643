/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styles from './CryoThawingComponent.module.css';
import { ReactComponent as More } from '../../assets/SVG/close-circle-greay.svg';
import { Autocomplete, Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, ThemeProvider } from '@mui/material';
import { theme } from '../Medical_History_Forms/themeprovider';
import { Formik, Form} from 'formik';
import { clearDispositionFields, getDonebyWitnessbyData, initialValues, plan, PlanInformationFormValues, PlanInformationValidationSchema, putEmbruo, putOocyte, Survival,} from './PlanInformationHelper';
import { ConfirmationModal } from '../confirmationModal';
import { useAlert } from '../alertProvider';

interface PlanInformationProps {
    handleCloseDialog: () => void;
    planInfo ?: number | null;
    label?: string;
}

const PlanInformation: React.FC<PlanInformationProps> = ({ handleCloseDialog ,planInfo,label }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openthawModal, setOpenthawModal] = useState<boolean>(false);
  const [disposition_plan, setdisposition_plan] = useState<string>('THAW');
  const [doneByOptions, setDoneByOptions] = useState<any[]>([]);
  const [WitnessedByOptions, setWitnessedByOptions] = useState<any[]>([]);
  const { addAlert } = useAlert();
  useEffect(() => {
    getDonebyWitnessbyData('DOCTOR')
      .then((response) => {
        const doctors = response.objects.map((doctor: any) => ({
          label: `${doctor.first_name} ${doctor.last_name}`,
          value: doctor.id,
        }));
  
        setDoneByOptions(doctors);
        setWitnessedByOptions(doctors);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [disposition_plan]);

  
  const handleThawSubmit = (values: PlanInformationFormValues, validateForm: any) => {
    validateForm(values).then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        setOpenthawModal(true);  
      } else {
        Object.keys(errors).forEach((field) => {
          console.warn(`Error in ${field}: ${errors[field]}`);
        });
      }
    });
  };
  
  
 


  const handleConfirmThaw = (values: PlanInformationFormValues) => {
    const apiCall = label === 'Embryo' ? putEmbruo : putOocyte; 
    const id = String(planInfo ?? '');
  
    apiCall(id, values)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {
        setOpenthawModal(false);
        addAlert(' details saved successfully!', 'success'); 
        handleCloseDialog();
      })
      .catch((error) => {
        console.error('Error while calling API:', error);
        addAlert(`Error saving ${label} details. Please try again.`, 'error'); 
      });
  };
  
  
  const handleDiscardSubmit = (values: PlanInformationFormValues, validateForm: any) => {
    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        setOpenModal(true);
      }
    });
  };
  const handleConfirmDiscard = (values: PlanInformationFormValues) => {
    const apiCall = label === 'Embryo' ? putEmbruo : putOocyte; 
    const id = String(planInfo ?? '');
  
    apiCall(id, values)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response) => {
        setOpenthawModal(false);
        addAlert('details saved successfully!', 'success'); 
        handleCloseDialog();
      })
      .catch((error) => {
        console.error('Error while calling API:', error);
        addAlert(`Error saving ${label} details. Please try again.`, 'error'); 
      });
    setOpenModal(false);
  };
  return (
    <>
      <div className={styles.PlanInformation}>
        <span className={styles.PlanInformationHeader}>Plan Information</span>
        <More style={{ cursor: 'pointer' }} onClick={handleCloseDialog} />
      </div>

      <ThemeProvider theme={theme}>
        <Formik
          initialValues={{
            ...initialValues
          }}
          onSubmit={handleThawSubmit}
          enableReinitialize
          validationSchema={PlanInformationValidationSchema}
        >
          {({ values, handleChange, handleBlur, errors, touched, setFieldValue, validateForm ,setFieldTouched,setFieldError}) => (
            <Form>
              <Grid container spacing={2} className="mt-4">
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="disposition_plan"
                      value={disposition_plan}
                      onChange={(e) => {
                        const { value } = e.target;
                        setdisposition_plan(value);
                        setFieldValue('disposition_plan', value);
                        clearDispositionFields(setFieldValue, setFieldTouched, setFieldError);
                      }}
                    >
                      <FormControlLabel value="THAW" control={<Radio />} label="Thaw" />
                      <FormControlLabel value="DISCARD" control={<Radio />} label="Discard" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>


              {disposition_plan === 'THAW' ? (
                <>
                  <Grid container spacing={2} className="mt-4">
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        options={plan}
                        getOptionLabel={(option) => option.label}
                        value={plan.find((option) => option.value === values.disposition_plan_type) || null}
                        onChange={(event, newValue) => {
                          const value = newValue ? newValue.value : '';
                          setFieldValue('disposition_plan_type', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Plan"
                            name="disposition_plan_type"
                            size="small"
                            error={touched.disposition_plan_type && !!errors.disposition_plan_type} 
                          />
                        )}
                      />
                    </Grid>

                    {/* Second TextField */}
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        name="disposition_date"
                        type='date'
                        label="Date"
                        fullWidth
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={values.disposition_date || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.disposition_date && !!errors.disposition_date}
                      />
                    </Grid>
                  </Grid>

                  {/* Other fields */}
                  <Grid container spacing={2} className="mt-2">
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        name="disposition_time"
                        type='time'
                        label="Time"
                        fullWidth
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={values.disposition_time || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.disposition_time && !!errors.disposition_time}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        options={doneByOptions} 
                        getOptionLabel={(option) => option.label}
                        value={doneByOptions.find((option) => option.value === values.disposition_embryologist) || null} 
                        onChange={(event, newValue) => {
                          const value = newValue ? newValue.value : ''; 
                          setFieldValue('disposition_embryologist', value);
                          setWitnessedByOptions(prevOptions => prevOptions.filter(option => option.value !== value));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Embryologist"
                            name="disposition_embryologist"
                            size="small" 
                            error={touched.disposition_embryologist && !!errors.disposition_embryologist}  
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="mt-2">
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        options={WitnessedByOptions} 
                        getOptionLabel={(option) => option.label}
                        value={WitnessedByOptions.find((option) => option.value === values.disposition_witness) || null} 
                        onChange={(event, newValue) => {
                          const value = newValue ? newValue.value : ''; 
                          setFieldValue('disposition_witness', value);
                          setDoneByOptions(prevOptions => prevOptions.filter(option => option.value !== value));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Witness By"
                            name="disposition_witness"
                            size="small" 
                            error={touched.disposition_witness && !!errors.disposition_witness}  
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        options={Survival}
                        getOptionLabel={(option) => option.label}
                        value={Survival.find((option) => option.value === values.is_disposition_survived) || null}
                        onChange={(event, newValue) => {
                          const value = newValue ? newValue.value : ''; 
                          setFieldValue('is_disposition_survived', value);  
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Survival Status"
                            name="is_disposition_survived"
                            size="small"
                            error={touched.is_disposition_survived && !!errors.is_disposition_survived}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="mt-2">
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        name="thaw_id"
                        label="Thaw ID"
                        fullWidth
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={values.thaw_id || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.thaw_id && !!errors.thaw_id}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        name="disposition_remarks"
                        label="Remark"
                        fullWidth
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={values.disposition_remarks || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.disposition_remarks && !!errors.disposition_remarks}
                      />
                    </Grid>
                  </Grid>

                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button
                      sx={{
                        width: '137px',
                        height: '46px',
                        padding: '12px 40px',
                        gap: '10px',
                        borderRadius: '10px',
                        border: '1px solid #505050',
                        opacity: 0.9,
                        fontFamily: '\'Montserrat\', sans-serif',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '22px',
                        textAlign: 'left',
                        textDecorationSkipInk: 'none',
                        color: '#505050',
                        textTransform: 'none',
                        marginRight: '8px', 
                      }}
                      onClick={handleCloseDialog}
                    >
  Cancel
                    </Button>

                    <Button
                      type='submit'
                      sx={{
                        width: '137px',
                        height: '46px',
                        padding: '12px 40px',
                        gap: '10px',
                        borderRadius: '10px',
                        opacity: 0.9,
                        fontFamily: '\'Montserrat\', sans-serif',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '22px',
                        textAlign: 'left',
                        textDecorationSkipInk: 'none',
                        color: '#f0f0f0',
                        backgroundColor: '#505050',
                        textTransform: 'none',
                    
                      }}
                      onClick={() => {
                        validateForm().then((errors) => {
                          if (Object.keys(errors).length === 0) {
                            handleThawSubmit(values, validateForm);  
                          } 
                        });
                      }}
                    
                    >
  Thaw
                    </Button>

                  </Box>
                </>
              ) : (
                <>
                  <Grid container spacing={2} className="mt-2">

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        name="disposition_date"
                        type='date'
                        label="Date"
                        fullWidth
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={values.disposition_date || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.disposition_date && !!errors.disposition_date}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        name="disposition_time"
                        type='time'
                        label="Time"
                        fullWidth
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={values.disposition_time || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.disposition_time && !!errors.disposition_time}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="mt-2">

                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        options={doneByOptions} 
                        getOptionLabel={(option) => option.label}
                        value={doneByOptions.find((option) => option.value === values.disposition_embryologist) || null} 
                        onChange={(event, newValue) => {
                          const value = newValue ? newValue.value : ''; 
                          setFieldValue('disposition_embryologist', value);
                          setWitnessedByOptions(prevOptions => prevOptions.filter(option => option.value !== value));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Embryologist"
                            name="disposition_embryologist"
                            size="small" 
                            error={touched.disposition_embryologist && !!errors.disposition_embryologist}  
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        options={WitnessedByOptions} 
                        getOptionLabel={(option) => option.label}
                        value={WitnessedByOptions.find((option) => option.value === values.disposition_witness) || null} 
                        onChange={(event, newValue) => {
                          const value = newValue ? newValue.value : ''; 
                          setFieldValue('disposition_witness', value);
                          setDoneByOptions(prevOptions => prevOptions.filter(option => option.value !== value));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Witness By"
                            name="disposition_witness"
                            size="small" 
                            error={touched.disposition_witness && !!errors.disposition_witness}  
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="mt-2">

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        name="disposition_remarks"
                        label="Remark"
                        fullWidth
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={values.disposition_remarks || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.disposition_remarks && !!errors.disposition_remarks}
                      />
                    </Grid>
                  </Grid>

                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button
                      variant="outlined"
                      sx={{
                        width: '137px',
                        height: '46px',
                        padding: '12px 40px',
                        gap: '10px',
                        borderRadius: '10px',
                        border: '1px solid #505050',
                        opacity: 0.9,
                        fontFamily: '\'Montserrat\', sans-serif',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '22px',
                        textAlign: 'left',
                        textDecorationSkipInk: 'none',
                        color: '#505050',
                        textTransform: 'none',
                        marginRight: '8px', 
                      }}
                      onClick={handleCloseDialog}
                      style={{ marginRight: 8 }}
                    >
                                            Cancel
                    </Button>
                    <Button
                      type='submit'
                      sx={{
                        width: '137px',
                        height: '46px',
                        padding: '12px 40px',
                        gap: '10px',
                        borderRadius: '10px',
                        opacity: 0.9,
                        fontFamily: '\'Montserrat\', sans-serif',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '22px',
                        textAlign: 'left',
                        textDecorationSkipInk: 'none',
                        color: '#f0f0f0',
                        backgroundColor: '#505050',
                        textTransform: 'none',
                      }}
                      onClick={() => handleDiscardSubmit(values, validateForm)}
                    >
                                            Discard
                    </Button>
                  </Box>
                </>
              )}
              <ConfirmationModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                onConfirm={() => handleConfirmDiscard(values)}
                type="save"
                title="Discard "
                contentMessage="Are you sure you want to save Discard details?"
              />
              <ConfirmationModal
                open={openthawModal}
                handleClose={() => setOpenthawModal(false)}
                onConfirm={() => handleConfirmThaw(values)}
                type="save"
                title="Thaw"
                contentMessage="Are you sure you want to save thaw details?"
              />
            </Form>
          )}
        </Formik>
      </ThemeProvider>
    </>
  );
};

export default PlanInformation;
